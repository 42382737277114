import React, { useEffect, useState } from "react";
import CountriesJson from "../../../constant/countries.json";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from "../../../constant/theme";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../../../../services/AxiosInstance";
import swal from "sweetalert";
import cover from "../../../../images/profile/cover.jpg";
import DatePicker from "react-datepicker";
import MainPagetitle from "../../../layouts/MainPagetitle";
import {
  UpdateUserProfile,
  updateProfile,
} from "../../../../store/actions/AuthActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const options1 = [
  { value: "", label: "Select" },
  { value: "66026da3e28086935bcb5bff", label: "Super Admin" },
  { value: "66026dace28086935bcb5c04", label: "Admin" },
  { value: "66026db7e28086935bcb5c07", label: "Team" },
];

const options2 = [
  { value: "", label: "Select" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const options3 = [
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
  { value: "china", label: "China" },
  { value: "india", label: "India" },
];
const options4 = [
  { value: "krasnodar", label: "Krasnodar" },
  { value: "tyumen", label: "Tyumen" },
  { value: "chelyabinsk", label: "Chelyabinsk" },
  { value: "moscow", label: "Moscow" },
];

const EditProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [selectOption , setSelectOption] = useState('Gender');
  const user = useSelector((stte) => stte.auth.auth);
  const [profileData, setProfileData] = useState();
  const [role, setrole] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const response = await DataService.get(`/users/${user.id}`);
    console.log(response.data, "User Data");
    if (response.data.success) {
      setProfileData(response?.data?.data ?? {});

      let date = new Date(response?.data?.data?.birth ?? new Date());
      console.log(date);
      formik.setValues(response?.data?.data ?? {});
      formik.setFieldValue("birth", date);

      console.log(response?.data?.data?.role);
      const response2 = await DataService.get(
        `/roles/${response?.data?.data?.role}`
      );
      console.log(response2.data);
      if (response2.data.success) {
        setrole(response2?.data?.data ?? {});
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    // gender: Yup.string().required("Gender is required"),
    // phone: Yup.string().required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    // password: Yup.string().required('Password is required'),
  });

  const validationSchemaPassword = Yup.object().shape({
    newPassword: Yup.string().required("New Password is required"),
    oldPassword: Yup.string().required("Old Password is required"),
  });

  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      try {
        console.log("pass", values);
        const response = await DataService.put(
          `/auth/changePassword/${user.id}`
        );
        if (response.data.success) {
          toast.success(
            response?.data?.message ?? "Password Updated Successfully!"
          );
          formikPassword.resetForm();
        } else {
          toast.error(
            response?.data?.message ?? "Error Occur while Updating Password!"
          );
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message ??
            "Error Occur while Updating Password!"
        );
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      role: "",
      gender: "",
      birth: new Date(),
      phone: "",
      email: "",
      country: "",
      city: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("check profile image", selectedFile);
      try {
        values.birth = new Date(values.birth);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("role", values.role);
        formData.append("gender", values.gender);
        formData.append("birth", values.birth); // Assuming it's a Date object
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("country", values.country);
        formData.append("city", values.city);
        if (selectedFile) formData.append("image", selectedFile);
        if (selectedFile2) formData.append("cover", selectedFile2);

        const response = await DataService.put(
          `/users/profile/${user.id}`,
          formData
        );
        if (response.data.success) {
          toast.success(
            response?.data?.message ?? "Profile Updated Successfully!"
          );
          dispatch(UpdateUserProfile(response.data.data));
          // getProfileData()
        } else {
          toast.error(
            response?.data?.message ?? "Error Occur while Updating Profile!"
          );
        }
      } catch (error) {
        console.error("Error updating profile", error);
        toast.error(
          error.response?.data?.message ??
            "Error Occurred while Updating Profile!"
        );
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);

    // Optionally, you can also display the image preview immediately after selecting it
    // For example, if you want to display the image in an <img> tag with id "preview"
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile2(file);

    // Optionally, you can also display the image preview immediately after selecting it
    // For example, if you want to display the image in an <img> tag with id "preview"
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("cover").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <MainPagetitle
        mainTitle="edit profile"
        pageTitle="edit profile"
        parentTitle="Profile"
        parentPageUrl="/profile"
      />
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-xl-3 col-lg-4">
            <div className="clearfix">
              <div className="card card-bx profile-card author-profile m-b30">
                <div className="card-body">
                  <div className="p-5">
                    <div className="author-profile">
                      <div className="author-media">
                        <img src={IMAGES.Tab1} alt="" />
                        <div
                          className="upload-link"
                          title=""
                          data-toggle="tooltip"
                          data-placement="right"
                          data-original-title="update"
                        >
                          <input type="file" className="update-flie" />
                          <i className="fa fa-camera"></i>
                        </div>
                      </div>
                      <div className="author-info">
                        <h6 className="title">Nella Vita</h6>
                        <span>Developer</span>
                      </div>
                    </div>
                  </div>
                  <div className="info-list">
                    <ul>
                      <li>
                        <Link to={"/profile"}>Models</Link>
                        <span>36</span>
                      </li>
                      <li>
                        <Link to={"/uc-lightgallery"}>Gallery</Link>
                        <span>3</span>
                      </li>
                      <li>
                        <Link to={"/profile"}>Lessons</Link>
                        <span>1</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="input-group mb-3">
                    <div className="form-control rounded text-center bg-white">
                      Portfolio
                    </div>
                  </div>
                  <div className="input-group">
                    <a
                      href="https://collectivesphere.biz/"
                      target="blank"
                      className="form-control text-primary rounded text-start bg-white"
                    >
                      https://collectivesphere.biz/
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-xl-12 col-lg-12">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">{t("profileUpdate")}</h6>
              </div>
              <form className="profile-form" onSubmit={formik.handleSubmit}>
                <div className="card-body">
                  <div className="row">
                    <div className="photo-content author-profile">
                      <div
                        className="upload-link"
                        title=""
                        data-toggle="tooltip"
                        data-placement="right"
                        data-original-title="update"
                      >
                        <input
                          onChange={handleFileChange2}
                          type="file"
                          accept="image/*"
                          className="update-flie"
                        />
                        <i className="fa fa-camera"></i>
                      </div>
                      <img
                        width={"100%"}
                        height={"100px"}
                        src={
                          profileData?.coverImage
                            ? imageUrl + profileData?.coverImage
                            : selectedFile2
                            ? URL.createObjectURL(selectedFile2)
                            : cover
                        }
                        className="cover-photo object-fit-cover rounded"
                        alt="profile"
                        id="cover"
                      />
                    </div>
                    <div className="profile-info">
                      <div className="author-profile">
                        <div
                          style={{ position: "relative" }}
                          className="relative"
                        >
                          <img
                            id="image"
                            width={100}
                            height={100}
                            style={{ borderRadius: 100 }}
                            src={
                              profileData && profileData.profileImage
                                ? imageUrl + profileData.profileImage
                                : selectedFile
                                ? URL.createObjectURL(selectedFile)
                                : IMAGES.Tab1
                            }
                            alt=""
                          />
                          <div
                            className="upload-link"
                            title=""
                            data-toggle="tooltip"
                            data-placement="right"
                            data-original-title="update"
                          >
                            <input
                              onChange={handleFileChange}
                              type="file"
                              accept="image/*"
                              className="update-flie"
                            />
                            <i className="fa fa-camera"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("fullName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.errors.name && (
                        <div className="text-danger fs-12">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("emailAddress")}</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <div className="text-danger fs-12">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                    {/* <div className="col-sm-6 m-b30">
                      <label className="form-label">Password</label>
                      <input
                        type="text"
                        className="form-control"
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {formik.errors.password && (
                        <div className="text-danger fs-12">{formik.errors.password}</div>
                      )}
                    </div> */}
                    {/* <div className="col-sm-6 m-b30">
                        <label className="form-label">Surname</label>
                        <input
                          type="text"
                          className="form-control"
                          name="surname"
                          onChange={formik.handleChange}
                          value={formik.values.surname}
                        />
                    </div> */}
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("role")}</label>
                      <Select
                        options={options1}
                        className="custom-react-select"
                        isSearchable={false}
                        name="role"
                        id="role"
                        // onChange={formik.handleChange}
                        // value={formik.values.role}
                        onChange={(selectedOption) =>
                          formik.setFieldValue("role", selectedOption.value)
                        }
                        value={options1.find(
                          (option) => option.value === formik.values.role
                        )}
                      />
                      {formik.errors.role && (
                        <div className="text-danger fs-12">
                          {formik.errors.role}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("gender")}</label>
                      <Select
                        options={options2}
                        className="custom-react-select"
                        isSearchable={false}
                        name="gender"
                        // onChange={formik.handleChange}
                        // value={formik.values.gender}
                        onChange={(selectedOption) =>
                          formik.setFieldValue("gender", selectedOption.value)
                        }
                        value={options2.find(
                          (option) => option.value === formik.values.gender
                        )}
                      />
                      {formik.errors.gender && (
                        <div className="text-danger fs-12">
                          {formik.errors.gender}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("dateOfBirth")}</label>
                      <input
                        type="date"
                        className="form-control"
                        id="birth"
                        name="birth"
                        value={
                          new Date(formik.values.birth)
                            .toISOString()
                            .split("T")[0]
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("phone")}</label>
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone && (
                        <div className="text-danger fs-12">
                          {formik.errors.phone}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("country")}</label>
                      <select
                        name="country"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                      >
                        {CountriesJson.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("city")}</label>
                      <input
                        className="form-control"
                        name="city"
                        onChange={formik.handleChange}
                        value={formik?.values?.city ?? ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {t("update")}
                  </button>
                  {/* <Link to={"#"} className="btn-link">
                    Forgot your password?
                  </Link> */}
                </div>
              </form>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12">
            <div className="card profile-card card-bx m-b30">
              <form
                className="profile-form"
                onSubmit={formikPassword.handleSubmit}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("oldPassword")}</label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        name="oldPassword"
                        onChange={formikPassword.handleChange}
                        value={formikPassword.values.oldPassword}
                      />
                      {formikPassword.errors.oldPassword && (
                        <div className="text-danger fs-12">
                          {formikPassword.errors.oldPassword}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("newPassword")}</label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        onChange={formikPassword.handleChange}
                        value={formikPassword.values.newPassword}
                      />
                      {formikPassword.errors.newPassword && (
                        <div className="text-danger fs-12">
                          {formikPassword.errors.newPassword}
                        </div>
                      )}
                    </div>
                    <div className=" mb-3">
                      <button type="submit" className="btn btn-primary">
                        {t("updatePassword")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
