import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from "../../../constant/theme";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import * as Yup from "yup";
import dummyImage from "../../../../images/avatar/1.png";
import DataService from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const options1 = [
  { value: "", label: "Select" },
  { value: "6605310b9d6e1361b3bfb502", label: "Outing" },
  { value: "660531189d6e1361b3bfb505", label: "Party" },
  { value: "6605313e9d6e1361b3bfb508", label: "Food" },
  { value: "6605314e9d6e1361b3bfb50b", label: "Business" },
  { value: "6605325d5b30dffd871dd397", label: "Traveling" },
];

const options2 = [
  { value: "", label: "Select" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const options3 = [
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
  { value: "china", label: "China" },
  { value: "india", label: "India" },
];
const options4 = [
  { value: "krasnodar", label: "Krasnodar" },
  { value: "tyumen", label: "Tyumen" },
  { value: "chelyabinsk", label: "Chelyabinsk" },
  { value: "moscow", label: "Moscow" },
];

const EditEvent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [event, setEvent] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    getEventdetail();
    getdata();
  }, []);

  const getdata = async () => {
    const response = await DataService.get("/events-categories/");
    console.log(response.data.data);
    let cate = response?.data?.data ?? [];
    if (cate && cate.length > 0) {
      let data = [];
      cate.map((ele) => {
        data.push({ value: ele._id, label: cap(ele.name) });
      });
      console.log("=d=", data);
      setCategories(data ?? []);
    }
  };

  const getEventdetail = async () => {
    const response = await DataService.get(`/events/${id}`);
    console.log(response.data.data);
    setEvent(response?.data?.data ?? {});
    console.log(response?.data?.data?.dateTime);
    let date = new Date(response?.data?.data?.dateTime);
    console.log(date);
    formik.setValues(response?.data?.data ?? {});
    formik.setFieldValue("dateTime", date);
  };

  const cap = (txt) => {
    if (typeof txt !== "string") {
      return "Input is not a string";
    }
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    category: Yup.string().required("Category is required"),
    dateTime: Yup.date().required("Date is required"),
    location: Yup.string().required("Location is required"),
    price: Yup.number().required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      category: "",
      dateTime: new Date(),
      location: "",
      price: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        values.dateTime = new Date(values.dateTime);
        console.log("Events added", values);
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("category", values.category);
        formData.append("dateTime", values.dateTime); // Assuming it's a Date object
        formData.append("location", values.location);
        formData.append("price", values.price);
        formData.append("image", selectedFile);
        const response = await DataService.put(`/events/${id}`, formData);
        if (response.data.success) {
          toast.success("Event Updated Successfully!");
        } else {
          toast.error(
            response?.data?.message ?? "Error Occur while Updating Event!"
          );
        }
      } catch (error) {
        console.error("Error Updated event");
        toast.error(
          error.response?.data?.message ?? "Error Occur while Updateing Event!"
        );
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  console.log(categories);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">{t("editEvent")}</h6>
                <div className="author-profile">
                  <div style={{ position: "relative" }} className="relative">
                    <img
                      id="image"
                      width={100}
                      height={100}
                      style={{ borderRadius: 100 }}
                      src={
                        event && event.image
                          ? imageUrl + event.image
                          : selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : IMAGES.Tab1
                      }
                      alt=""
                    />
                    <div
                      className="upload-link"
                      title=""
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                    >
                      <input
                        onChange={handleFileChange}
                        type="file"
                        accept="image/*"
                        className="update-flie"
                      />
                      <i className="fa fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
              <form className="profile-form" onSubmit={formik.handleSubmit}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">{t("title")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                      />
                      {formik.errors.title && (
                        <div className="text-danger fs-12">
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={3}
                        type="text"
                        className="form-control"
                        name="description"
                        id="description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      />
                      {formik.errors.description && (
                        <div className="text-danger fs-12">
                          {formik.errors.description}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("category")}</label>
                      <select
                        name="category"
                        onChange={formik.handleChange}
                        value={formik.values.category}
                        className="form-control"
                        id=""
                      >
                        {categories.map((category) => (
                          <option value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.category && (
                        <div className="text-danger fs-12">
                          {formik.errors.category}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        className="form-control"
                        id="dateTime"
                        name="dateTime"
                        value={
                          formik.values.dateTime.toISOString().split("T")[0]
                        }
                        onChange={formik.handleChange}
                      />
                      {formik.errors.dateTime && (
                        <div className="text-danger fs-12">
                          {formik.errors.dateTime}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("price")}</label>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        onChange={formik.handleChange}
                        value={formik.values.price}
                      />
                      {formik.errors.price && (
                        <div className="text-danger fs-12">
                          {formik.errors.price}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("location")}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="location"
                        onChange={formik.handleChange}
                        value={formik.values.location}
                      />
                      {formik.errors.location && (
                        <div className="text-danger fs-12">
                          {formik.errors.location}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {t("update")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditEvent;
