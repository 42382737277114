import React, { useRef, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import FoldersList from "./FoldersList";
import FilesList from "./FilesList";
import { useTranslation } from "react-i18next";
import DataService from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { Button, Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";

const Media = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [rootFolderID, setRootFolderID] = useState(null);
  const [singleFolderData, setSingleFolderData] = useState(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [reloadState, setReloadState] = useState(false);
  const handleFileModalClose = () => setShowFileModal(false);
  const showLoading = useSelector((state) => state.auth.showLoading);

  const changeFolder = async (id) => {
    setSelectedFolder(id);
    console.log("check folder id", id);
  };

  const onRootFolder = async (id) => {
    setRootFolderID(id);
    console.log("check root folder id", id);
  };

  const handleFileModalShow = () => {
    if (selectedFolder) {
      setShowFileModal(true);
    } else {
      toast.info(`${t("selectAnyFolder")}`);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedFile(files);
  };

  const getFolderOne = async (id) => {
    try {
      const res = await DataService.get(`/media/folders/${id}`);
      setSingleFolderData(res.data.data);
      console.log("set index single data", res.data.data);
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      if (selectedFile.length === 0) {
        toast.error("Please select any file to upload!");
        return;
      }
      if (selectedFolder == null) {
        toast.error("Please select destination folder!");
        return;
      }
      formData.append("folder", selectedFolder);
      formData.append("type", values.type);
      formData.append("sharedWith", user.email + "," + values.sharedWith);
      formData.append("name", values.name);
      // formData.append("file", selectedFile);
      selectedFile.forEach((file) => formData.append("file", file));
      try {
        dispatch(loadingToggleAction(true));
        await DataService.post("/media/files/upload", formData);
        dispatch(loadingToggleAction(false));
        toast.success("File Uploaded Successfully");
        setReloadState(true);
        // getSingleFolder();
        // getData();
        handleFileModalClose(false);
      } catch (error) {
        dispatch(loadingToggleAction(false));
        toast.error(error?.response?.data?.message ?? "File Upload Failed!");
      }
    },
  });

  return (
    <>
      <div className="h-80 overflow-hidden">
        {/* <PageTitle activeMenu="Media" motherMenu={t("Media")} /> */}
        <MainPagetitle mainTitle="Media" pageTitle="Media" parentTitle="Dashboard" parentPageUrl="/media" />
        <div className="row">
          <div className="col-12">
            <div className="card" style={{ border: "none" }}>
              <div className="card-body pt-5">
                <div className=" gap-4 justify-content-center">
                  <div className="row">
                    <FoldersList
                      selectedFolder={selectedFolder}
                      changeFolder={changeFolder}
                      setRootFolderID={setRootFolderID}
                      getFolderOne={getFolderOne}
                      handleFileModalShow={handleFileModalShow}
                    />
                  </div>
                  <div className="row ">
                    <FilesList
                      reloadState={reloadState}
                      setReloadState={setReloadState}
                      selectedFolder={selectedFolder}
                      changeFolder={changeFolder}
                      rootFolderID={rootFolderID}
                      singleFolderData={singleFolderData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showFileModal} onHide={handleFileModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("uploadFile")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                type="hidden"
                className="form-control"
                name="parent"
                onChange={formik.handleChange}
                value={"selectedFolder"}
                id=""
              />
              <label htmlFor="">{t("fileName")}:</label>
              <input
                type="name"
                className="form-control"
                name="name"
                onChange={formik.handleChange}
                id=""
              />
              <label htmlFor="">{t("type")}:</label>
              <select
                className="form-control"
                name="type"
                id=""
                onChange={formik.handleChange}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
              <label htmlFor="">
                {t("shareWithCommaSeparated")}
              </label>
              <input
                type="name"
                className="form-control"
                name="sharedWith"
                disabled={formik.values.type === "public" ? true : false}
                onChange={formik.handleChange}
                id=""
              />
              <label htmlFor="icon">{t("selectFile")}:</label>
              <div
                className="drop-zone border border-primary rounded p-5 text-center"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current.click()}
              >
                {selectedFile.length > 0 ? (
                  <div>
                    {selectedFile.map((file, index) => (
                      <p key={index}>
                        {t("selectedFile")}: <b>{file.name}</b>
                      </p>
                    ))}
                  </div>
                ) : (
                  <p>{t("drag&DropFile")}</p>
                )}
                <input
                  ref={fileInputRef}
                  name="file"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="form-control"
                  multiple // Allow multiple file selection
                  style={{ display: "none" }} // Hide the input field visually
                />
              </div>
              {/* <label htmlFor="">Choose File:</label>
            <input
              type="file"
              className="form-control"
              name="file"
              onChange={handleFileChange}
              id=""
            /> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-sm"
              variant="secondary"
              onClick={handleFileModalClose}
            >
              {t("close")}
            </Button>
            <Button
              disabled={showLoading}
              className="btn btn-sm"
              variant="primary"
              onClick={formik.handleSubmit}
            >
              {t("create")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Media;
