import { toast } from "react-toastify";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export var SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export var SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export var LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export var LOGIN_FAILED_ACTION = "[login action] failed login";
export var LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export var LOGOUT_ACTION = "[Logout action] logout action";
export var updateProfile = "Update Profile";

export function signupAction(values, navigate) {
  return (dispatch) => {
    signUp(values)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate("/dashboard");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    dispatch(loadingToggleAction(true));
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate);
        dispatch(loginConfirmedAction(response.data));
        dispatch(loadingToggleAction(false));
        navigate("/dashboard");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        dispatch(loadingToggleAction(false));
      });
    dispatch(loadingToggleAction(false));
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function UpdateUserProfile(data) {
  return {
    type: updateProfile,
    payload: data,
  };
}
