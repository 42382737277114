import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Projects from "./components/Dashboard/Projects";
import TaskSummary from "./components/Dashboard/TaskSummary";
import HomeBlog from "./components/Dashboard/Blog";
import ManageClient from "./components/Dashboard/ManageClient";
import Report from "./components/Dashboard/Report";
import Finance from "./components/Dashboard/Finance";
import Task from "./components/Dashboard/Task";
import CoreHr from "./components/Dashboard/CoreHr";

//Update Pages
import SvgIcons from "./components/Dashboard/SvgIcons";

//Apps
import Contacts from "./components/AppsMenu/Contacts";
import Media from "./components/AppsMenu/Media";
import User from "./components/AppsMenu/AppProfile/User";
import UserRoles from "./components/AppsMenu/AppProfile/UserRoles";
import AddRole from "./components/AppsMenu/AppProfile/AddRole";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import AppProfile2 from "./components/AppsMenu/AppProfile/AppProfile2";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import CustomerProfile from "./components/AppsMenu/AppProfile/CustomerProfile";
import AppCustomer from "./components/AppsMenu/AppProfile/AppCustomer";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

//CMS
import Content from "./components/Cms/Content";
import Menu from "./components/Cms/Menu";
import EmailTemplate from "./components/Cms/EmailTemplate";
import CmsBlog from "./components/Cms/Blog";
import ContentAdd from "./components/Cms/ContentAdd";
import AddMail from "./components/Cms/AddMail";
import AddBlog from "./components/Cms/AddBlog";
import BlogCategory from "./components/Cms/BlogCategory";

//Aikit pages
import AutoWriter from "./components/Aikit/AutoWriter";
import Scheduler from "./components/Aikit/Scheduler";
import Repurpose from "./components/Aikit/Repurpose";
import RSS from "./components/Aikit/Rss";
import Chatbot from "./components/Aikit/Chatbot";
import FineTuneModels from "./components/Aikit/FineTuneModels";
import AIMenu from "./components/Aikit/AIMenu";
import Settings from "./components/Aikit/Settings";
import ImportExport from "./components/Aikit/ImportExport";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
// Widget
import Widget from "./pages/Widget";
/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";
/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import { ThemeContext } from "../context/ThemeContext";
import AddUser from "./components/AppsMenu/AppProfile/AddUser";
import EditUser from "./components/AppsMenu/AppProfile/EditUser";
import UpdateBlogCategory from "./components/Cms/UpdateBlogCategory";
import UpdateBlog from "./components/Cms/UpdateBlog";
import BlogComment from "./components/Dashboard/blogComment";
import BlogDetail from "./components/Dashboard/blogDetail";

import AddEvent from "./components/AppsMenu/Calendar/AddEvent";
import EditEvent from "./components/AppsMenu/Calendar/EditEvent";
import EventCategories from "./components/AppsMenu/Calendar/EventCategories";
import ResetPassword from "./pages/ResetPassword";
import { useSelector } from "react-redux";
import BlogCommentList from "./components/Cms/BlogCommentList";

const Markup = () => {
  const user = useSelector((state) => state.auth.auth);
  console.log(user.role.name, "Redux User");
  const allroutes = [
    // Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    // { url: "dashboard-dark", component: <DashboardDark/> },
    { url: "core-hr", component: <CoreHr /> },
    { url: "project", component: <Projects /> },
    { url: "task-summary", component: <TaskSummary /> },
    { url: "blog-section", component: <HomeBlog /> },
    { url: "blog-comment", component: <BlogComment /> },
    { url: "comment-list", component: <BlogCommentList /> },
    { url: "blog-detail/:id", component: <BlogDetail /> },
    { url: "manage-client", component: <ManageClient /> },
    { url: "reports", component: <Report /> },
    { url: "finance", component: <Finance /> },
    { url: "task", component: <Task /> },
    { url: "media", component: <Media /> },

    ///Cms
    { url: "blog", component: <CmsBlog /> },
    { url: "add-content", component: <ContentAdd /> },
    { url: "add-blog", component: <AddBlog /> },
    { url: "blog/:id", component: <UpdateBlog /> },
    { url: "blog-category", component: <BlogCategory /> },
    { url: "blog-category/:id", component: <UpdateBlogCategory /> },

    //Apps
    {
      url: "user",
      component: ["admin", "super-admin"].includes(user.role.name) ? (
        <User />
      ) : (
        <NotFound />
      ),
    },
    { url: "user-roles", component: <UserRoles /> },
    { url: "add-role", component: <AddRole /> },
    { url: "profile", component: <AppProfile /> },
    { url: "edit-profile", component: <EditProfile /> },
    {
      url: "add-user",
      component: ["admin", "super-admin"].includes(user.role.name) ? (
        <AddUser />
      ) : (
        <NotFound />
      ),
    },
    {
      url: "edit-user/:id",
      component: ["admin", "super-admin"].includes(user.role.name) ? (
        <EditUser />
      ) : (
        <NotFound />
      ),
    },
    // Apps
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "events", component: <Calendar /> },

    //events
    { url: "add-event", component: <AddEvent /> },
    { url: "edit-event/:id", component: <EditEvent /> },
    { url: "event-categories", component: <EventCategories /> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
