import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from "../../layouts/MainPagetitle";
import CardWidget from "./elements/CardWidget";
import ToDoList from "./elements/ToDoList";
import EmployeesTableList from "./elements/EmployeesTableList";
import { useSelector } from "react-redux";
import Blog from "./BlogSection";
import Blogs from "./Blogs";

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const user = useSelector((state) => state.auth.auth);
  // useEffect(() => {
  //   changeBackground({ value: "light", label: "Light" });
  // }, []);

  return (
    <>
      <MainPagetitle
        mainTitle={"Dashboard"}
        pageTitle="Dashboard"
        parentTitle="Home"
      />
      <div
        className="container-fluid mx-auto row gap-2 py-0 mt-3"
        style={{ paddingRight: 0 }}
      >
        <div className="row">
          <CardWidget />
        </div>
        <div className="row gap-sm-0 gap-2">
          <div className="col-xl-8">
            <div className="shadow rounded" style={{ height: "400px" }}>
              <Blogs />
            </div>
          </div>
          <div className="col-xl-4" style={{ height: "400px" }}>
            <ToDoList />
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ height: "400px" }}>
            {["admin", "super-admin"].includes(user.role.name) && (
              <EmployeesTableList />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
