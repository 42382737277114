import React from "react";
import { Link } from "react-router-dom";

const BlogCommentTitle = ({ motherMenu, activeMenu, pageContent }) => {
  let path = window.location.pathname.split("/");

  return (
    <>
      <div className="row page-titles mx-0 mt-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${path[path.length - 1]}`}>{motherMenu}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={`/comment-list`}>{activeMenu}</Link>
          </li>
        </ol>
      </div>
    </>
  );
};

export default BlogCommentTitle;
