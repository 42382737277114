import React, { Fragment, useEffect, useReducer, useState, useTransition } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import cover from "../../../../images/profile/cover.jpg";
import { IMAGES } from "../../../constant/theme";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../../../../services/AxiosInstance";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const AppProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((stte) => stte.auth.auth);
  const [profileData, setProfileData] = useState();
  const [role, setrole] = useState();

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      dispatch(loadingToggleAction(true));
      const response = await DataService.get(`/users/${user.id}`);
      console.log(response.data);
      if (response.data.success) {
        setProfileData(response?.data?.data ?? {});
        console.log(response?.data?.data?.role);
        const response2 = await DataService.get(
          `/roles/${response?.data?.data?.role}`
        );
        console.log(response2.data);
        if (response2.data.success) {
          setrole(response2?.data?.data ?? {});
        }
      }
      dispatch(loadingToggleAction(false));
    } catch (error) {
      toast.error(error.response.data.message ?? "Something Went Wrong@");
    }
  };

  return (
    <Fragment>
      <MainPagetitle
        mainTitle="Profile"
        pageTitle="Profile"
        parentTitle="Dashboard"
        parentPageUrl="/dashboard"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content ">
                  {/* <img className="cover-photo rounded" src="" /> */}
                  <img
                    width={"100%"}
                    height={"100px"}
                    src={
                      profileData?.coverImage
                        ? imageUrl + profileData?.coverImage
                        : cover
                    }
                    className="cover-photo rounded object-fit-cover"
                    alt="profile"
                  />
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img
                      src={
                        profileData?.profileImage
                          ? imageUrl + profileData?.profileImage
                          : IMAGES.Tab1
                      }
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0 text-capitalize">
                        {profileData?.name}
                      </h4>
                      <p>{role?.title}</p>
                    </div>
                    <div className="profile-name px-3 pt-2">
                      {/* <h4 className="text-muted mb-0">{profileData?.email}</h4> */}
                      {/* <p>Email:</p> */}
                    </div>
                    {/* <Dropdown className="dropdown ms-auto">
                      <Dropdown.Toggle
                        variant="primary"
                        className="btn btn-primary light sharp i-false"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          //    xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="18px"
                          height="18px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle
                              fill="#000000"
                              cx="5"
                              cy="12"
                              r="2"
                            ></circle>
                            <circle
                              fill="#000000"
                              cx="12"
                              cy="12"
                              r="2"
                            ></circle>
                            <circle
                              fill="#000000"
                              cx="19"
                              cy="12"
                              r="2"
                            ></circle>
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item className="dropdown-item">
                          <i className="fa fa-user-circle text-primary me-2" />
                          View profile
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item">
                          <i className="fa fa-users text-primary me-2" />
                          Add to close friends
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item">
                          <i className="fa fa-plus text-primary me-2" />
                          Add to group
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item">
                          <i className="fa fa-ban text-primary me-2" />
                          Block
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <Link
                      to={"/edit-profile"}
                      className="ms-auto btn btn-primary h-50 "
                    >
                      {t("editProfile")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row ">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mt-3 mx-3">
                      <div className=" col">
                        <span>{t("email")}:</span>
                        <h3 className="m-b-0">{profileData?.email}</h3>
                      </div>
                      <div className=" col">
                        <span>{t("phoneNumber")}:</span>
                        <h3 className="m-b-0">{profileData?.phone}</h3>
                      </div>
                      <div className=" col">
                        <span>{t("gender")}:</span>
                        <h3 className="m-b-0 text-capitalize">
                          {profileData?.gender}
                        </h3>{" "}
                      </div>
                    </div>
                    <div className=" row my-3 mx-3">
                      <div className=" col">
                        <span>{t("dateOfBirth")}</span>
                        <h3 className="m-b-0 ">
                          {new Date(profileData?.birth).toLocaleDateString()}
                        </h3>
                      </div>
                      <div className=" col">
                        <span>{t("city")}</span>
                        <h3 className="m-b-0 text-capitalize">
                          {profileData?.city}
                        </h3>
                      </div>
                      <div className=" col">
                        <span>{t("country")}</span>
                        <h3 className="m-b-0 text-capitalize">
                          {profileData?.country &&
                            profileData?.country !== "undefined" &&
                            profileData?.country}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary">Today Highlights</h5>
                  </div>
                  <div className="card-body pt-3">
                    <div className="profile-blog ">
                      <img
                        src={IMAGES.Profile1}
                        alt="profile"
                        className="img-fluid  mb-4 w-100 "
                      />
                      <Link to="/post-details">
                        {" "}
                        <h4>Darwin Creative Agency Theme</h4>{" "}
                      </Link>
                      <p className="mb-0">
                        A small river named Duden flows by their place and
                        supplies it with the necessary regelialia. It is a
                        paradisematic country, in which roasted parts of
                        sentences fly into your mouth.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary ">Interest</h5>
                  </div>
                  <div className="card-body pt-3">
                    <div className="profile-interest">
                      <LightGallery
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        elementClassNames="row sp4"
                      >
                        {galleryBlog.map((item, index) => (
                          <div
                            data-src={item.image}
                            className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                            key={index}
                          >
                            <img
                              className="px-1 py-1 img-fluid rounded"
                              src={item.image}
                              style={{ width: "100%" }}
                              alt="gallery"
                            />
                          </div>
                        ))}
                      </LightGallery>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h5 className="text-primary">Our Latest News</h5>
                  </div>
                  <div className="card-body pt-3">
                    <div className="profile-news">
                      {mediaBlog.map((item, index) => (
                        <div className="media pt-3 pb-3" key={index}>
                          <img
                            src={item.image}
                            alt=""
                            className="me-3 rounded"
                            width="75"
                          />
                          <div className="media-body">
                            <h6 className="m-b-5">
                              <Link to="/post-details" className="text-black">
                                Collection of textile samples
                              </Link>
                            </h6>
                            <p className="mb-0">
                              I shared this on my fb wall a few months back, and
                              I thought.
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-xl-8">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey="Posts">
                      <Nav as="ul" className="nav nav-tabs">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link to="#my-posts" eventKey="Posts">
                            Posts
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link to="#about-me" eventKey="About">
                            About Me
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link to="#profile-settings" eventKey="Setting">
                            Setting
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane id="my-posts" eventKey="Posts">
                          <div className="my-post-content pt-3">
                            <div className="post-input">
                              <textarea
                                name="textarea"
                                id="textarea"
                                cols={30}
                                rows={5}
                                className="form-control bg-transparent"
                                placeholder="Please type what you want...."
                                defaultValue={""}
                              />
                              <Link
                                to={"#"}
                                className="btn btn-primary light px-3 me-2"
                                onClick={() => dispatch({ type: "linkModal" })}
                              >
                                <i className="fa fa-link m-0" />{" "}
                              </Link>
                              

                              <Link
                                to={"#"}
                                className="btn btn-primary light px-3 me-1"
                                data-target="#cameraModal"
                                onClick={() =>
                                  dispatch({ type: "cameraModal" })
                                }
                              >
                                <i className="fa fa-camera m-0" />{" "}
                              </Link>
                              

                              <Link
                                to={"#"}
                                className="btn btn-primary ms-1"
                                data-target="#postModal"
                                onClick={() => dispatch({ type: "postModal" })}
                              >
                                Post
                              </Link>
                              
                            </div>

                            <div className="profile-uoloaded-post border-bottom-1 pb-5">
                              <img
                                src={IMAGES.Profile8}
                                alt=""
                                className="img-fluid w-100 rounded"
                              />
                              <Link className="post-title" to="/post-details">
                                <h3 className="text-black">
                                  Collection of textile samples lay spread
                                </h3>
                              </Link>
                              <p>
                                A wonderful serenity has take possession of my
                                entire soul like these sweet morning of spare
                                which enjoy whole heart.A wonderful serenity has
                                take possession of my entire soul like these
                                sweet morning of spare which enjoy whole heart.
                              </p>
                              <button className="btn btn-primary me-2">
                                <span className="me-2">
                                  {" "}
                                  <i className="fa fa-heart" />{" "}
                                </span>
                                Like
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => dispatch({ type: "replyModal" })}
                              >
                                <span className="me-2">
                                  {" "}
                                  <i className="fa fa-reply" />
                                </span>
                                Reply
                              </button>
                            </div>
                            <div className="profile-uoloaded-post border-bottom-1 pb-5">
                              <img
                                src={IMAGES.Profile9}
                                alt=""
                                className="img-fluid w-100 rounded"
                              />
                              <Link className="post-title" to="/post-details">
                                <h3 className="text-black">
                                  Collection of textile samples lay spread
                                </h3>
                              </Link>
                              <p>
                                A wonderful serenity has take possession of my
                                entire soul like these sweet morning of spare
                                which enjoy whole heart.A wonderful serenity has
                                take possession of my entire soul like these
                                sweet morning of spare which enjoy whole heart.
                              </p>
                              <button className="btn btn-primary me-2">
                                <span className="me-2">
                                  <i className="fa fa-heart" />{" "}
                                </span>
                                Like
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => dispatch({ type: "replyModal" })}
                              >
                                <span className="me-2">
                                  <i className="fa fa-reply" />
                                </span>
                                Reply
                              </button>
                            </div>
                            <div className="profile-uoloaded-post pb-3">
                              <img
                                src={IMAGES.Profile8}
                                alt=""
                                className="img-fluid  w-100 rounded"
                              />
                              <Link className="post-title" to="/post-details">
                                <h3 className="text-black">
                                  Collection of textile samples lay spread
                                </h3>
                              </Link>
                              <p>
                                A wonderful serenity has take possession of my
                                entire soul like these sweet morning of spare
                                which enjoy whole heart.A wonderful serenity has
                                take possession of my entire soul like these
                                sweet morning of spare which enjoy whole heart.
                              </p>
                              <button className="btn btn-primary me-2">
                                <span className="me-2">
                                  <i className="fa fa-heart" />
                                </span>
                                Like
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => dispatch({ type: "replyModal" })}
                              >
                                <span className="me-2">
                                  {" "}
                                  <i className="fa fa-reply" />
                                </span>
                                Reply
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane id="about-me" eventKey="About">
                          <div className="profile-about-me">
                            <div className="pt-4 border-bottom-1 pb-3">
                              <h4 className="text-primary">About Me</h4>
                              <p className="mb-2">
                                A wonderful serenity has taken possession of my
                                entire soul, like these sweet mornings of spring
                                which I enjoy with my whole heart. I am alone,
                                and feel the charm of existence was created for
                                the bliss of souls like mine.I am so happy, my
                                dear friend, so absorbed in the exquisite sense
                                of mere tranquil existence, that I neglect my
                                talents.
                              </p>
                              <p>
                                A collection of textile samples lay spread out
                                on the table - Samsa was a travelling salesman -
                                and above it there hung a picture that he had
                                recently cut out of an illustrated magazine and
                                housed in a nice, gilded frame.
                              </p>
                            </div>
                          </div>
                          <div className="profile-skills mb-5">
                            <h4 className="text-primary mb-2">Skills</h4>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              {" "}
                              Admin
                            </Link>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              {" "}
                              Dashboard
                            </Link>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              Photoshop
                            </Link>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              Bootstrap
                            </Link>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              Responsive
                            </Link>
                            <Link
                              to="/profile"
                              className="btn btn-primary light btn-xs mb-1 me-1"
                            >
                              Crypto
                            </Link>
                          </div>
                          <div className="profile-lang  mb-5">
                            <h4 className="text-primary mb-2">Language</h4>
                            <Link
                              to="/profile"
                              className="text-muted pe-3 f-s-16"
                            >
                              <i className="flag-icon flag-icon-us" />
                              English
                            </Link>
                            <Link
                              to="/profile"
                              className="text-muted pe-3 f-s-16"
                            >
                              <i className="flag-icon flag-icon-fr" />
                              French
                            </Link>
                            <Link
                              to="/profile"
                              className="text-muted pe-3 f-s-16"
                            >
                              <i className="flag-icon flag-icon-bd" />
                              Bangla
                            </Link>
                          </div>
                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              Personal Information
                            </h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Name<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>Mitchell C.Shay</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Email<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>example@examplel.com</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Availability
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>Full Time (Free Lancer)</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Age<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>27</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  {" "}
                                  Location<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>Rosemont Avenue Melbourne, Florida</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Year Experience
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>07 Year Experiences</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane id="profile-settings" eventKey="Setting">
                          <div className="pt-3">
                            <div className="settings-form">
                              <h4 className="text-primary">Account Setting</h4>
                              <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Email</label>
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">
                                      Password
                                    </label>
                                    <input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <label className="form-label">Address</label>
                                  <input
                                    type="text"
                                    placeholder="1234 Main St"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    Address 2
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Apartment, studio, or floor"
                                    className="form-control"
                                  />
                                </div>
                                <div className="row">
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">City</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-4">
                                    <label className="form-label">State</label>
                                    <select
                                      className="form-control"
                                      id="inputState"
                                      defaultValue="option-1"
                                    >
                                      <option value="option-1">
                                        Choose...
                                      </option>
                                      <option value="option-2">Option 1</option>
                                      <option value="option-3">Option 2</option>
                                      <option value="option-4">Option 3</option>
                                    </select>
                                  </div>
                                  <div className="form-group mb-3 col-md-2">
                                    <label className="form-label">Zip</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <div className="form-check custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="gridCheck"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="gridCheck"
                                    >
                                      Check me out
                                    </label>
                                  </div>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Sign in
                                </button>
                              </form>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <Modal
        className="modal fade"
        show={state.sendMessage}
        onHide={() => dispatch({ type: "sendMessage" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Send Message</h5>
            <Button
              variant=""
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "sendMessage" })}
            ></Button>
          </div>
          <div className="modal-body">
            <form
              className="comment-form"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch({ type: "sendMessage" });
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Name <span className="required">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Author"
                      name="Author"
                      placeholder="Author"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="text-black font-w600">
                      {" "}
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Email"
                      placeholder="Email"
                      name="Email"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label htmlFor="comment" className="text-black font-w600">
                      Comment
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      name="comment"
                      placeholder="Comment"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input
                      type="submit"
                      value="Post Comment"
                      className="submit btn btn-primary"
                      name="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal> */}
      {/* Post Modal */}
      {/* <Modal
        show={state.post}
        className="modal fade"
        id="postModal"
        onHide={() => dispatch({ type: "postModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "postModal" })}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <textarea
              name="textarea"
              id="textarea"
              cols={30}
              rows={5}
              className="form-control mb-2 bg-transparent"
              placeholder="Please type what you want...."
              defaultValue={""}
            />
            <Link className="btn btn-primary btn-rounded mt-1" to="/profile">
              Post
            </Link>
          </div>
        </div>
      </Modal> */}
      {/* Link Modal */}
      {/* <Modal
        show={state.link}
        className="modal fade post-input"
        id="linkModal"
        onHide={() => dispatch({ type: "linkModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Social Links</h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "linkModal" })}
            ></button>
          </div>
          <div className="modal-body">
            <Link className="btn-social me-1 facebook" to="/profile">
              <i className="fab fa-facebook-f" />
            </Link>
            <Link className="btn-social me-1 google-plus" to="/profile">
              {" "}
              <i className="fab fa-google-plus-g" />
            </Link>
            <Link className="btn-social me-1 linkedin" to="/profile">
              <i className="fab fa-linkedin-in" />
            </Link>
            <Link className="btn-social me-1 instagram" to="/profile">
              {" "}
              <i className="fab fa-instagram" />
            </Link>
            <Link className="btn-social me-1 twitter" to="/profile">
              <i className="fab fa-twitter" />
            </Link>
            <Link className="btn-social me-1 youtube" to="/profile">
              <i className="fab fa-youtube" />
            </Link>
            <Link className="btn-social whatsapp" to="/profile">
              <i className="fab fa-whatsapp" />
            </Link>
          </div>
        </div>
      </Modal> */}
      {/* Camera Modal */}
      {/* <Modal
        show={state.camera}
        className="modal fade"
        id="cameraModal"
        onHide={() => dispatch({ type: "cameraModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload images</h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "cameraModal" })}
            ></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-3">
              <span className="input-group-text">Upload</span>
              <div className="form-file">
                <input type="file" className="form-file-input" />
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      {/* Reply Modal */}
      {/* <Modal
        show={state.reply}
        className="modal fade"
        id="replyModal"
        onHide={() => dispatch({ type: "replyModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post Reply</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => dispatch({ type: "replyModal" })}
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <textarea className="form-control" rows="4">
                Message
              </textarea>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => dispatch({ type: "replyModal" })}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Reply
            </button>
          </div>
        </div>
      </Modal> */}
    </Fragment>
  );
};

export default AppProfile;
