import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constant/theme";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = ({ toggle, setToggle }) => {
  return (
    <div className="nav-header shadow-sm">
      <Link to="/dashboard" className="brand-logo justify-content-center">
        <img
          alt="images"
          className="object-fit-contain w-100"
          height={130}
          src={IMAGES.LogoTransparent}
        />
      </Link>
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
