// import React, { useReducer, useState, useEffect } from 'react';
import React, { useState, useEffect, useReducer } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import CkEditorBlog from "../Forms/CkEditor/CkEditorBlog";
import { Button, Collapse } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import DataService from "../../../services/AxiosInstance";
import { IMAGES } from "../../constant/theme";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainPagetitle from "../../layouts/MainPagetitle";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const foodOptions = [
  { value: "food", label: "Food", color: "#FF8B00" },
  { value: "fashion", label: "Fashion", color: "#FFC400" },
  { value: "lifestyle", label: "Lifestyle", color: "#36B37E" },
];

const initialState = true;
const reducer = (state, action) => {
  switch (action.type) {
    case "collpase0":
      return { ...state, collpase0: !state.collpase0 };
    case "collpase1":
      return { ...state, collpase1: !state.collpase1 };
    case "collpase2":
      return { ...state, collpase2: !state.collpase2 };
    case "collpase3":
      return { ...state, collpase3: !state.collpase3 };
    case "collpase4":
      return { ...state, collpase4: !state.collpase4 };
    case "collpase5":
      return { ...state, collpase5: !state.collpase5 };
    case "collpase6":
      return { ...state, collpase6: !state.collpase6 };
    case "collpase7":
      return { ...state, collpase7: !state.collpase7 };
    case "collpase8":
      return { ...state, collpase8: !state.collpase8 };
    case "collpase9":
      return { ...state, collpase9: !state.collpase9 };
    case "collpase10":
      return { ...state, collpase10: !state.collpase10 };
    case "collpase11":
      return { ...state, collpase11: !state.collpase11 };
    case "collpase12":
      return { ...state, collpase12: !state.collpase12 };
    case "collpase13":
      return { ...state, collpase13: !state.collpase13 };
    case "section0":
      return { ...state, section0: !state.section0 };
    case "section1":
      return { ...state, section1: !state.section1 };
    case "section2":
      return { ...state, section2: !state.section2 };
    case "section3":
      return { ...state, section3: !state.section3 };
    case "section4":
      return { ...state, section4: !state.section4 };
    case "section5":
      return { ...state, section5: !state.section5 };
    case "section6":
      return { ...state, section6: !state.section6 };
    case "section7":
      return { ...state, section7: !state.section7 };
    case "section8":
      return { ...state, section8: !state.section8 };
    case "section9":
      return { ...state, section9: !state.section9 };
    default:
      return state;
  }
};

const screenOption = [{ id: "3", title: "Slug", series: "16" }];

const UpdateBlog = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [blogCategories, setBlogCategories] = useState([]);
  const [editBlogs, setEditBlogs] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.auth);

  const getBlogCategory = async () => {
    try {
      const response = await DataService.get(
        "/blog-categories/all-blog-categories"
      );
      setBlogCategories(response.data.data);
      console.log("----------", response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };
  useEffect(() => {
    getBlogCategory();
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [bannerSelectedFile, setBannerSelectedFile] = useState(null);

  const handleBannerFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==banner file==", file);
    setBannerSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("bannerImage").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const fetchData = async () => {
    try {
      const response = await DataService.get(`/blogs/${id}`);
      setEditBlogs(response.data.data);
      formik.setFieldValue("title", response?.data?.data?.title ?? "");
      formik.setFieldValue("image", response?.data?.data?.image ?? "");
      formik.setFieldValue(
        "bannerImage",
        response?.data?.data?.bannerImage ?? ""
      );
      formik.setFieldValue("color", response?.data?.data?.color ?? "");
      formik.setFieldValue(
        "description",
        response?.data?.data?.description ?? ""
      );
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      title: null,
      color: "#000000",
      description: null,
      categories: [],
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("---values", values);
        console.log("---banner---", bannerSelectedFile);
        const formData = new FormData();
        formData.append("image", selectedFile);
        formData.append("title", values.title);
        formData.append("userId", user.id);
        formData.append("description", values.description);
        formData.append("color", values.color);
        formData.append("bannerImage", bannerSelectedFile);
        values.categories.forEach((category) => {
          formData.append("categories[]", category);
        });
        const response = await DataService.put(`/blogs/update/${id}`, formData);
        toast.success("Blog Updated Successfully");
        navigate("/blog");
        setLoading(false);
      } catch (error) {
        toast.error(
          error?.response?.data?.message ??
            "An error occurred while updating the blog."
        );
        setLoading(false);
      }
    },
  });

  const arrayCategories = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      formik.setFieldValue("categories", [...formik.values.categories, value]);
    } else {
      formik.setFieldValue(
        "categories",
        formik.values.categories.filter((category) => category !== value)
      );
    }
  };
  return (
    <>
      <MainPagetitle mainTitle="Update Blog" pageTitle="Update Blog" parentTitle="dashboard" parentPageUrl="/blog"  />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="mb-5 d-flex justify-content-between">
              <ul className="d-flex align-items-center flex-wrap">
                <li>
                  <Link to={"/blog"} className="btn btn-primary">
                  {t("blogList")}
                  </Link>
                </li>
                <li>
                  <Link to={"/blog-category"} className="btn btn-primary mx-1">
                  {t("addBlogCategory")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"#"}
                    className="btn btn-primary open mt-1 mt-md-0"
                    onClick={() => dispatch({ type: "collpase10" })}
                  >
                    {t("screenOption")}
                  </Link>
                </li>
              </ul>
              <Button
                disabled={loading}
                type="submit"
                to={"/blog-category"}
                onClick={formik.handleSubmit}
                className="btn btn-primary me-1 mt-sm-0 mt-1"
              >
                {t("save")}
              </Button>
            </div>
            <Collapse in={state.collpase10}>
              <div className="main-check">
                <div className="row">
                  <h4 className="mb-3">{t("showOnScreen")}</h4>
                  {screenOption.map((item, ind) => (
                    <div className="col-xl-2 col-lg-3 col-sm-4" key={ind}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`flexCheckDefault-${item.series}`}
                          onChange={() => {
                            dispatch({ type: `section${item.id}` });
                          }}
                          defaultChecked
                        />
                        <label
                          className="form-check-label mb-0 text-nowrap"
                          htmlFor={`flexCheckDefault-${item.series}`}
                        >
                          {item.title}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Collapse>
            <div className="row">
              <div className="col-6">
                <div className="mb-3 ">
                  <label htmlFor="title">{t("title")}</label>
                  <input
                    type="text"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3 ">
                  <label htmlFor="">{t("badgeColor")}</label>
                  <input
                    type="color"
                    name="color"
                    onChange={formik.handleChange}
                    value={formik.values.color}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="mb-3">
                  <label htmlFor="">Image</label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="form-control update-flie"
                    placeholder=""
                  />
                </div>
                <img
                  id="image"
                  className=""
                  width={100}
                  height={100}
                  style={{ borderRadius: 100, objectFit: "cover" }}
                  src={
                    formik.values.image
                      ? imageUrl + formik.values.image
                      : IMAGES.Tab1
                  }
                  alt=""
                />
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label htmlFor="bannerImage">{t("banner")} Image</label>
                  <input
                    type="file"
                    name="bannerImage"
                    accept="image/*"
                    onChange={handleBannerFileChange}
                    className="form-control update-flie"
                    placeholder=""
                  />
                </div>
                <img
                  id="bannerImage"
                  className=""
                  width={100}
                  height={100}
                  style={{ borderRadius: 100, objectFit: "cover" }}
                  src={
                    formik.values.bannerImage
                      ? imageUrl + formik.values.bannerImage
                      : IMAGES.Tab1
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xl-8 col-xxl-12">
                <label for="">Description</label>
                <div className="card h-auto">
                  <div className="card-body p-2">
                    <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                      <CkEditorBlog formik={formik} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-12">
                <label for="">{t("categories")}</label>
                {!state.section7 && (
                  <div className="filter cm-content-box box-primary">
                    <div
                      className="content-title"
                      style={{ justifyContent: "end" }}
                    >
                      <div className="tools">
                        <Link
                          to={"#"}
                          className={`SlideToolHeader ${
                            state.collpase4 ? "collapse" : "expand"
                          }`}
                          onClick={() => dispatch({ type: "collpase4" })}
                        >
                          <i className="fas fa-angle-up"></i>
                        </Link>
                      </div>
                    </div>
                    <Collapse in={!state.collpase4}>
                      <div
                        className="cm-content-body publish-content form excerpt"
                        style={{ border: "none" }}
                      >
                        {blogCategories.map((category, index) => {
                          return (
                            <div className="p-1 mb-3" key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  name="categories"
                                  onChange={arrayCategories}
                                  value={category._id}
                                  type="checkbox"
                                  id={`flexCheckDefault-${index}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexCheckDefault-${index}`}
                                >
                                  {category.name}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Collapse>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBlog;
