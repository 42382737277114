import React, { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import backgroundImage from "../../images/auth-bg.png";
import logo from "../../images/logo/logo-full.png";
import { useFormik } from "formik";
import DataService from "../../services/AxiosInstance";
import { toast } from "react-toastify";
import * as Yup from "yup";
const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate("");
  const [heartActive, setHeartActive] = useState(true);
  console.log("==token==", token);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Both Must be Same"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("==val==", values);
      let obj = {
        password: values.password,
        resetAuthToken: token,
      };
      console.log("==send==", obj);
      try {
        const response = await DataService.put(`/auth/resetPassword`, obj);
        if (response.data.success) {
          toast.success("Password Reset Successfully!");
          navigate("/login");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error ?? "Internal Server Error");
      }
    },
  });

  return (
    <div className="">
      <div className="row gx-0 bg-white vh-100 d-flex align-items-center justify-content-center position-relative">
        <div
          className="col-xl-5 col-lg-6 col-md-6 col-sm-12"
          style={{ zIndex: 999999 }}
        >
          <div className="login-form">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="auth-form">
                  <div className="text-center mb-3">
                    <Link to="/dashboard">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <h4 className="text-center mb-4 ">Reset Password</h4>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <label className="">
                        <strong>Password</strong>
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && (
                        <div className="text-danger fs-12">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <label className="">
                        <strong>Confirm Password</strong>
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.confirmPassword && (
                        <div className="text-danger fs-12">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <div className="d-flex align-items-center gap-2 mt-3">
                    <div
                      className="w-100"
                      style={{ height: "2px", background: "lightGray" }}
                    />{" "}
                    OR{" "}
                    <div
                      className="w-100"
                      style={{ height: "2px", background: "lightGray" }}
                    />
                  </div>
                  <Link to="/login" className="text-align-center mx-auto">
                    <div
                      className="w-100 btn d-flex align-items-center justify-content-center mt-3 "
                      style={{
                        background: "lightGray",
                        color: "black",
                        fontWeight: "normal",
                      }}
                    >
                      Back to login
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={backgroundImage}
          className="position-fixed top-0 left-0  h-100 object-fit-cover"
          alt=""
        />
        <div className="position-fixed top-0 left-0 h-100 w-100 bg-black opacity-50"></div>
        <div
          className="bottom-0 w-100 position-absolute p-2"
          style={{ zIndex: 9999, color: "white" }}
        >
          <div className="text-end">
            <div className="text-end">
              <span>
                {" "}
                © Copyright by{" "}
                <span
                  className={`heart ${heartActive ? "" : "heart-blast"}`}
                  onClick={() => setHeartActive(!heartActive)}
                ></span>
                <a
                  href="https://collectivesphere.biz/"
                  style={{ color: "white" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  Collective Sphere{" "}
                </a>{" "}
                All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
