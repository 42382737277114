import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();
  return (
    <div
      className="footer out-footer"
      style={{ borderTop: "none" }}
    >
      <div className="copyright">
        <p>
        {t("copyRightBy")} ©{/* Designed &amp;  */}{" "}
          <a href="#" target="_blank" rel="noreferrer">
            Collective First
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
