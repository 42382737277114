import React, { useEffect, useRef, useState } from "react";
import DataService from "../../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const EditFolderModal = ({
  handleEditModalClose,
  editFolderModal,
  selectedFolder,
  getData,
}) => {
  const { t } = useTranslation();
  const [folders, setFolders] = useState();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      console.log("Edit Modal Submit");
      const formData = new FormData();
      if (selectedFile) formData.append("file", selectedFile);
      formData.append("type", values.type);
      formData.append("sharedWith", values.sharedWith);
      if (values?.name !== "undefined")
        formData.append("name", values?.name ?? folders?.name);

      try {
        if (selectedFolder) {
          console.log("Error !", selectedFolder);
          const res = await DataService.put(
            "/media/folders/" + selectedFolder,
            formData
          );
          toast.success(res?.data?.message ?? "Folder Updated Successfully");
          handleEditModalClose();
          callApi();
          getData();
          resetForm();
        }
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error creating folder");
        dispatch(loadingToggleAction(false));
      }
    },
  });
  useEffect(() => {
    callApi();
  }, [editFolderModal]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  console.log(selectedFile, "selected file");
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };
  const callApi = async () => {
    try {
      if (selectedFolder) {
        const res = await DataService.get("/media/folders/" + selectedFolder);
        setFolders(res.data.data);
        console.log(res.data);
        console.log("selected Folder", res.data);
        formik.setFieldValue("name", res.data.data.name);
        formik.setFieldValue("type", res.data.data.type);
        formik.setFieldValue("sharedWith", res.data.data.sharedWith);
        formik.setFieldValue("parent", res.data.data.parent);
        return res.data;
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Modal show={editFolderModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("updateFolder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">{t("enterFolderName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formik.values.name}
              placeholder={folders?.name}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              value={formik.values.type}
              id=""
              onChange={formik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              type="name"
              className="form-control"
              name="sharedWith"
              value={formik.values.sharedWith}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="icon">{t("selectFolderIcon")}:</label>
            <div
              className="drop-zone border border-primary rounded p-5 text-center"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              {selectedFile ? (
                <p>
                  {t("selectedFile")}: <b>{selectedFile.name}</b>
                </p>
              ) : (
                <p>{t("drag&DropFile")}</p>
              )}
              <input
                ref={fileInputRef}
                name="icon"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="visually-hidden form-control"
              />
            </div>
            {folders?.url && (
              <>
                <label htmlFor="icon">{t("currentIcon")}:</label>
                <img
                  src={`${imageUrl}${folders.url}`}
                  className="w-100 object-fit-contain"
                  alt=""
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="primary"
            onClick={formik.handleSubmit}
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFolderModal;
