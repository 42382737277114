import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../constant/theme";
import { useDispatch } from "react-redux";
import DataService from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const usergridblog = [
  {
    id: "active",
    image: IMAGES.Friends1,
    title: "Sophia Ava",
    email: "demo1@gmail.com",
    posts: "175",
    followers: "50",
    following: "30",
    position: "Software Engineer",
    Joining: "15 Feb 2023",
  },
  {
    id: "deactive",
    image: IMAGES.User,
    title: "Alexandra Joi",
    email: "demo2@gmail.com",
    posts: "172",
    followers: "47",
    following: "20",
    position: "Web Doveloper",
    Joining: "10 Jan 2023",
  },
  {
    id: "active",
    image: IMAGES.Friends2,
    title: "Amelia Mia",
    email: "demo3@gmail.com",
    posts: "180",
    followers: "90",
    following: "40",
    position: "App Doveloper",
    Joining: "27 March 2022",
  },
  {
    id: "deactive",
    image: IMAGES.Friends3,
    title: "Olivia Emma",
    email: "demo4@gmail.com",
    posts: "1500",
    followers: "310",
    following: "10",
    position: "IT Head",
    Joining: "27 March 2022",
  },
  {
    id: "active",
    image: IMAGES.Friends4,
    title: "Sophia Ava",
    email: "demo1@gmail.com",
    posts: "175",
    followers: "50",
    following: "30",
    position: "Software Engineer",
    Joining: "15 Feb 2023",
  },
  {
    id: "deactive",
    image: IMAGES.User,
    title: "Alexandra Joi",
    email: "demo5@gmail.com",
    posts: "172",
    followers: "47",
    following: "20",
    position: "Web Doveloper",
    Joining: "10 Jan 2023",
  },
  {
    id: "active",
    image: IMAGES.Friends1,
    title: "Amelia Mia",
    email: "demo6@gmail.com",
    posts: "180",
    followers: "90",
    following: "40",
    position: "App Doveloper",
    Joining: "27 March 2022",
  },
  {
    id: "active",
    image: IMAGES.Friends3,
    title: "Olivia Emma",
    email: "demo2@gmail.com",
    posts: "1500",
    followers: "310",
    following: "10",
    position: "IT Head",
    Joining: "27 March 2022",
  },
  {
    id: "active",
    image: IMAGES.Friends2,
    title: "Sophia Ava",
    email: "demo1@gmail.com",
    posts: "175",
    followers: "50",
    following: "30",
    position: "Software Engineer",
    Joining: "15 Feb 2023",
  },
  {
    id: "deactive",
    image: IMAGES.User,
    title: "Alexandra Joi",
    email: "demo2@gmail.com",
    posts: "172",
    followers: "47",
    following: "20",
    position: "Web Doveloper",
    Joining: "10 Jan 2023",
  },
  {
    id: "active",
    image: IMAGES.Friends2,
    title: "Amelia Mia",
    email: "demo3@gmail.com",
    posts: "180",
    followers: "90",
    following: "40",
    position: "App Doveloper",
    Joining: "27 March 2022",
  },
  {
    id: "deactive",
    image: IMAGES.Friends3,
    title: "Olivia Emma",
    email: "demo4@gmail.com",
    posts: "1500",
    followers: "310",
    following: "10",
    position: "IT Head",
    Joining: "27 March 2022",
  },
];

function Items({ currentItems, getUserData }) {
  const deleteUser = async (iid) => {
    try {
      Swal.fire({
        title: "Es-tu sûr ?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprime-le!",
        cancelButtonText: "Annuler"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete(`/users/${iid}`);
          if (response.data.success) {
            toast.success(
              response?.data?.message ?? "User Deleted Successfully!"
            );
            getUserData();
          } else {
            toast.error(
              response?.data?.message ?? "Failed to delete the category."
            );
          }
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Error Occur while Deleting User!"
      );
    }
  };
  return (
    <>
      {currentItems &&
        currentItems?.map((item, index) => (
          <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
            <div className="card border">
              <div className="card-body">
                <div className="card-use-box">
                  <div className="">
                    <img
                      height={80}
                      width={80}
                      src={
                        item?.profileImage
                          ? imageUrl + item?.profileImage
                          : IMAGES.Tab1
                      }
                      className=" rounded-circle"
                      alt=""
                    />
                    {/* <div className={`active `}></div> */}
                  </div>
                  <div className="card__text">
                    <h4 className="mb-0 text-capitalize">{item.name}</h4>
                    <p>
                      {item.email.length > 20
                        ? item.email.slice(0, 20) + "..."
                        : item.email}
                    </p>
                  </div>
                  {/* <ul className="card__info">
                        <li>
                            <span className="card__info__stats">{item.posts}</span>
                            <span>posts</span>
                        </li>
                        <li>
                            <span className="card__info__stats">{item.followers}</span>
                            <span>followers</span>
                        </li>
                        <li>
                            <span className="card__info__stats">{item.following}</span>
                            <span>following</span>
                        </li>
                    </ul> */}
                  <ul className="post-pos">
                    <li>
                      <span className="card__info__stats">Rôle: </span>
                      <span className="text-capitalize">{item.role}</span>
                    </li>
                    <li>
                      <span className="card__info__stats">Genre: </span>
                      <span className="text-capitalize">{item.gender}</span>
                    </li>
                    <li>
                      <span className="card__info__stats">Téléphone: </span>
                      <span>{item.phone}</span>
                    </li>
                    <li>
                      <span className="card__info__stats">Date de naissance: </span>
                      <span>{new Date(item?.birth).toLocaleDateString()}</span>
                    </li>
                  </ul>
                  <div>
                    <Link
                      to={`/edit-user/${item._id}`}
                      className="btn btn-primary btn-sm me-2"
                    >
                      Modifier
                    </Link>{" "}
                    <Link
                      to={"#"}
                      onClick={() => {
                        deleteUser(item._id);
                      }}
                      className="btn btn-secondary btn-sm ms-2"
                    >
                      Supprimer
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

const GridTab = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    getUserData();
  }, []);

  const endOffset = itemOffset + 12;
  const currentItems = tableData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tableData.length / 12);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 12) % tableData.length;
    setItemOffset(newOffset);
  };

  const getUserData = async () => {
    try {
      dispatch(loadingToggleAction(true));
      const response = await DataService.get("/users/");
      setUsers(response?.data?.data ?? []);
      setTableData(response?.data?.data ?? []);
      dispatch(loadingToggleAction(false));
    } catch (error) {
      dispatch(loadingToggleAction(false));
    }
  };

  return (
    <>
      <div className="row">
        <Items currentItems={currentItems} getUserData={getUserData} />
        <div className="d-flex w-100">
          <ReactPaginate
            className="w-100 d-flex justify-content-center gap-5"
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default GridTab;
