import React, { useEffect, useRef, useState } from "react";
import DataService from "../../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ShareFolderModal = ({
  newShareModalShow,
  handleShareModal,
  selectedFolder,
  getData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [folders, setFolders] = useState();
  const showLoading = useSelector((state) => state.auth.showLoading);
  const user = useSelector((state) => state.auth.auth);

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      console.log("Edit Modal Submit");

      if (!values.sharedWith.includes(user.email))
        values.sharedWith =
          user.email.toLowerCase() + "," + values.sharedWith.toLowerCase();
      try {
        const res = await DataService.put(
          "/media/folders/" + selectedFolder,
          values
        );
        toast.success(res?.data?.message ?? "Folder Updated Successfully");
        handleShareModal(false);
        callApi();
        getData();
        resetForm();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error creating folder");
        dispatch(loadingToggleAction(false));
      }
    },
  });
  useEffect(() => {
    callApi();
  }, [newShareModalShow]);

  const callApi = async () => {
    try {
      if (selectedFolder) {
        const res = await DataService.get("/media/folders/" + selectedFolder);
        setFolders(res.data.data);
        console.log(res.data);
        console.log("selected Folder", res.data);
        formik.setFieldValue("name", res.data.data.name);
        formik.setFieldValue("type", res.data.data.type);
        formik.setFieldValue("sharedWith", res.data.data.sharedWith);
        formik.setFieldValue("parent", res.data.data.parent);
        return res.data;
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal show={newShareModalShow} onHide={() => handleShareModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("shareFolder")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="hidden"
            className="form-control"
            name="parent"
            value={selectedFolder}
            onChange={formik.handleChange}
            id=""
          />
          <label htmlFor="">{t("type")}:</label>
          <select
            className="form-control"
            name="type"
            value={formik.values.type}
            id=""
            onChange={formik.handleChange}
          >
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
          <label htmlFor="">{t("shareWithCommaSeparated")}</label>
          <input
            type="sharedWith"
            value={formik.values.sharedWith}
            className="form-control"
            name="sharedWith"
            onChange={formik.handleChange}
            id=""
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-sm"
          variant="secondary"
          onClick={() => handleShareModal(false)}
        >
          {t("close")}
        </Button>
        <Button
          onClick={formik.handleSubmit}
          disabled={showLoading}
          className="btn btn-sm"
          variant="primary"
        >
          {t("share")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareFolderModal;
