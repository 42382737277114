import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import { MenuList, TeamMenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector } from "react-redux";
import { IMAGES } from "../../constant/theme";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = ({ toggle }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.auth);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);
  useEffect(() => {}, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    MenuList.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title });
        }
        item.content?.forEach((ele) => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title });
          }
        });
      });
    });
  }, [path]);

  function ucwords(str) {
    if (!str) return ""; // Check if str is undefined or null

    return str.toLowerCase().replace(/(^|\s)\S/g, function (letter) {
      return letter.toUpperCase();
    });
  }

  return (
    <div
      className={`deznav overflow-hidden ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="deznav-scroll">
        <div className="d-flex gap-1 justify-content-around p-1">
          <div
            className="header-media overflow-hidden rounded-circle bg-light"
            style={{ width: "3rem", height: "3rem" }}
          >
            <img
              src={
                user?.profileImage ? imageUrl + user?.profileImage : IMAGES.Tab1
              }
              alt=""
              className="object-fit-cover w-100"
            />
          </div>
          {!toggle && (
            <div className="d-flex flex-column justify-content-center sidebar-user-details">
              <h6 className="p-0 m-0">
                {ucwords(user?.displayName) ?? "User"}
              </h6>
              <span>{user?.email}</span>
            </div>
          )}
        </div>
        <ul className="metismenu" id="menu">
          {user.role.name === "admin" || user.role.name === "super-admin"
            ? MenuList.map((data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass} key={index}>
                      {t(data.title)}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            <div className="menu-icon">{data.iconStyle}</div>{" "}
                            <span className="nav-text text-capitalize">
                              {t(data.title)}
                              {data.update && data.update.length > 0 ? (
                                <span className="badge badge-xs badge-danger ms-2">
                                  {data.update}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`text-capitalize ${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`text-capitalize ${
                                        state.activeSubmenu === data.title
                                          ? "mm-active"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                      data.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={data.to}
                                            className={
                                              data.hasMenu ? "has-arrow" : ""
                                            }
                                            onClick={() => {
                                              handleSubmenuActive(data.title);
                                            }}
                                          >
                                            {t(data.title)}
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu === data.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`text-capitalize ${
                                                menuClass === "mm-collapse"
                                                  ? "mm-show"
                                                  : ""
                                              }`}
                                            >
                                              {data.content &&
                                                data.content.map(
                                                  (data, ind) => {
                                                    return (
                                                      <li key={ind}>
                                                        <Link
                                                          className={`${
                                                            path === data.to
                                                              ? "mm-active"
                                                              : ""
                                                          }`}
                                                          to={data.to}
                                                        >
                                                          {t(data.title)}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link
                                          to={data.to}
                                          className={`text-capitalize ${
                                            data.to === path ? "mm-active" : ""
                                          }`}
                                        >
                                          {t(data.title)}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link
                          to={data.to}
                          onClick={() => {
                            handleMenuActive(data.title);
                          }}
                        >
                          <div className="menu-icon">{data.iconStyle}</div>{" "}
                          <span className="nav-text text-capitalize">
                            {t(data.title)}
                          </span>
                          {data.update && data.update.length > 0 ? (
                            <span className="badge badge-xs badge-danger ms-2">
                              {data.update}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </li>
                  );
                }
              })
            : TeamMenuList.map((data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass + " text-capitalize"} key={index}>
                      {t(data.title)}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={`text-capitalize ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            <div className="menu-icon">{data.iconStyle}</div>{" "}
                            <span className="nav-text text-capitalize">
                              {t(data.title)}
                              {data.update && data.update.length > 0 ? (
                                <span className="badge badge-xs badge-danger ms-2">
                                  {data.update}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`text-capitalize${
                                        state.activeSubmenu === data.title
                                          ? "mm-active"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                      data.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={data.to}
                                            className={
                                              data.hasMenu ? "has-arrow" : ""
                                            }
                                            onClick={() => {
                                              handleSubmenuActive(data.title);
                                            }}
                                          >
                                            {t(data.title)}
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu === data.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`${
                                                menuClass === "mm-collapse"
                                                  ? "mm-show"
                                                  : ""
                                              }`}
                                            >
                                              {data.content &&
                                                data.content.map(
                                                  (data, ind) => {
                                                    return (
                                                      <li key={ind}>
                                                        <Link
                                                          className={`text-capitalize ${
                                                            path === data.to
                                                              ? "mm-active"
                                                              : ""
                                                          }`}
                                                          to={data.to}
                                                        >
                                                          {t(data.title)}
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link
                                          to={data.to}
                                          className={`text-capitalize ${
                                            data.to === path ? "mm-active" : ""
                                          }`}
                                        >
                                          {t(data.title)}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link to={data.to}>
                          <div className="menu-icon">{data.iconStyle}</div>{" "}
                          <span className="nav-text text-capitalize">
                            {t(data.title)}
                          </span>
                          {data.update && data.update.length > 0 ? (
                            <span className="badge badge-xs badge-danger ms-2">
                              {data.update}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </li>
                  );
                }
              })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
