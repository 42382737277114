import React, { useState, useEffect, useReducer, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Button, Collapse } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import DataService from "../../../services/AxiosInstance";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import MainPagetitle from "../../layouts/MainPagetitle";

const initialState = true;
const reducer = (state, action) => {
  switch (action.type) {
    case "openCollapse1":
      return { ...state, openCollapse1: !state.openCollapse1 };
    case "openCollapse2":
      return { ...state, openCollapse2: !state.openCollapse2 };
    default:
      return state;
  }
};
const BlogCategory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [editCategory, setEditCategory] = useState(null);
  const [blogCategories, setBlogCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = blogCategories.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blogCategories.length / 10);

  const fetchData = async () => {
    try {
      const response = await DataService.get(
        "/blog-categories/all-blog-categories"
      );
      setBlogCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const editBlogCategory = async (id) => {
    try {
      const response = await DataService.get(`/blog-categories/${id}`);
      navigate(`/blog-category/${id}`);
      setEditCategory(response.data.data);
    } catch (error) {
      console.error("Error fetching blog category:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: null,
      color: "#000000",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("----data----", values);
        const formData = new FormData();
        formData.append("image", selectedFile);
        formData.append("name", values.name);
        formData.append("color", values.color);
        const response = await DataService.post(
          "/blog-categories/create",
          formData
        );
        toast.success("Blog Category Created Successfully");
        window.location.reload();
        fetchData();
        setLoading(false);
      } catch (error) {
        toast.error(
          error?.response?.data?.message ?? "Error adding Blog Category!:",
          error
        );
        setLoading(false);
      }
    },
  });

  const deleteBlogCategory = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await DataService.delete(`/blog-categories/${id}`);
          if (deleted) {
            toast.success(deleted.data.message);
            fetchData();
          } else {
            toast.error(
              deleted?.data?.message ?? "Failed to delete the blog category."
            );
          }
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Failed to delete the blog category."
      );
      console.error("Error deleting blog category:", error);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % blogCategories.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <MainPagetitle mainTitle="Blog Category" pageTitle="Blog Category" parentTitle="Dashboard" parentPageUrl="/blog-category"  />
      <div
        className="container-fluid"
        style={{paddingTop: "0.875rem" }}
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-4">
                <div className="filter cm-content-box box-primary">
                  <div className="content-title" style={{ padding: "0px" }}>
                    <div className="cpa">{t("addBlogCategory")}</div>
                    <div className="tools">
                      <Link
                        to={"#"}
                        className={`SlideToolHeader ${
                          state.openCollapse1 ? "collapse" : "expand"
                        }`}
                        onClick={() => dispatch({ type: "openCollapse1" })}
                      >
                        <i className="fas fa-angle-up" />
                      </Link>
                    </div>
                  </div>
                  <Collapse in={!state.openCollapse1}>
                    <div
                      className="cm-content-body  form excerpt"
                      style={{ border: "none" }}
                    >
                      <div className="mt-3">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">{t("name")}</label>
                            <input
                              type="text"
                              name="name"
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              required
                              className="form-control"
                              placeholder={`${t("name")}`}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Image</label>
                            <input
                              type="file"
                              name="image"
                              onChange={handleFileChange}
                              className="form-control"
                              placeholder=""
                              required
                              accept="image/*"
                            />
                          </div>
                          {selectedFile && (
                            <img
                              id="image"
                              className=""
                              required
                              width={100}
                              height={100}
                              style={{ borderRadius: 100, objectFit: "cover" }}
                              src=""
                              alt=""
                            />
                          )}
                          <div className="mb-3 mt-2">
                            <label className="form-label">{t("badgeColor")}</label>
                            <input
                              type="color"
                              name="color"
                              required
                              onChange={formik.handleChange}
                              value={formik.values.color}
                              className="form-control"
                              placeholder="Color"
                            />
                          </div>
                          <div>
                            <button
                              disabled={loading}
                              type="submit"
                              className="btn btn-primary"
                            >
                              {t("save")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="filter cm-content-box box-primary">
                  <div className="content-title" style={{ padding: "0px" }}>
                    <div className="cpa">{t("blogList")}</div>
                    <div className="tools">
                      <Link
                        to={"#"}
                        className={`SlideToolHeader ${
                          state.openCollapse2 ? "collapse" : "expand"
                        }`}
                        onClick={() => dispatch({ type: "openCollapse2" })}
                      >
                        <i className="fas fa-angle-up" />
                      </Link>
                    </div>
                  </div>
                  <Collapse in={!state.openCollapse2}>
                    {/* <div
                      className="cm-content-body publish-content form excerpt"
                      style={{ border: "none" }}
                    >
                      <div className="table-responsive">
                        <div
                          id="blog_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table  table-striped verticle-middle table-responsive-sm">
                            <thead>
                              <tr>
                                <th className="text-black" scope="col">
                                  S.No
                                </th>
                                <th className="text-black" scope="col">
                                  {t("name")}
                                </th>
                                <th scope="col" className="text-black text-end">
                                  {t("action")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems &&
                                currentItems.length > 0 &&
                                currentItems.map((category, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{category.name}</td>
                                    <td>{category.slug}</td>
                                    <td className="text-end">
                                      <div className="">
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            editBlogCategory(category._id)
                                          }
                                          className="btn btn-warning btn-sm content-icon me-1"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Button>
                                        <span> |</span>{" "}
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            deleteBlogCategory(category._id)
                                          }
                                          className="btn btn-danger btn-sm content-icon ms-1"
                                        >
                                          <i className="fa-solid fa-trash" />
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <div className="d-flex w-100 my-3">
                            <ReactPaginate
                              className="w-100 d-flex justify-content-end gap-3"
                              breakLabel="..."
                              nextLabel=" >"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              previousLabel="< "
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="card">
                      <div className="card-body p-0">
                        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                          <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table
                              id="projects-tbl"
                              className="table ItemsCheckboxSec dataTable no-footer mb-0"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th className="">{t("name")}</th>
                                  <th className="">{t("slug")}</th>
                                  <th className="text-end">{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                              {currentItems &&
                                currentItems.length > 0 &&
                                currentItems.map((category, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{category.name}</td>
                                    <td>{category.slug}</td>
                                    <td className="text-end">
                                      <div className="">
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            editBlogCategory(category._id)
                                          }
                                          className="btn btn-warning btn-sm content-icon me-1"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Button>
                                        <span> |</span>{" "}
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            deleteBlogCategory(category._id)
                                          }
                                          className="btn btn-danger btn-sm content-icon ms-1"
                                        >
                                          <i className="fa-solid fa-trash" />
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="d-flex w-100 my-3">
                              <ReactPaginate
                                className="w-100 d-flex justify-content-end gap-3"
                                breakLabel="..."
                                nextLabel=" >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< "
                                renderOnZeroPageCount={null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCategory;
