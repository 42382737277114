const BaseImageUrl = process.env.REACT_APP_IMAGE_URL;

async function validateImageUrl(imageUrl, placeholderUrl = "") {
  console.log(BaseImageUrl + imageUrl);
  try {
    const response = await fetch(BaseImageUrl + imageUrl, {
      method: "HEAD",
    });
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.startsWith("image/")) {
        return imageUrl; // Return the original image URL
      }
    }
    // If the response is not ok or it's not an image, return the placeholder URL
    return placeholderUrl;
  } catch (error) {
    console.error("Error checking image URL validity:", error);
    return placeholderUrl; // Return the placeholder URL in case of an error
  }
}

export { validateImageUrl };
