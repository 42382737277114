import { SVGICON } from "../../constant/theme";

export const MenuList = [
  //Content
  {
    title: "Collective Sphere",
    classsChange: "menu-title",
  },
  //Dashboard
  {
    title: "dashboard",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Home,
    to: "/dashboard",
  },
  {
    title: "user",
    hasMenu: true,
    iconStyle: SVGICON.Employe,
    to: "/user",
  },
  {
    title: "media",
    to: "media",
    iconStyle: SVGICON.Plugins,
  },
  {
    title: "blogs",
    // update:"New",
    iconStyle: SVGICON.Blog,
    content: [
      {
        title: "blogs list",
        to: "blog",
      },
      {
        title: "add blog",
        to: "add-blog",
      },
      {
        title: "blog category",
        to: "blog-category",
      },
      {
        title: "blog section",
        to: "blog-section",
      },
      {
        title: "comment list",
        to: "comment-list",
      },
    ],
  },

  {
    title: "Agenda",
    to: "events",
    iconStyle: SVGICON.Widget,
    content: [
      {
        title: "events",
        to: "events",
      },
      {
        title: "add event",
        to: "add-event",
      },
      {
        title: "event categories",
        to: "event-categories",
      },
    ],
  },
];

export const TeamMenuList = [
  //Content
  {
    title: "Collective Sphere",
    classsChange: "menu-title",
  },
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Home,
    to: "/dashboard",
  },
  {
    title: "Media",
    to: "media",
    iconStyle: SVGICON.Plugins,
  },
  {
    title: "Blogs",
    iconStyle: SVGICON.Blog,
    content: [
      {
        title: "Blog Section",
        to: "blog-section",
      },
    ],
  },

  {
    title: "events",
    to: "events",
    iconStyle: SVGICON.Widget,
    content: [
      {
        title: "events",
        to: "events",
      },
    ],
  },
];

export const AdminMenuList = [
  //Content
  {
    title: "Collective Sphere",
    classsChange: "menu-title",
  },
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Home,
    to: "/dashboard",
  },
  {
    title: "Users",
    hasMenu: true,
    iconStyle: SVGICON.Employe,
    to: "/user",
  },
  {
    title: "Media",
    to: "media",
    iconStyle: SVGICON.Plugins,
  },
  {
    title: "Blogs",
    // update:"New",
    iconStyle: SVGICON.Blog,
    content: [
      {
        title: "Blogs List",
        to: "blog",
      },
      {
        title: "Add Blog",
        to: "add-blog",
      },
      {
        title: "blog category",
        to: "blog-category",
      },
      {
        title: "Blog Section",
        to: "blog-section",
      },
    ],
  },

  {
    title: "events",
    to: "events",
    iconStyle: SVGICON.Widget,
    content: [
      {
        title: "events",
        to: "events",
      },
      {
        title: "add event",
        to: "add-event",
      },
      {
        title: "event categories",
        to: "event-categories",
      },
    ],
  },
];
