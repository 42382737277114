import React from "react";

import EventCalendar from "./EventCalendar";

import PageTitle from "../../../layouts/PageTitle";
import MainPagetitle from "../../../layouts/MainPagetitle";

const Calendar = () => {
  return (
    <div className="h-80">
      <MainPagetitle
        mainTitle="Events"
        pageTitle="Events"
        parentTitle="Dashboard"
        parentPageUrl="/dashboard"
      />
      <div className="container-fluid py-0">
        <EventCalendar />
      </div>
    </div>
  );
};

export default Calendar;
