import React, { useEffect } from "react";
import DataService from "../../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const EditFileModal = ({
  handleUpdateFileModalClose,
  showUpdateFileModal,
  selectedUpdateFile,
  getData,
  selectedFolder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.auth);
  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      values.folderId = selectedFolder;
      if (!values.sharedWith.includes(user.email))
        values.sharedWith =
          user.email.toLowerCase() + "," + values.sharedWith.toLowerCase();
      console.log(values, "Sumbmitted Values");
      try {
        const res = await DataService.post(
          "/media/file/" + selectedUpdateFile._id,
          values
        );
        toast.success(res?.data?.message ?? "File Updated Successfully");
        handleUpdateFileModalClose();
        // getData();
        resetForm();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        console.log(error);
        toast.error(error.message ?? "Error updating file");
        dispatch(loadingToggleAction(false));
      }
    },
  });
  useEffect(() => {
    console.log("selected File", selectedUpdateFile);
    formik.setFieldValue("name", selectedUpdateFile?.name);
    formik.setFieldValue("type", selectedUpdateFile?.type);
    formik.setFieldValue("sharedWith", selectedUpdateFile?.sharedWith);
    formik.setFieldValue("parent", selectedUpdateFile?.parent);
  }, [showUpdateFileModal]);

  return (
    <>
      <Modal show={showUpdateFileModal} onHide={handleUpdateFileModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("updateFile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">{t("enterFileName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formik.values.name}
              placeholder={selectedUpdateFile?.name}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              id=""
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              value={formik.values.sharedWith}
              type="name"
              className="form-control"
              name="sharedWith"
              onChange={formik.handleChange}
              id=""
            />

            {selectedUpdateFile?.url && (
              <>
                <label htmlFor="icon">Image:</label>
                <img
                  src={`${imageUrl}${selectedUpdateFile.url}`}
                  className="w-100 object-fit-contain"
                  alt=""
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="primary"
            onClick={formik.handleSubmit}
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFileModal;
