import React, { useEffect, useState } from "react";
import { SVGICON } from "../../../constant/theme";
import { toast } from "react-toastify";
import DataService from "../../../../services/AxiosInstance";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

const CardWidget = () => {
  const [stats, setStats] = useState({
    users: 0,
    folders: 0,
    blogs: 0,
    events: 0,
  });
  const { t } = useTranslation();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const response = await DataService.get("dashboard/stats");
      if (response.data.success) {
        setStats(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body shadow rounded-lg depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6 className="text-capitalize">
                  {t("totalTeam")}
                </h6>
                <CountUp
                  className={`mb-0 fs-28 fw-bold me-2 text-primary`}
                  end={stats.users}
                  duration={"5"}
                />
              </div>
              <div className="icon-box bg-primary-light">{SVGICON.User}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body shadow rounded-lg depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6 className="text-capitalize">
                  {t("folders")} {t("count")}
                </h6>
                <CountUp
                  className={`mb-0 fs-28 fw-bold me-2 text-primary`}
                  end={stats.folders}
                  duration={"5"}
                />
              </div>
              <div
                className="icon-box  bg-primary-light"
                style={{ color: "red" }}
              >
                {SVGICON.Dotes}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card chart-grd same-card">
          <div className="card-body shadow rounded-lg depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6 className="text-capitalize">
                  {t("blogs")} {t("count")}
                </h6>
                <CountUp
                  className={`mb-0 fs-28 fw-bold me-2 text-primary`}
                  end={stats.blogs}
                  duration={"5"}
                />
              </div>
              <div className="icon-box  bg-primary-light text-danger">
                {SVGICON.EditPrimary}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 ">
        <div className="card chart-grd same-card">
          <div className="card-body shadow rounded-lg depostit-card">
            <div className="depostit-card-media d-flex justify-content-between">
              <div>
                <h6 className="text-capitalize">
                  {t("total")} {t("events")}
                </h6>
                <CountUp
                  className={`mb-0 fs-28 fw-bold me-2 text-primary`}
                  end={stats.events}
                  duration={"5"}
                />
              </div>
              <div className="icon-box  bg-primary-light">
                {SVGICON.CalendarList}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardWidget;
