import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "fr",
  lng: "fr",
  resources: {
    en: {
      translations: require("./localization/en/translation.json"),
    },
    fr: {
      translations: require("./localization/fr/translation.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "fr"];

export default i18n;
