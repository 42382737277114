import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Collapse } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import DataService from "../../../services/AxiosInstance";
import { IMAGES, SVGICON } from "../../constant/theme";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import MainPagetitle from "../../layouts/MainPagetitle";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const tableData = [
  { sr: "1", title: "Lifestyle" },
  { sr: "2", title: "Fashion" },
  { sr: "3", title: "Lifestyle" },
  { sr: "4", title: "Fashion" },
  { sr: "5", title: "Food" },
  { sr: "6", title: "Sasa" },
  { sr: "7", title: "Beautiy" },
  { sr: "8", title: "Food" },
  { sr: "9", title: "Fashion" },
  { sr: "10", title: "Beautiy" },
  { sr: "11", title: "Lifestyle" },
  { sr: "12", title: "Test" },
];

const initialState = true;
const reducer = (state, action) => {
  switch (action.type) {
    case "openCollapse1":
      return { ...state, openCollapse1: !state.openCollapse1 };
    case "openCollapse2":
      return { ...state, openCollapse2: !state.openCollapse2 };
    default:
      return state;
  }
};
const UpdateBlogCategory = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { id } = useParams();
  const [editCategory, setEditCategory] = useState(null);
  const [blogCategories, setBlogCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = blogCategories.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blogCategories.length / 10);

  const formik = useFormik({
    initialValues: {
      name: null,
      color: "#000000",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("--values--", values);
        const formData = new FormData();
        formData.append("image", selectedFile);
        formData.append("name", values.name);
        formData.append("color", values.color);

        const response = await DataService.put(
          `/blog-categories/update/${editCategory._id}`,
          formData
        );
        navigate("/blog-category");
        toast.success("Blog Category Updated Successfully");
        fetchData();
        setLoading(false);
      } catch (error) {
        toast.error(
          error?.response?.data?.message ??
            "An error occurred while updating the blog category."
        );
        setLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      const allBlogsResponse = await DataService.get(
        "/blog-categories/all-blog-categories"
      );
      const response = await DataService.get(`/blog-categories/${id}`);
      setEditCategory(response.data.data);
      formik.setFieldValue("name", response?.data?.data?.name ?? "");
      formik.setFieldValue("image", response?.data?.data?.image ?? "");
      formik.setFieldValue("color", response?.data?.data?.color ?? "");
      setBlogCategories(allBlogsResponse.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % blogCategories.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <MainPagetitle mainTitle="Update Blog Category" pageTitle="Update Blog Category" parentTitle="Dashboard" parentPageUrl="/blog-category"  />
      <div
        className="container-fluid"
        style={{ paddingTop: "0.875rem" }}
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-4">
                <div className="filter cm-content-box box-primary">
                  <div className="content-title" style={{ padding: "0px" }}>
                    <div className="cpa">{t("updateBlogCategory")}</div>
                    <div className="tools">
                      <Link
                        to={"#"}
                        className={`SlideToolHeader ${
                          state.openCollapse1 ? "collapse" : "expand"
                        }`}
                        onClick={() => dispatch({ type: "openCollapse1" })}
                      >
                        <i className="fas fa-angle-up" />
                      </Link>
                    </div>
                  </div>
                  <Collapse in={!state.openCollapse1}>
                    <div
                      className="cm-content-body  form excerpt"
                      style={{ border: "none" }}
                    >
                      <div className="mt-3">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">{t("name")}</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              onChange={formik.handleChange}
                              className="form-control"
                              value={formik.values.name}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Image</label>
                            <input
                              type="file"
                              accept="image/*"
                              name="image"
                              onChange={handleFileChange}
                              className="form-control"
                            />
                          </div>
                          <img
                            id="image"
                            className=""
                            width={100}
                            height={100}
                            style={{ borderRadius: 100 }}
                            src={
                              formik.values.image
                                ? imageUrl + formik.values.image
                                : IMAGES.Tab1
                            }
                            alt=""
                          />
                          <div className="mb-3 mt-2">
                            <label className="form-label">{t("badgeColor")}</label>
                            <input
                              type="color"
                              name="color"
                              onChange={formik.handleChange}
                              value={formik.values.color}
                              className="form-control"
                            />
                          </div>
                          <div>
                            <button
                              disabled={loading}
                              type="submit"
                              className="btn btn-primary"
                            >
                              {t("update")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="filter cm-content-box box-primary">
                  <div className="content-title" style={{ padding: "0px" }}>
                    <div className="cpa">{t("blogList")}</div>
                    <div className="tools">
                      <Link
                        to={"#"}
                        className={`SlideToolHeader ${
                          state.openCollapse2 ? "collapse" : "expand"
                        }`}
                        onClick={() => dispatch({ type: "openCollapse2" })}
                      >
                        <i className="fas fa-angle-up" />
                      </Link>
                    </div>
                  </div>
                  <Collapse in={!state.openCollapse2}>
                    {/* <div
                      className="cm-content-body publish-content form excerpt"
                      style={{ border: "none" }}
                    >
                      <div className="table-responsive">
                        <table className="table  table-striped verticle-middle table-responsive-sm">
                          <thead>
                            <tr>
                              <th className="text-black" scope="col">
                                S.No
                              </th>
                              <th className="text-black" scope="col">
                                {t("name")}
                              </th>
                              <th className="text-black" scope="col">
                                {t("slug")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems &&
                              currentItems.length > 0 &&
                              currentItems.map((category, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{category.name}</td>
                                  <td>{category.slug}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="d-flex w-100 my-3">
                          <ReactPaginate
                            className="w-100 d-flex justify-content-end gap-3"
                            breakLabel="..."
                            nextLabel=" >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< "
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="card">
                      <div className="card-body p-0">
                        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                          <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table
                              id="projects-tbl"
                              className="table ItemsCheckboxSec dataTable no-footer mb-0"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th className="">{t("name")}</th>
                                  <th className="text-end">{t("slug")}</th>
                                </tr>
                              </thead>
                              <tbody>
                              {currentItems &&
                              currentItems.length > 0 &&
                              currentItems.map((category, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{category.name}</td>
                                  <td>{category.slug}</td>
                                </tr>
                              ))}
                              </tbody>
                            </table>
                            <div className="d-flex w-100 my-3">
                              <ReactPaginate
                                className="w-100 d-flex justify-content-end gap-3"
                                breakLabel="..."
                                nextLabel=" >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< "
                                renderOnZeroPageCount={null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBlogCategory;
