import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CountriesJson from "../../../constant/countries.json";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from "../../../constant/theme";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import * as Yup from "yup";
import dummyImage from "../../../../images/avatar/1.png";
import MainPagetitle from "../../../layouts/MainPagetitle";
import DataService from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useTranslation } from "react-i18next";

const options1 = [
  { value: "", label: "Select" },
  { value: "66026da3e28086935bcb5bff", label: "Super Admin" },
  { value: "66026dace28086935bcb5c04", label: "Admin" },
  { value: "66026db7e28086935bcb5c07", label: "Team" },
];

const options2 = [
  { value: "", label: "Select" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const options3 = [
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
  { value: "china", label: "China" },
  { value: "india", label: "India" },
];
const options4 = [
  { value: "krasnodar", label: "Krasnodar" },
  { value: "tyumen", label: "Tyumen" },
  { value: "chelyabinsk", label: "Chelyabinsk" },
  { value: "moscow", label: "Moscow" },
];

const AddUser = () => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    dispatch(loadingToggleAction(true));
    const response = await DataService.get("/roles/");
    setRoles(response?.data?.data ?? []);
    dispatch(loadingToggleAction(false));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      role: "",
      gender: "",
      birth: new Date(),
      phone: null,
      email: "",
      country: null,
      city: null,
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(loadingToggleAction(true));
      try {
        values.birth = new Date(values.birth);
        console.log(values.birth);
        console.log("User added", values);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("role", values.role);
        formData.append("gender", values.gender);
        formData.append("birth", values.birth); // Assuming it's a Date object
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("country", values.country);
        formData.append("city", values.city);
        formData.append("password", values.password);
        formData.append("image", selectedFile);
        const response = await DataService.post("/users/create", formData);
        toast.success(response.data.message);
        setloading(false);
        dispatch(loadingToggleAction(false));
      } catch (error) {
        dispatch(loadingToggleAction(false));
        setloading(false);
        toast.error(error?.response?.data?.message ?? "Error Adding User!");
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <MainPagetitle
        mainTitle="add user"
        pageTitle="add user"
        parentTitle="Dashboard"
        parentPageUrl="/user"
      />
      <div className="container-fluid" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div
              className="profile-card card-bx m-b30 mt-3"
              style={{ border: "none" }}
            >
              <div
                className="card-header"
                style={{ border: "none", padding: "0px" }}
              >
                <h6 className="title">{t("accountSetup")}</h6>
                <div className="author-profile">
                  <div style={{ position: "relative" }} className="relative">
                    <img
                      id="image"
                      width={100}
                      height={100}
                      style={{ borderRadius: 100 }}
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : IMAGES.Tab1
                      }
                      alt=""
                    />
                    <div
                      className="upload-link"
                      title=""
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                    >
                      <input
                        onChange={handleFileChange}
                        accept="image/*"
                        type="file"
                        className="update-flie"
                      />
                      <i className="fa fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
              <form className="profile-form" onSubmit={formik.handleSubmit}>
                <div className="card-body mt-4" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("fullName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.errors.name && (
                        <div className="text-danger fs-12">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("emailAddress")}</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <div className="text-danger fs-12">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("password")}</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {formik.errors.password && (
                        <div className="text-danger fs-12">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("role")}</label>
                      <Select
                        options={options1}
                        className="custom-react-select"
                        isSearchable={false}
                        name="role"
                        id="role"
                        onChange={(selectedOption) =>
                          formik.setFieldValue("role", selectedOption.value)
                        }
                        value={options1.find(
                          (option) => option.value === formik.values.role
                        )}
                      />
                      {formik.errors.role && (
                        <div className="text-danger fs-12">
                          {formik.errors.role}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("gender")}</label>
                      <Select
                        options={options2}
                        className="custom-react-select"
                        isSearchable={false}
                        name="gender"
                        onChange={(selectedOption) =>
                          formik.setFieldValue("gender", selectedOption.value)
                        }
                        value={options2.find(
                          (option) => option.value === formik.values.gender
                        )}
                      />
                      {formik.errors.gender && (
                        <div className="text-danger fs-12">
                          {formik.errors.gender}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("dateOfBirth")}</label>
                      <input
                        className="form-control"
                        id="birth"
                        name="birth"
                        selected={formik.values.birth}
                        onChange={(e) =>
                          formik.setFieldValue("birth", e.target.value)
                        }
                        type="date"
                      />
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("phone")}</label>
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("country")}</label>
                      <select
                        name="country"
                        className="form-control"
                        onChange={formik.handleChange}
                      >
                        {CountriesJson.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("city")}</label>
                      <input
                        className="form-control"
                        name="city"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer mb-3"
                  style={{ border: "none", padding: "0px" }}
                >
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {t("add")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUser;
