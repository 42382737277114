import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import { IMAGES } from "../../constant/theme";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BlogSubscription from "./BlogSubscription";
import DataService from "../../../services/AxiosInstance";
import { useFormik } from "formik";
import noData from "../../../../src/no-data.svg";
import ReactPaginate from "react-paginate";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import ucwords from "../../../services/Ucwords";
import { useTranslation } from "react-i18next";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const Blog = () => {
  const { t } = useTranslation();
  const [blogs, setBlogs] = useState([]);
  const user = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const [blogComments, setBlogComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [singleComment, setSingleComment] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = blogs.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blogs.length / 10);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const handleCommentModalClose = () => setShowCommentModal(false);
  const handleCommentModalShow = async (id) => {
    try {
      const response = await DataService.get(`/blogs/comment/${id}`);
      console.log("data check", response.data.data);
      setSingleComment(response.data.data);
      setShowCommentModal(true); // Open the modal after fetching data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      image: "", // Initial value for the image field
    },
    // You can define onSubmit if needed
    onSubmit: (values) => {
      // Your form submission logic here
    },
  });

  const fetchData = async () => {
    try {
      dispatch(loadingToggleAction(true));

      const response = await DataService.get("/blogs");
      setBlogs(response.data.data);
      formik.setFieldValue("image", response?.data?.data?.image ?? "");
      dispatch(loadingToggleAction(false));
    } catch (error) {
      dispatch(loadingToggleAction(false));
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = searchQuery
      ? blogs.filter((blog) => {
          return (
            blog.title &&
            blog.title.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      : blogs;

    setFilteredBlogs(filtered);
  }, [searchQuery, blogs]);

  function formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  function truncateString(str, num) {
    const words = str.split(" ");
    if (words.length <= num) {
      return str;
    }
    return words.slice(0, num).join(" ") + "...";
  }

  const fetchAllComments = async () => {
    try {
      const response = await DataService.get("/blogs/comment");
      setBlogComments(response.data.data);
    } catch (error) {
      console.error("Error fetching blog comments:", error);
    }
  };

  useEffect(() => {
    fetchAllComments();
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % blogs.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <MainPagetitle mainTitle="blog section" pageTitle="blog section" parentTitle="Dashboard" parentPageUrl="/blog-section"  />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-9 col-lg-7">
            <div className="row">
              <div className="col-xl-12">
                <div className="card" style={{ border: "none" }}>
                  <div className="card-header" style={{ border: "none" }}>
                    <div className="row w-100">
                      <div className="col-6">
                        <h4 className="heading mb-0 blog-title">{t("blogs")}</h4>
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control"
                          name="search"
                          placeholder={`${t("search blog")}`}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {filteredBlogs.length > 0 ? (
                        filteredBlogs.slice(0, 10).map((item, ind) => (
                          <Link
                            to={`/blog-detail/${item._id}`}
                            key={ind}
                            className="col-xl-6"
                          >
                            <div className="" key={ind}>
                              <div className="seconday-post">
                                <img
                                  id="image"
                                  src={
                                    item.image
                                      ? imageUrl + item.image
                                      : IMAGES.Tab1
                                  }
                                  style={{ width: "160px" }}
                                  alt=""
                                />
                                <div className="post-1">
                                  <div className="post-data">
                                    {item.categories.map((cate, key) => (
                                      <React.Fragment key={key}>
                                        <span
                                          className={`badge border-0 badge-sm `}
                                          style={{
                                            backgroundColor: cate.color,
                                          }}
                                        >
                                          {ucwords(cate.name)}
                                        </span>
                                        {key !== item.categories.length - 1 && (
                                          <>&nbsp;</>
                                        )}{" "}
                                      </React.Fragment>
                                    ))}
                                    <h4>{ucwords(item.title)}</h4>
                                    <div>
                                      <img
                                        src={
                                          user?.profileImage
                                            ? imageUrl + user?.profileImage
                                            : IMAGES.Tab1
                                        }
                                        className="avatar rounded-circle me-2 small-post"
                                        alt=""
                                      />
                                      <span>
                                        <b className="text-primary">
                                          {ucwords(user?.displayName)}
                                        </b>{" "}
                                        in {formatDate(item.createdAt)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))
                      ) : (
                        <div className="row text-center ">
                          <img
                            src={noData}
                            className="mx-auto"
                            id="image"
                            style={{ width: "14%" }}
                          />
                          <h4 className="mt-2" style={{ color: "#949494" }}>
                            {t("blogsNotFound")}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0">
                    <h4 className="heading mb-0 blog-title">{t("readMore")}</h4>
                  </div>
                  <div className="card-body">
                    {currentItems &&
                      currentItems.length > 0 &&
                      currentItems.map((item, ind) => (
                        <Link to={`/blog-detail/${item._id}`} key={ind}>
                          <div className="third-post" key={ind}>
                            <img
                              className=""
                              id="image"
                              src={
                                item.image ? imageUrl + item.image : IMAGES.Tab1
                              }
                              alt={`Image ${ind}`}
                              style={{ width: "270px" }} // Add the style here
                            />
                            <div className="post-1">
                              <div className="post-data">
                                {item.categories.map((cate, key) => (
                                  <React.Fragment key={key}>
                                    <span
                                      className={`badge border-0 badge-sm `}
                                      style={{ backgroundColor: cate.color }}
                                    >
                                      {ucwords(cate.name)}
                                    </span>
                                    {key !== item.categories.length - 1 && (
                                      <>&nbsp;</>
                                    )}{" "}
                                  </React.Fragment>
                                ))}
                                <h4>{ucwords(item.title)}</h4>
                                <div className="mb-2">
                                  <img
                                    src={
                                      user?.profileImage
                                        ? imageUrl + user?.profileImage
                                        : IMAGES.Tab1
                                    }
                                    className="avatar rounded-circle me-2 small-post"
                                    alt=""
                                  />{" "}
                                  <span>
                                    <b className="text-primary">
                                      {ucwords(user?.displayName)}
                                    </b>{" "}
                                    in {formatDate(item.createdAt)}
                                  </span>
                                </div>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: truncateString(
                                      item.description,
                                      30
                                    ),
                                  }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                  <div
                    className="d-flex w-100 my-3"
                    style={{ fontSize: "large" }}
                  >
                    <ReactPaginate
                      className="w-100 d-flex justify-content-end gap-3"
                      breakLabel="..."
                      nextLabel=" >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< "
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-5">
            <div className="card-header border-0 pt-0 px-0">
              <h4 className="heading mb-0 blog-title">{t("popularPost")}</h4>
            </div>
            <div className="recent-post">
              {blogs.slice(0, 3).map((data, ind) => (
                <Link to={`/blog-detail/${data._id}`} key={ind}>
                  <div className="third-post style-1" key={ind}>
                    <img
                      src={data.image ? imageUrl + data.image : IMAGES.Tab1}
                      alt=""
                    />
                    <div className="post-1">
                      <div className="post-data">
                        <h4>{ucwords(data.title)}</h4>
                        <div className="mb-2">
                          <img
                            src={
                              user?.profileImage
                                ? imageUrl + user?.profileImage
                                : IMAGES.Tab1
                            }
                            className="avatar rounded-circle me-2 small-post"
                            alt=""
                          />
                          <span> {formatDate(data.createdAt)} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="card-header border-0 pt-0 px-0">
              <h4 className="heading mb-0 blog-title">{t("socialPlugin")}</h4>
            </div>
            <div className="mb-4">
              <ul className="c-social">
                <li>
                  <a
                    href="https://www.facebook.com/dexignzone"
                    rel="noreferrer"
                    target="_blank"
                    className="bg-facebook"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.whatsapp.com/"
                    rel="noreferrer"
                    target="_blank"
                    className="bg-whatsapp"
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/dexignzone"
                    rel="noreferrer"
                    target="_blank"
                    className="bg-linkedin"
                  >
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="skype:rahulxarma?chat" className="bg-skype">
                    <i className="fa-brands fa-skype"></i>
                  </a>
                </li>
              </ul>
            </div>
            <BlogSubscription />
            <div className="recent-post">
              <div className="card-header border-0 pt-0 px-0">
                <h4 className="heading mb-0 blog-title">{t("comments")}</h4>
              </div>
              {blogComments.slice(0, 3).map((item, ind) => (
                <div
                  className="third-post style-2"
                  key={ind}
                  onClick={() => {
                    handleCommentModalShow(item._id);
                  }}
                >
                  <img
                    src={
                      user?.profileImage
                        ? imageUrl + user?.profileImage
                        : IMAGES.Tab1
                    }
                    className="avatar  rounded-circle me-2 av-post"
                    alt=""
                  />
                  <div className="post-1">
                    <div className="post-data">
                      <h4 className="mb-0">
                        {ucwords(user?.name) || "No User"}
                      </h4>
                      <div>
                        <span>{`${item.comment
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")}...`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row mb-3">
                <Link
                  to="/comment-list"
                  className="btn btn-primary btn-sm  d-block mt-3"
                >
                  {t("viewAllComments")}
                </Link>
              </div>
            </div>

            <Modal show={showCommentModal} onHide={handleCommentModalClose}>
              <Modal.Header closeButton style={{ backgroundColor: "white" }}>
                <Modal.Title>Comment Detail</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "white" }}>
                <div className="row">
                  <img
                    src={
                      user?.profileImage
                        ? imageUrl + user?.profileImage
                        : IMAGES.Tab1
                    }
                  />
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <h4>Name</h4>
                  </div>
                  <div className="col-8">
                    <h5>{ucwords(user?.displayName) || "No User"}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h4>Blog</h4>
                  </div>
                  <div className="col-8">
                    <h5>{ucwords(singleComment.blogId?.title) || "No Blog"}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h4>Comment</h4>
                  </div>
                  <div className="col-8">
                    <p>{singleComment.comment}</p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: "white" }}>
                <Button
                  className="btn btn-sm"
                  variant="secondary"
                  onClick={handleCommentModalClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
