import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import DataService from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import BlogCommentTitle from "../../layouts/BlogCommentTitle";
import ucwords from "../../../services/Ucwords";
import { useTranslation } from "react-i18next";
import MainPagetitle from "../../layouts/MainPagetitle";

const BlogCommentList = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [data, setData] = useState(
    document.querySelectorAll("#blog_wrapper tbody tr")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [comments, setComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredComment, setFilteredComments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = filteredComment.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredComment.length / 10);
  const user = useSelector((state) => state.auth.auth);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % filteredComment.length;
    setItemOffset(newOffset);
  };

  const navigate = useNavigate();

  const [blogCategories, setBlogCategories] = useState([]);

  const getBlogCategory = async () => {
    try {
      const response = await DataService.get(
        "/blog-categories/all-blog-categories"
      );
      setBlogCategories(response.data.data);
      console.log("----------", response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };
  useEffect(() => {
    getBlogCategory();
  }, []);

  const options = [];
  blogCategories.forEach((category, index) => {
    options.push({ value: index.id, label: category.name });
  });

  const fetchData = async () => {
    try {
      const response = await DataService.get("/blogs/comment");
      console.log("--check comment--", response.data.data);
      setComments(response.data.data);
    } catch (error) {
      console.error("Error fetching blog Comment:", error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = comments.filter((comment) => {
      // Check if the blog title or slug matches the search query
      const titleMatches = comment.comment
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return titleMatches;
    });
    setFilteredComments(filtered);
  }, [searchQuery, comments]);

  const clearFilter = () => {
    setSearchQuery("");
  };

  const deleteCommentCategory = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await DataService.delete(
            `/blogs/delete/comment/${id}`
          );
          if (deleted) {
            toast.success(deleted.data.message);
            fetchData();
          } else {
            toast.error(
              deleted.data.message ?? "Failed to delete the blog comment."
            );
          }
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Failed to delete the blog."
      );
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <>
      {/* <PageTitle activeMenu="Blog Comment List" motherMenu="CMS" /> */}
      <MainPagetitle mainTitle="blog comment list" pageTitle="blog comment list" parentTitle="Dashboard"  />
      <div
        className="container-fluid"
        style={{ paddingTop: "0.875rem" }}
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <div
                className="cm-content-body form excerpt"
                style={{ border: "none" }}
              >
                <div className="row mt-3">
                  <div className="col-xl-9 col-sm-6">
                    <input
                      type="text"
                      className="form-control mb-3 mb-xl-0"
                      id="exampleFormControlInput1"
                      placeholder={`${t("search")}`}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <button
                      className="btn btn-danger light"
                      title="Click here to remove filter"
                      type="button"
                      onClick={clearFilter}
                    >
                      {t("removeFilter")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter cm-content-box box-primary">
              <div className={`content-title`} style={{ padding: "0px" }}>
                <div className="cpa">
                  <i className="fa-solid fa-file-lines me-1" />
                  {t("blogCommentList")}
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`SlideToolHeader ${
                      open2 ? "collapse" : "expand"
                    }`}
                    onClick={() => setOpen2(!open2)}
                  >
                    <i className="fas fa-angle-up"></i>
                  </Link>
                </div>
              </div>
              <Collapse in={open2}>
              <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                      <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                        <table
                          id="projects-tbl"
                          className="table ItemsCheckboxSec dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th className="text-black">{t("id")}</th>
                              <th className="text-black">{t("user")}</th>
                              <th className="text-black">{t("blogs")}</th>
                              <th className="text-black">{t("comments")}</th>
                              {["admin", "super-admin"].includes(
                                user.role.name
                              ) && (
                                <th className="text-black text-end">{t("action")}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.length > 0 ? (
                              currentItems.map((comment, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {comment.userId
                                      ? ucwords(comment.userId.name)
                                      : "No User"}
                                  </td>
                                  <td className="">
                                    {comment.blogId
                                      ? comment.blogId.title
                                      : "No Blog"}
                                  </td>
                                  <td>{comment.comment}</td>
                                  {["admin", "super-admin"].includes(
                                    user.role.name
                                  ) && (
                                    <td className="text-end">
                                      <Link
                                        type="button"
                                        onClick={() =>
                                          deleteCommentCategory(comment._id)
                                        }
                                        className="btn btn-danger btn-sm content-icon ms-1"
                                      >
                                        <i className="fa-solid fa-trash" />
                                      </Link>
                                    </td>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "center",
                                    color: "#949494",
                                    fontSize: "15px",
                                  }}
                                >
                                  {t("noCommentsFound")}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="d-flex w-100 my-3">
                          <ReactPaginate
                            className="w-100 d-flex justify-content-end gap-3"
                            breakLabel="..."
                            nextLabel=" >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< "
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="cm-content-body form excerpt"
                  style={{ border: "none" }}
                >
                  <div className="table-responsive">
                    <div
                      id="blog_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table className="table table-responsive-lg table-striped table-condensed flip-content">
                        <thead>
                          <tr>
                            <th className="text-black">S.No</th>
                            <th className="text-black">{t("user")}</th>
                            <th className="text-black">{t("blogs")}</th>
                            <th className="text-black">{t("comments")}</th>
                            {["admin", "super-admin"].includes(
                              user.role.name
                            ) && (
                              <th className="text-black text-end">{t("action")}</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.length > 0 ? (
                            currentItems.map((comment, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {comment.userId
                                    ? ucwords(comment.userId.name)
                                    : "No User"}
                                </td>
                                <td className="">
                                  {comment.blogId
                                    ? comment.blogId.title
                                    : "No Blog"}
                                </td>
                                <td>{comment.comment}</td>
                                {["admin", "super-admin"].includes(
                                  user.role.name
                                ) && (
                                  <td className="text-end">
                                    <Link
                                      type="button"
                                      onClick={() =>
                                        deleteCommentCategory(comment._id)
                                      }
                                      className="btn btn-danger btn-sm content-icon ms-1"
                                    >
                                      <i className="fa-solid fa-trash" />
                                    </Link>
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: "#949494",
                                  fontSize: "15px",
                                }}
                              >
                                {t("noCommentsFound")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex w-100 my-3">
                        <ReactPaginate
                          className="w-100 d-flex justify-content-end gap-3"
                          breakLabel="..."
                          nextLabel=" >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel="< "
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogCommentList;
