import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import DataService from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import MainPagetitle from "../../layouts/MainPagetitle";

const Blog = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [blogCategories, setBlogCategories] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = filteredBlogs.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredBlogs.length / 10);

  const getBlogCategory = async () => {
    try {
      const response = await DataService.get(
        "/blog-categories/all-blog-categories"
      );
      setBlogCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };
  useEffect(() => {
    getBlogCategory();
  }, []);

  const options = [];
  blogCategories.forEach((category, index) => {
    options.push({ value: index.id, label: category.name });
  });

  const fetchData = async () => {
    try {
      const response = await DataService.get("/blogs");
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = blogs.filter((blog) => {
      // Check if the blog title or slug matches the search query
      const titleMatches = blog.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const slugMatches = blog.slug
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      // Check if the blog belongs to the selected category
      const categoryMatches =
        selectedCategory === "" ||
        blog.categories.some((cat) => cat._id === selectedCategory);

      const createdAtMatches =
        !selectedDate ||
        new Date(blog.createdAt).toISOString().slice(0, 10) ===
          new Date(selectedDate).toISOString().slice(0, 10);
      return (
        (titleMatches || slugMatches) && categoryMatches && createdAtMatches
      );
    });
    setFilteredBlogs(filtered);
  }, [searchQuery, selectedCategory, selectedDate, blogs]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % filteredBlogs.length;
    setItemOffset(newOffset);
  };

  const clearFilter = () => {
    setSearchQuery("");
    setSelectedCategory("");
    setSelectedDate("");
  };

  const editBlogs = async (id) => {
    try {
      const response = await DataService.get(`/blogs/${id}`);
      navigate(`/blog/${id}`);
    } catch (error) {
      console.error("Error fetching blog category:", error);
    }
  };

  const deleteBlogCategory = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await DataService.delete(`/blogs/delete/${id}`);
          if (deleted) {
            toast.success(deleted.data.message);
            fetchData();
          } else {
            toast.error(deleted.data.message ?? "Failed to delete the blog.");
          }
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Failed to delete the blog."
      );
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <>
      <MainPagetitle mainTitle="Blogs" pageTitle="Blogs" parentTitle="Dashboard"  />
      <div
        className="container-fluid"
        style={{ paddingTop: "0.875rem" }}
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <div className={`content-title`} style={{ padding: "0px" }}>
                <div className="cpa">
                  <i className="fas fa-filter me-2"></i>{t("filter")}
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`SlideToolHeader ${
                      open ? "collapse" : "expand"
                    }`}
                    onClick={() => setOpen(!open)}
                  >
                    <i className="fas fa-angle-up"></i>
                  </Link>
                </div>
              </div>
              <Collapse in={open}>
                <div
                  className="cm-content-body form excerpt"
                  style={{ border: "none" }}
                >
                  <div className="row mt-3">
                    <div className="col-xl-3 col-sm-6">
                      <input
                        type="text"
                        className="form-control mb-3 mb-xl-0"
                        id="exampleFormControlInput1"
                        placeholder={`${t("search")}`}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-3 col-sm-6 mb-3 mb-xl-0">
                      <select
                        className="custom-react-select form-control"
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        value={selectedCategory}
                      >
                        <option value={""} selected>
                          {t("selectCategory")}
                        </option>
                        {blogCategories.map((data, index) => (
                          <option key={index} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                      <input
                        type="date"
                        name="datepicker"
                        className=" form-control mb-xl-0 mb-3"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="col-xl-3 col-sm-6">
                      <button
                        className="btn btn-danger light"
                        title="Click here to remove filter"
                        type="button"
                        onClick={clearFilter}
                      >
                        {t("removeFilter")}
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="mb-3">
              <ul className="d-flex align-items-center flex-wrap">
                <li>
                  <Link to={"/add-blog"} className="btn btn-primary ">
                    {t("addBlog")}
                  </Link>
                </li>
                <li>
                  <Link to={"/blog-category"} className="btn btn-primary mx-1">
                    {t("addBlogCategory")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="filter cm-content-box box-primary">
              <div className={`content-title`} style={{ padding: "0px" }}>
                <div className="cpa">
                  <i className="fa-solid fa-file-lines me-1" />
                  {t("blogList")}
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`SlideToolHeader ${
                      open2 ? "collapse" : "expand"
                    }`}
                    onClick={() => setOpen2(!open2)}
                  >
                    <i className="fas fa-angle-up"></i>
                  </Link>
                </div>
              </div>
              <Collapse in={open2}>
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                      <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                        <table
                          id="projects-tbl"
                          className="table ItemsCheckboxSec dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>
                              <th className="text-black">{t("id")}</th>
                              <th className="text-black">{t("title")}</th>
                              <th className="text-black">{t("slug")}</th>
                              <th className="text-black">{t("categories")}</th>
                              <th className="text-black">{t("createdDate")}</th>
                              <th className="text-black text-end">{t("action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.length > 0 ? (
                              currentItems.map((blog, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{blog.title}</td>
                                  <td>{blog.slug}</td>
                                  <td>
                                    {blog.categories.map((cat, index) => (
                                      <React.Fragment key={index}>
                                        <span>{cat.name}</span>
                                        {index < blog.categories.length - 1 &&
                                          ", "}
                                      </React.Fragment>
                                    ))}
                                  </td>
                                  <td>
                                    {new Date(blog.createdAt).toLocaleDateString(
                                      "en-US",
                                      {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </td>
                                  <td className="text-end">
                                    <Link
                                      type="button"
                                      onClick={() => editBlogs(blog._id)}
                                      className="btn btn-warning btn-sm content-icon me-1"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    <Link
                                      type="button"
                                      onClick={() => deleteBlogCategory(blog._id)}
                                      className="btn btn-danger btn-sm content-icon ms-1"
                                    >
                                      <i className="fa-solid fa-trash" />
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "center",
                                    color: "#949494",
                                    fontSize: "15px",
                                  }}
                                >
                                  {t("noDataFound")}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="d-flex w-100 my-3">
                          <ReactPaginate
                            className="w-100 d-flex justify-content-end gap-3"
                            breakLabel="..."
                            nextLabel=" >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< "
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="cm-content-body form excerpt"
                  style={{ border: "none" }}
                >
                  <div className="table-responsive">
                    <div
                      id="blog_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table className="table table-responsive-lg table-striped table-condensed flip-content">
                        <thead>
                          <tr>
                            <th className="text-black">S.No</th>
                            <th className="text-black">{t("title")}</th>
                            <th className="text-black">{t("slug")}</th>
                            <th className="text-black">{t("categories")}</th>
                            <th className="text-black">{t("createdDate")}</th>
                            <th className="text-black text-end">{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.length > 0 ? (
                            currentItems.map((blog, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{blog.title}</td>
                                <td>{blog.slug}</td>
                                <td>
                                  {blog.categories.map((cat, index) => (
                                    <React.Fragment key={index}>
                                      <span>{cat.name}</span>
                                      {index < blog.categories.length - 1 &&
                                        ", "}
                                    </React.Fragment>
                                  ))}
                                </td>
                                <td>
                                  {new Date(blog.createdAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      month: "2-digit",
                                      day: "2-digit",
                                      year: "numeric",
                                    }
                                  )}
                                </td>
                                <td className="text-end">
                                  <Link
                                    type="button"
                                    onClick={() => editBlogs(blog._id)}
                                    className="btn btn-warning btn-sm content-icon me-1"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                  <Link
                                    type="button"
                                    onClick={() => deleteBlogCategory(blog._id)}
                                    className="btn btn-danger btn-sm content-icon ms-1"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: "#949494",
                                  fontSize: "15px",
                                }}
                              >
                                {t("noDataFound")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex w-100 my-3">
                        <ReactPaginate
                          className="w-100 d-flex justify-content-end gap-3"
                          breakLabel="..."
                          nextLabel=" >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel="< "
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Blog;
