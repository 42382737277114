import React, { useEffect, useRef, useState } from "react";
import Nestable from "react-nestable";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataService from "../../../../services/AxiosInstance";
import noData from "../../../../no-data.svg";
import Swal from "sweetalert2";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import EditFileModal from "./EditFileModal";
import FileShareModal from "./FileShareModal";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { SVGICON } from "../../../constant/theme";
import folderImage from "../../../../images/folder_image.jpg";
import axios from "axios";
import { HomeIcon } from "@heroicons/react/24/solid";
import FoldersList from "./FoldersList";
import { useTranslation } from "react-i18next";

const videoFileTypes = ["mp4", "avi", "mov"];
const imageFileTypes = ["png", "jpg", "jpeg", "gif"];

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const FilesList = ({
  selectedFolder,
  changeFolder,
  rootFolderID,
  singleFolderData,
  reloadState,
  setReloadState,
}) => {
  const [tree, settree] = useState({
    example: 1,
    defaultCollapsed: false,
  });
  const refNestable = useRef(null);

  const { id } = useParams();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedUpdateFile, setSelectedUpdateFile] = useState(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showUpdateFileModal, setShowUpdateFileModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredSubFolder, setIsHoveredSubFolder] = useState(false);
  const [folderIsHovered, setFolderIsHovered] = useState(false);
  const [shareIconHovered, setShareIconHovered] = useState(false);
  const [dropDownHovered, setDropDownHovered] = useState(false);
  const [newShareModalShow, setHandleShareModal] = useState(false);
  const showLoading = useSelector((state) => state.auth.showLoading);
  const [showFileDetail, setShowFileDetail] = useState(false);
  const [showImageDetail, setShowImageDetail] = useState(false);
  const [folderData, setFolderData] = useState([]);
  const [updateFiles, setUpdateFiles] = useState(false);
  const [dislayNone, setDisplayNone] = useState(true);
  const [hovered, setHovered] = useState(null);
  const [singleSubFolder, setSingleSubFolder] = useState([]);
  const [selectedSubFolderIndex, setSelectedSubFolderIndex] = useState([]);
  const [newFolders, setNewFolders] = useState();
  const [updateFolderID, setUpdateFolderID] = useState();
  const [selectedSubFolderId, setSelectedSubFolderId] = useState();
  const [newShareModalShowSubFolder, setHandleShareModalSubFolder] =
    useState(false);

  const [newHandleUpdateSubFolder, setNewHandleUpdateSubFolder] =
    useState(false);

  const [files, setFiles] = useState([]);
  const [updateFolders, setUpdateFolders] = useState([]);
  const editRef = useRef([]);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const handleEditModalClose = () => setEditFolderModal(false);
  const handleEditModalShow = (e) => {
    e.preventDefault(); // Prevent default right-click menu
    setEditFolderModal(true);
  };
  const handleUpdateFileModalClose = () => setShowUpdateFileModal(false);
  useEffect(() => {
    if (reloadState) {
      getSingleFolder();
      setReloadState(false);
    }
  }, [reloadState]);
  const ucwords = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (letter) => {
      return letter.toUpperCase();
    });
  };

  const handleViewModalShow = async (id) => {
    setShowFileDetail(true);
    const response = await DataService.post("/media/file/single/" + id, {
      folder: selectedFolder,
    });
    setShowImageDetail(response.data.singleFile);

    // const fileName = response.data.data.name;
    // const capitalizedFileName = ucwords(fileName);
    // const nameElement = document.getElementById("fileName");
    // nameElement.innerText = capitalizedFileName;

    // const fileUrl = response.data.singleFile.url;
    // const imgElement = document.getElementById("fileImage");
    // imgElement.src = imageUrl + fileUrl;
    // const fileType = response.data.singleFile.type;
    // const capitalizedFileType = ucwords(fileType);

    // const typeElement = document.getElementById("fileType");
    // typeElement.innerText = capitalizedFileType;
  };
  const handleViewModalClose = () => {
    setShowFileDetail(false);
    setShowImageDetail(null);
    setShowFileDetail(false);
  };
  const handleFileModalClose = () => setShowFileModal(false);
  // const handleFileModalShow = () => {
  //   if (selectedFolder) {
  //     setShowFileModal(true);
  //   } else {
  //     toast.info("Please select any folder!");
  //   }
  // };
  // const handleUpdateFileModalShow = async (id) => {
  //   console.log("check edit modal id", id);
  //   const response = await DataService.post("/media/file/single/" + id, {
  //     folder: selectedFolder,
  //   });
  //   console.log(response, "Response of file");

  //   // folders
  //   setSelectedUpdateFile(response.data.singleFile);
  //   setShowUpdateFileModal(true);
  // };

  const handleUpdateFileModalShow = async (id) => {
    try {
      console.log("check edit modal id", id);
      const response = await DataService.post("/media/file/single/" + id, {
        folder: selectedFolder,
      });
      console.log(response, "Response of file");

      // folders
      setSelectedUpdateFile(response.data.singleFile);
      setShowUpdateFileModal(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error: Resource not found
        console.error("Resource not found:", error.response.data);
        // Display an error message or take appropriate action
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  // const handleShareModal = async (id) => {
  //   console.log("get this id", id);
  //   const response = await DataService.post("/media/file/single/" + id, {
  //     folder: selectedFolder,
  //   });
  //   console.log(response, "Response of file");
  //   setSelectedUpdateFile(response.data.singleFile);
  //   setHandleShareModal(true);
  // };

  const handleShareModal = async (id) => {
    try {
      const response = await DataService.post("/media/file/single/" + id, {
        folder: selectedFolder,
      });
      console.log(response, "Response of file");
      setSelectedUpdateFile(response.data.singleFile);
      setHandleShareModal(true);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Config error:", error.config);
    }
  };

  const handleShareModalSubFolder = async (id) => {
    try {
      console.log("call api", id);
      if (id) {
        const res = await DataService.get("/media/folders/" + id);
        setNewFolders(res.data.data);
        setHandleShareModalSubFolder(true);
        console.log(res.data);
        console.log("selected Folder", res.data);
        formikSubFolder.setFieldValue("name", res.data.data.name);
        formikSubFolder.setFieldValue("type", res.data.data.type);
        formikSubFolder.setFieldValue("sharedWith", res.data.data.sharedWith);
        formikSubFolder.setFieldValue("parent", res.data.data.parent);
        return res.data;
      } else {
        setHandleShareModalSubFolder(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const formikSubFolder = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      console.log("Edit Modal Submit");

      if (!values.sharedWith.includes(user.email))
        values.sharedWith =
          user.email.toLowerCase() + "," + values.sharedWith.toLowerCase();
      try {
        const res = await DataService.put(
          "/media/folders/" + selectedFolder,
          values
        );
        toast.success(res?.data?.message ?? "Folder Updated Successfully");
        handleShareModal(false);
        handleShareModalSubFolder();
        resetForm();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error creating folder");
        dispatch(loadingToggleAction(false));
      }
    },
  });

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const getSingleFolder = async (id) => {
    try {
      const res = await DataService.get(`/media/folders/${selectedFolder}`);

      res?.data?.data?.files?.map((file) => {});
      let filesArray = res?.data?.data?.files?.filter((file) => {
        if (file?.type === "private") {
          return file?.sharedWith?.includes(user?.email ?? "") ?? false;
        } else {
          return true;
        }
      });
      setFiles(filesArray);
      console.log("select folder", res.data);
      setUpdateFiles(res.data.files);

      const parentId = res.data.data.parent;
      console.log("select data", parentId);
      const parentData = await DataService.get(
        `/media/folders/parent/${selectedFolder}`
      );

      const updatedFolderData = await Promise.all(
        parentData.data.data.map(async (item) => {
          const image = imageUrl + item.url;
          const isImage = await checkImageExists(image);
          return {
            ...item,
            isImage,
          };
        })
      );

      setFolderData(updatedFolderData);

      console.log("check child data", parentData.data.data);
      setDisplayNone(false);
      if (parentId) {
      } else {
        console.log("No parent folder found");
      }
    } catch (error) {}
  };

  // const getSingSubFolder = async (id) => {
  //   try {
  //     const res = await DataService.get(`/media/folders/${id}`);
  //     setSingleSubFolder((prevSubFolders) => [
  //       ...prevSubFolders,
  //       res.data.data,
  //     ]);
  //     // setSingleSubFolder(res.data.data);
  //     console.log("single sub folder get", res.data.data);
  //   } catch (error) {}
  // };
  const handleSubFolderClick = async (id, index) => {
    try {
      const res = await DataService.get(`/media/folders/${id}`);

      const clickedSubFolder = res.data.data;
      const relatedSubFoldersRes = await DataService.get(
        `/media/folders/parent/${clickedSubFolder._id}`
      );
      const relatedSubFolders = relatedSubFoldersRes.data.data;
      console.log("clicked sub folder", relatedSubFolders);

      const updatedSubFolders = await Promise.all(
        relatedSubFolders.map(async (item) => {
          const image = imageUrl + item.url;
          const isImage = await checkImageExists(image);
          return {
            ...item,
            isImage,
          };
        })
      );

      setFolderData(updatedSubFolders);

      relatedSubFolders?.files?.map((file) => {});
      let filesArray = res?.data?.data?.files?.filter((file) => {
        if (file?.type === "private") {
          return file?.sharedWith?.includes(user?.email ?? "") ?? false;
        } else {
          return true;
        }
      });
      setFiles(filesArray);

      // Update singleSubFolder state to display subfolders of clicked subfolder
      setSingleSubFolder(clickedSubFolder.children || []);

      // Update folderData state to include only the clicked subfolder and its parent subfolders
      const updatedFolderData = [clickedSubFolder];
      let currentFolder = clickedSubFolder;
      while (currentFolder.parent) {
        const parentRes = await DataService.get(
          `/media/folders/${currentFolder.parent}`
        );
        const parentFolder = parentRes.data.data;
        updatedFolderData.unshift(parentFolder);
        currentFolder = parentFolder;
      }

      setSingleSubFolder(updatedFolderData);
      setSelectedSubFolderId(id);

      setSelectedSubFolderIndex(index);
      console.log("sub folder click then", res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getSingleFolder();
    // setFiles(null);
  }, [selectedFolder]);

  const getChildFolder = async (id) => {
    try {
      changeFolder(id);
      console.log("Selected folder id:", id); // Check if the id is received correctly
      const res = await DataService.get(`/media/folders/${id}`);
      // return res.data;
      console.log("select folder", res.data);

      const parentId = res.data.data.parent;
      console.log("select data", parentId);
      const parentData = await DataService.get(`/media/folders/parent/${id}`);

      // setFolderData(parentData.data.data);
      // if (parentData && parentData.length > 0) {
      //   setFolderData(parentData.data.data); // Show child folders
      // } else {
      //   console.log("No child folders found, displaying current folder");
      //   setFolderData(parentData.data.data); // Display the current folder if no child folders
      // }
      // res?.data?.data?.files?.map((file) => {});
      // let filesArray = res?.data?.data?.files?.filter((file) => {
      //   if (file?.type === "private") {
      //     return file?.sharedWith?.includes(user?.email ?? "") ?? false;
      //   } else {
      //     return true;
      //   }
      // });
      // setFiles(filesArray);
      console.log("check child data", parentData.data.data);
      if (parentId) {
      } else {
        console.log("No parent folder found");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getChildFolder();
  }, [id]);

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = (index) => {
    setIsHovered(null);
  };

  const handleMouseEnterSubFolder = (index) => {
    setIsHoveredSubFolder(index);
  };

  const handleMouseLeaveSubFolder = (index) => {
    setIsHoveredSubFolder(null);
  };
  const checkImageExists = async (image) => {
    try {
      const response = await fetch(image, {
        method: "HEAD",
        timeout: 5000,
      });
      const contentType = response.headers.get("content-type");
      return contentType && contentType.startsWith("image/");
    } catch (error) {
      console.error("Error while fetching image:", error);
      return false;
    }
  };

  const subFolderHandleMouseEnter = (index) => {
    setFolderIsHovered(index);
    setFolderIsHovered(true);
  };

  const subFolderHandleMouseLeave = (index) => {
    setFolderIsHovered(null);
    setFolderIsHovered(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      if (selectedFile == null) {
        toast.error("Please select any file to upload!");
        return;
      }
      if (selectedFolder == null) {
        toast.error("Please select destination folder!");
        return;
      }
      formData.append("folder", selectedFolder);
      formData.append("type", values.type);
      formData.append("sharedWith", user.email + "," + values.sharedWith);
      formData.append("name", values.name);
      formData.append("file", selectedFile);
      try {
        dispatch(loadingToggleAction(true));
        await DataService.post("/media/files/upload", formData);
        dispatch(loadingToggleAction(false));
        toast.success("File Uploaded Successfully");
        getSingleFolder();
        // getData();
        handleFileModalClose(false);
      } catch (error) {
        dispatch(loadingToggleAction(false));
        toast.error(error?.response?.data?.message ?? "File Upload Failed!");
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // const getData = async () => {
  //   try {
  //     if (selectedFolder) {
  //       const response = await DataService.get(
  //         `/media/files/${selectedFolder}`
  //       );
  //       response?.data?.data?.files?.map((file) => {});
  //       let filesArray = response?.data?.data?.files?.filter((file) => {
  //         if (file?.type === "private") {
  //           return file?.sharedWith?.includes(user?.email ?? "") ?? false;
  //         } else {
  //           return true;
  //         }
  //       });
  //       setFiles(filesArray);
  //     }
  //   } catch (error) {}
  // };

  const handleFileClick = (file) => {
    console.log(file, "File Clicked");
    console.log(file?.fileType, "File Type");
    switch (file?.fileType) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        handleViewModalShow(file._id);
        break;
      case "mp4":
      case "avi":
      case "mov":
        handleViewModalShow(file._id);
        console.log("Video file clicked");
        break;
      case "pdf":
        handleViewModalShow(file._id);
        console.log("PDF file clicked");
        break;
      default:
        console.log("Unknown file type or no file type provided");
        break;
    }
  };

  const deleteFolder = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}` // Add translated cancel button text
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete("/media/folders/" + id);
          if (response.data.success) {
            toast.success("Folder Deleted Successfully!");
            // getData();
            getSingleFolder();
            window.location.reload();
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error creating folder");
    }
  };

  const rowStyle = {
    display: "flex",
    alignItems: "center",
  };

  const h3Style = {
    margin: "0 10px",
    padding: "10px",
    transition: "background-color 0.3s, border-radius 0.3s",
  };

  const h3HoverStyle = {
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
  };

  const selectedStyle = {
    backgroundColor: "#4a88dd",
    color: "white",
    borderRadius: "10px",
  };

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const deleteFile = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}` // Add translated cancel button text
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.post("/media/file/delete/" + id, {
            folder: selectedFolder,
          });
          if (response.data.success) {
            toast.success(response.data.message);
            // getData();
            getSingleFolder();
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error creating file");
    }
  };
  const clickToWindowLocation = () => {
    window.location.reload();
  };

  const updateSubFolderFormik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      console.log("Edit Modal Submit");
      const formData = new FormData();
      if (selectedFile) formData.append("file", selectedFile);
      formData.append("type", values.type);
      formData.append("sharedWith", values.sharedWith);
      if (values?.name !== "undefined")
        formData.append("name", values?.name ?? updateFolders?.name);
      try {
        if (updateFolderID) {
          console.log("Error !", updateFolderID);
          const res = await DataService.put(
            "/media/folders/" + updateFolderID,
            formData
          );
          toast.success(res?.data?.message ?? "Folder Updated Successfully");
          setNewHandleUpdateSubFolder(false);
          handleUpdateSubFolder();
          getSingleFolder();
          resetForm();
        }
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error creating folder");
        dispatch(loadingToggleAction(false));
      }
    },
  });

  useEffect(() => {
    handleUpdateSubFolder();
  }, [editFolderModal]);

  const updateHandleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  console.log(selectedFile, "selected file");
  const updateHandleDragOver = (e) => {
    e.preventDefault();
  };

  const updateHandleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };
  const handleUpdateSubFolder = async (id) => {
    try {
      if (id) {
        setUpdateFolderID(id);
        const res = await DataService.get("/media/folders/" + id);
        setUpdateFolders(res.data.data);
        setNewHandleUpdateSubFolder(true);

        console.log(res.data);
        console.log("selected Folder", res.data);
        formik.setFieldValue("name", res.data.data.name);
        formik.setFieldValue("type", res.data.data.type);
        formik.setFieldValue("sharedWith", res.data.data.sharedWith);
        formik.setFieldValue("parent", res.data.data.parent);
        return res.data;
      } else {
        setNewHandleUpdateSubFolder(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {/* <div className="w-100 d-flex justify-content-between align-items-center">
        <h3>Images</h3>
        {["admin", "super-admin"].includes(user.role.name) && (
          <button className="btn btn-link btn-sm" onClick={handleFileModalShow}>
            {"+ "} Upload Files
          </button>
        )}
      </div> */}
      {/* <Nestable
        className="p-0"
        items={files}
        collapsed={defaultCollapsed}
        renderItem={renderItem}
        ref={refNestable}
        maxDepth={30}
      /> */}
      <div className="row" style={rowStyle}>
        {singleSubFolder.length === 0 && (
          <>
            {singleFolderData && (
              <>
                <span
                  className="w-auto"
                  onClick={() => clickToWindowLocation()}
                >
                  <HomeIcon className="" style={{ width: "20px" }} />
                </span>
                <span
                  className={`w-auto ${hovered === "parent" ? "hovered" : ""}`}
                  style={
                    hovered === "parent"
                      ? { ...h3Style, ...h3HoverStyle, ...selectedStyle }
                      : { ...h3Style, ...selectedStyle }
                  }
                  onMouseEnter={() => setHovered("parent")}
                  onMouseLeave={() => setHovered(null)}
                >
                  <b>{capitalizeFirstLetter(singleFolderData.name)}</b>
                </span>
                {singleSubFolder.length > 0 && (
                  <span className="w-auto"> &gt; </span>
                )}
              </>
            )}
          </>
        )}
        <div className="mt-2">
          {singleSubFolder.map((subFolder, index) => (
            <React.Fragment key={subFolder._id}>
              {index === 0 && ( // Only render the HomeIcon if it's the first item
                <span
                  className="w-auto"
                  onClick={() => clickToWindowLocation()}
                >
                  <HomeIcon className="" style={{ width: "20px" }} />
                </span>
              )}
              <span
                className={`w-auto ms-4 ${
                  hovered === `sub-${index}` ? "hovered" : ""
                } ${selectedSubFolderId === subFolder._id ? "selected" : ""}`}
                style={
                  selectedSubFolderId === subFolder._id
                    ? { ...h3Style, ...selectedStyle }
                    : hovered === `sub-${index}`
                    ? { ...h3Style, ...h3HoverStyle }
                    : h3Style
                }
                onMouseEnter={() => setHovered(`sub-${index}`)}
                onMouseLeave={() => setHovered(null)}
                onClick={() => handleSubFolderClick(subFolder._id)}
              >
                <b>{capitalizeFirstLetter(subFolder.name)}</b>
              </span>
              {index < singleSubFolder.length - 1 && (
                <span className="w-auto"> &gt; </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div
        className="card-content overflow-scroll rounded-lg mh-100 pt-3"
        style={{ minHeight: "300px" }}
      >
        {/* {folderData && folderData.length > 0 && ( */}
        {/* {folderData && folderData.length > 0 && (
          <div className="w-100 d-flex justify-content-between align-items-center">
            <h3>Sub Folders</h3>
          </div>
        )} */}

        <div className="w-100 d-flex justify-content-between align-items-center pt-3">
          <h3>{t("files")}</h3>
          {/* {["admin", "super-admin"].includes(user.role.name) && (
            <button
              className="btn btn-link btn-sm"
              onClick={handleFileModalShow}
            >
              {"+ "} Upload Files
            </button>
          )} */}
        </div>

        <div className="row d-flex">
          {(folderData && folderData.length > 0) ||
          (files && files.length > 0) ? (
            <div className="d-flex flex-wrap gap-5">
              {folderData?.map((item, index) => {
                const image = imageUrl + item.url;
                return (
                  <>
                    <div
                      onMouseEnter={() => handleMouseEnterSubFolder(index)}
                      onMouseLeave={() => handleMouseLeaveSubFolder(index)}
                      style={{ position: "relative" }}
                      key={`parent-${index}`}
                    >
                      <div
                        className={`${
                          isHoveredSubFolder === index ? "show" : "d-none"
                        }`}
                        key={`hover-div-${index}`}
                      >
                        <div
                          onClick={() => handleShareModalSubFolder(item._id)}
                          className={`me-2 rounded `}
                          style={{
                            position: "absolute",
                            top: 2,
                            right: 38,
                            zIndex: 3,
                            backgroundColor:
                              isHoveredSubFolder === index
                                ? shareIconHovered
                                  ? "#007ed9"
                                  : "#FFFFFF"
                                : "initial",
                            height: "34px",
                            width: "34px",
                            paddingLeft: "7px",
                          }}
                          onMouseEnter={() => setShareIconHovered(true)}
                          onMouseLeave={() => setShareIconHovered(false)}
                          key={`share-icon-${index}`}
                        >
                          <svg
                            fill={shareIconHovered ? "#ffffff" : "#000000"}
                            height="20xpx"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 268.733 268.733"
                            key={`svg-${index}`}
                          >
                            <g>
                              <path
                                d="M0,141.467v36.37c0,5.69,4.613,10.304,10.304,10.304h33.425v-39.175c0-13.63,3.309-26.493,9.135-37.864
                                c-12.09-0.718-23.009-5.835-31.187-13.77C8.495,107.539,0,123.506,0,141.467z"
                              />
                              <path
                                d="M78.616,81.218c-5.225-8.579-8.236-18.646-8.236-29.403c0-6.551,1.13-12.839,3.183-18.697
                                c-5.172-3.171-11.254-5.001-17.765-5.001c-18.8,0-34.041,15.239-34.041,34.04c0,18.8,15.241,34.041,34.041,34.041
                                c2.589,0,5.107-0.299,7.531-0.847C67.813,90.029,72.951,85.282,78.616,81.218z"
                              />
                              <path
                                d="M171.078,150.335c5.518,0,10.918,1.226,15.834,3.515l8.482-6.204c-0.432-22.684-11.904-42.655-29.279-54.77
                                c-10.175,9.679-23.919,15.639-39.037,15.639c-15.118,0-28.862-5.96-39.038-15.638c-17.712,12.35-29.312,32.86-29.312,56.091v44.552
                                c0,6.971,5.651,12.622,12.622,12.622h66.796c-2.988-5.393-4.696-11.589-4.696-18.178
                                C133.45,167.214,150.33,150.335,171.078,150.335z"
                              />
                              <circle cx="127.078" cy="51.815" r="41.698" />
                              <path
                                d="M247.104,215.36c-3.436,0-6.672,0.822-9.558,2.248l-40.529-29.645l40.234-29.431c2.957,1.518,6.301,2.391,9.852,2.391
                                c11.279,0,20.53-8.636,21.529-19.652c1.163-12.944-9.064-23.603-21.529-23.603c-11.944,0-21.628,9.683-21.628,21.628
                                c0,0.99,0.09,1.957,0.219,2.911l-40.359,29.521c-3.96-3.473-9.025-5.393-14.258-5.393c-11.944,0-21.628,9.683-21.628,21.628
                                c0,11.944,9.684,21.628,21.628,21.628c5.273,0,10.329-1.941,14.258-5.394l40.408,29.557c-0.159,1.058-0.268,2.132-0.268,3.234
                                c0,11.944,9.684,21.628,21.628,21.628c11.912,0,21.629-9.655,21.629-21.628C268.733,225.079,259.078,215.36,247.104,215.36z"
                              />
                            </g>
                          </svg>
                        </div>
                        <Dropdown
                          key={`dropdown-${index}`}
                          onMouseEnter={() => setDropDownHovered(true)}
                          onMouseLeave={() => setDropDownHovered(false)}
                          style={{
                            borderRadius: 0,
                            overflow: "visible",
                            position: "absolute",
                            top: 2,
                            right: 0,
                            zIndex: 3,
                            backgroundColor:
                              isHoveredSubFolder === index
                                ? dropDownHovered
                                  ? "#007ed9"
                                  : "#FFFFFF"
                                : "initial",
                            height: "34px",
                            width: "34px",
                            padding: "5px",
                          }}
                          className="rounded"
                        >
                          <Dropdown.Toggle
                            as="div"
                            className="btn-link i-false gap-2 d-flex justify-content-end w-100 align-items-center"
                            ref={(el) => (editRef.current[item._id] = el)}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill={dropDownHovered ? "#ffffff" : "#000000"}
                              xmlns="http://www.w3.org/2000/svg"
                              key={`dropdown-toggle-svg-${index}`}
                            >
                              <path
                                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                stroke={dropDownHovered ? "#ffffff" : "#000000"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                stroke={dropDownHovered ? "#ffffff" : "#000000"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                stroke={dropDownHovered ? "#ffffff" : "#000000"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="flex-d dropdown-menu-right border"
                            align="end"
                            key={`dropdown-menu-${index}`}
                          >
                            <Dropdown.Item
                              onClick={(e) => {
                                handleUpdateSubFolder(item._id);
                              }}
                              key={`dropdown-item-edit-${index}`}
                            >
                              {t("edit")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                deleteFolder(item._id);
                              }}
                              key={`dropdown-item-delete-${index}`}
                            >
                              {t("delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        data-src={item.url}
                        className="mb-4 position-relative"
                        key={index}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          ["admin", "super-admin"].includes(user.role.name) &&
                            editRef.current[item._id].click();
                        }}
                        onClick={() => {
                          getChildFolder(item._id);
                          // getSingSubFolder(item._id);
                          handleSubFolderClick(item._id);
                        }}
                      >
                        <div
                          className="rounded d-flex flex-column position-relative bg-lightGray rounded shadow-sm mb-2"
                          style={{ height: "10rem", width: "13rem" }}
                          // onMouseEnter={() => subFolderHandleMouseEnter(index)}
                          // onMouseLeave={() => subFolderHandleMouseLeave(index)}
                        >
                          <div
                            // onClick={() => handleViewModalShow(item._id)}
                            onClick={() => handleFileClick(item)}
                            className="w-100 h-100 position-absolute top-0 left-0 opacity-25"
                          ></div>
                          {["admin", "super-admin"].includes(
                            user.role.name
                          ) && (
                            <>
                              <div
                                style={{ top: "2px", right: "2px" }}
                                className={`d-flex justify-content-end gap-1 align-items-center position-absolute `}
                              >
                                {/* <div
                                onClick={() => handleShareModal(item._id)}
                                className="me-2 rounded"
                                style={{
                                  position: "relative",
                                  backgroundColor:
                                    folderIsHovered === index
                                      ? shareIconHovered
                                        ? "#007ed9"
                                        : "#FFFFFF"
                                      : "initial",
                                  height: "34px",
                                  width: "34px",
                                  paddingLeft: "7px",
                                }}
                                onMouseEnter={() => setShareIconHovered(true)}
                                onMouseLeave={() => setShareIconHovered(false)}
                              >
                                <svg
                                  fill={shareIconHovered ? "#ffffff" : "#000000"}
                                  height="20xpx"
                                  width="20px"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 268.733 268.733"
                                >
                                  <g>
                                    <path
                                      d="M0,141.467v36.37c0,5.69,4.613,10.304,10.304,10.304h33.425v-39.175c0-13.63,3.309-26.493,9.135-37.864
                                          c-12.09-0.718-23.009-5.835-31.187-13.77C8.495,107.539,0,123.506,0,141.467z"
                                    />
                                    <path
                                      d="M78.616,81.218c-5.225-8.579-8.236-18.646-8.236-29.403c0-6.551,1.13-12.839,3.183-18.697
                                          c-5.172-3.171-11.254-5.001-17.765-5.001c-18.8,0-34.041,15.239-34.041,34.04c0,18.8,15.241,34.041,34.041,34.041
                                          c2.589,0,5.107-0.299,7.531-0.847C67.813,90.029,72.951,85.282,78.616,81.218z"
                                    />
                                    <path
                                      d="M171.078,150.335c5.518,0,10.918,1.226,15.834,3.515l8.482-6.204c-0.432-22.684-11.904-42.655-29.279-54.77
                                          c-10.175,9.679-23.919,15.639-39.037,15.639c-15.118,0-28.862-5.96-39.038-15.638c-17.712,12.35-29.312,32.86-29.312,56.091v44.552
                                          c0,6.971,5.651,12.622,12.622,12.622h66.796c-2.988-5.393-4.696-11.589-4.696-18.178
                                          C133.45,167.214,150.33,150.335,171.078,150.335z"
                                    />
                                    <circle cx="127.078" cy="51.815" r="41.698" />
                                    <path
                                      d="M247.104,215.36c-3.436,0-6.672,0.822-9.558,2.248l-40.529-29.645l40.234-29.431c2.957,1.518,6.301,2.391,9.852,2.391
                                          c11.279,0,20.53-8.636,21.529-19.652c1.163-12.944-9.064-23.603-21.529-23.603c-11.944,0-21.628,9.683-21.628,21.628
                                          c0,0.99,0.09,1.957,0.219,2.911l-40.359,29.521c-3.96-3.473-9.025-5.393-14.258-5.393c-11.944,0-21.628,9.683-21.628,21.628
                                          c0,11.944,9.684,21.628,21.628,21.628c5.273,0,10.329-1.941,14.258-5.394l40.408,29.557c-0.159,1.058-0.268,2.132-0.268,3.234
                                          c0,11.944,9.684,21.628,21.628,21.628c11.912,0,21.629-9.655,21.629-21.628C268.733,225.079,259.078,215.36,247.104,215.36z"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <Dropdown
                                onMouseEnter={() => setDropDownHovered(true)}
                                onMouseLeave={() => setDropDownHovered(false)}
                                style={{
                                  borderRadius: 0,
                                  overflow: "visible",
                                  position: "relative",
                                  backgroundColor:
                                    folderIsHovered === index
                                      ? dropDownHovered
                                        ? "#007ed9"
                                        : "#FFFFFF"
                                      : "initial",
                                  height: "34px",
                                  width: "34px",
                                  padding: "5px",
                                }}
                                className="rounded"
                              >
                                <Dropdown.Toggle
                                  as="div"
                                  className="btn-link i-false gap-2 d-flex justify-content-end w-100 align-items-center"
                                  ref={(el) => (editRef.current[item.id] = el)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill={dropDownHovered ? "#ffffff" : "#000000"}
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className="flex-d dropdown-menu-right border"
                                  align="end"
                                >
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      handleUpdateFileModalShow(item._id);
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      deleteFile(item._id);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> */}
                              </div>
                            </>
                          )}
                          <div className=" w-100 rounded d-flex align-items-end justify-content-between text-sm p-2 gap-2"></div>
                          <div
                            style={{ maxHeight: "90%", flexGrow: 1 }}
                            className="p-2 d-flex justify-content-center align-items-center"
                            // onClick={() => handleFileClick(item)}
                          >
                            <img
                              src={item.isImage ? image : folderImage}
                              alt="gallery"
                              style={{ maxHeight: "60%", maxWidth: "100%" }}
                              className=" cursor-pointer rounded object-fit-contain"
                            />
                          </div>
                        </div>
                        <span style={{ flexGrow: "1" }}>
                          {item?.name?.length > 30
                            ? item.name.substring(0, 30) + "..."
                            : item?.name}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}

              {files?.map((item, index) => {
                const image = imageUrl + item.url;
                return (
                  <div
                    data-src={item.url}
                    className="mb-4 position-relative"
                    key={index}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      ["admin", "super-admin"].includes(user.role.name) &&
                        editRef.current[item.id].click();
                    }}
                  >
                    <div
                      className="rounded d-flex flex-column position-relative bg-lightGray rounded shadow-sm mb-2"
                      style={{ height: "10rem", width: "13rem" }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <div
                        // onClick={() => handleViewModalShow(item._id)}
                        onClick={() => handleFileClick(item)}
                        className="w-100 h-100 position-absolute top-0 left-0 opacity-25"
                      ></div>
                      {["admin", "super-admin"].includes(user.role.name) && (
                        <>
                          <div
                            style={{ top: "2px", right: "2px" }}
                            className={`d-flex justify-content-end gap-1 align-items-center position-absolute  ${
                              isHovered === index ? "show" : "d-none"
                            }`}
                          >
                            <div
                              onClick={() => handleShareModal(item._id)}
                              className="me-2 rounded"
                              style={{
                                position: "relative",
                                backgroundColor:
                                  isHovered === index
                                    ? shareIconHovered
                                      ? "#007ed9"
                                      : "#FFFFFF"
                                    : "initial",
                                height: "34px",
                                width: "34px",
                                paddingLeft: "7px",
                              }}
                              onMouseEnter={() => setShareIconHovered(true)}
                              onMouseLeave={() => setShareIconHovered(false)}
                            >
                              <svg
                                fill={shareIconHovered ? "#ffffff" : "#000000"}
                                height="20xpx"
                                width="20px"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 268.733 268.733"
                              >
                                <g>
                                  <path
                                    d="M0,141.467v36.37c0,5.69,4.613,10.304,10.304,10.304h33.425v-39.175c0-13.63,3.309-26.493,9.135-37.864
                                        c-12.09-0.718-23.009-5.835-31.187-13.77C8.495,107.539,0,123.506,0,141.467z"
                                  />
                                  <path
                                    d="M78.616,81.218c-5.225-8.579-8.236-18.646-8.236-29.403c0-6.551,1.13-12.839,3.183-18.697
                                        c-5.172-3.171-11.254-5.001-17.765-5.001c-18.8,0-34.041,15.239-34.041,34.04c0,18.8,15.241,34.041,34.041,34.041
                                        c2.589,0,5.107-0.299,7.531-0.847C67.813,90.029,72.951,85.282,78.616,81.218z"
                                  />
                                  <path
                                    d="M171.078,150.335c5.518,0,10.918,1.226,15.834,3.515l8.482-6.204c-0.432-22.684-11.904-42.655-29.279-54.77
                                        c-10.175,9.679-23.919,15.639-39.037,15.639c-15.118,0-28.862-5.96-39.038-15.638c-17.712,12.35-29.312,32.86-29.312,56.091v44.552
                                        c0,6.971,5.651,12.622,12.622,12.622h66.796c-2.988-5.393-4.696-11.589-4.696-18.178
                                        C133.45,167.214,150.33,150.335,171.078,150.335z"
                                  />
                                  <circle cx="127.078" cy="51.815" r="41.698" />
                                  <path
                                    d="M247.104,215.36c-3.436,0-6.672,0.822-9.558,2.248l-40.529-29.645l40.234-29.431c2.957,1.518,6.301,2.391,9.852,2.391
                                        c11.279,0,20.53-8.636,21.529-19.652c1.163-12.944-9.064-23.603-21.529-23.603c-11.944,0-21.628,9.683-21.628,21.628
                                        c0,0.99,0.09,1.957,0.219,2.911l-40.359,29.521c-3.96-3.473-9.025-5.393-14.258-5.393c-11.944,0-21.628,9.683-21.628,21.628
                                        c0,11.944,9.684,21.628,21.628,21.628c5.273,0,10.329-1.941,14.258-5.394l40.408,29.557c-0.159,1.058-0.268,2.132-0.268,3.234
                                        c0,11.944,9.684,21.628,21.628,21.628c11.912,0,21.629-9.655,21.629-21.628C268.733,225.079,259.078,215.36,247.104,215.36z"
                                  />
                                </g>
                              </svg>
                            </div>
                            <Dropdown
                              onMouseEnter={() => setDropDownHovered(true)}
                              onMouseLeave={() => setDropDownHovered(false)}
                              style={{
                                borderRadius: 0,
                                overflow: "visible",
                                position: "relative",
                                backgroundColor:
                                  isHovered === index
                                    ? dropDownHovered
                                      ? "#007ed9"
                                      : "#FFFFFF"
                                    : "initial",
                                height: "34px",
                                width: "34px",
                                padding: "5px",
                              }}
                              className="rounded"
                            >
                              <Dropdown.Toggle
                                as="div"
                                className="btn-link i-false gap-2 d-flex justify-content-end w-100 align-items-center"
                                ref={(el) => (editRef.current[item.id] = el)}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill={dropDownHovered ? "#ffffff" : "#000000"}
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                    stroke={
                                      dropDownHovered ? "#ffffff" : "#000000"
                                    }
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                    stroke={
                                      dropDownHovered ? "#ffffff" : "#000000"
                                    }
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                    stroke={
                                      dropDownHovered ? "#ffffff" : "#000000"
                                    }
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="flex-d dropdown-menu-right border"
                                align="end"
                              >
                                <Dropdown.Item
                                  onClick={(e) => {
                                    handleUpdateFileModalShow(item._id);
                                  }}
                                >
                                  {t("edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    deleteFile(item._id);
                                  }}
                                >
                                  {t("delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </>
                      )}
                      <div className=" w-100 rounded d-flex align-items-end justify-content-between text-sm p-2 gap-2"></div>
                      <div
                        style={{ maxHeight: "90%", flexGrow: 1 }}
                        className="p-2 d-flex justify-content-center align-items-center"
                        // onClick={() => handleFileClick(item)}
                      >
                        <img
                          src={
                            item.fileType == "pdf"
                              ? "/pdf-icon.png"
                              : item.fileType == "mp4"
                              ? "/video-icon.png"
                              : image
                          }
                          alt="gallery"
                          style={{ maxHeight: "60%", maxWidth: "100%" }}
                          className=" cursor-pointer rounded object-fit-contain"
                        />
                      </div>
                    </div>
                    <span style={{ flexGrow: "1" }}>
                      {item?.name?.length > 30
                        ? item.name.substring(0, 30) + "..."
                        : item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row text-center ">
              <img
                alt=""
                src={noData}
                className="mx-auto"
                id="image"
                style={{ width: "14%" }}
              />
              <h4 className="mt-2" style={{ color: "#949494" }}>
                {selectedFolder
                  ? `${t("folderIsEmpty")}`
                  : `${t("pleaseSelectAnyFolder")}`}
              </h4>
            </div>
          )}
        </div>

        {/* <div className="w-100 d-flex justify-content-between align-items-center pt-3">
          <h3>Images</h3>
          {["admin", "super-admin"].includes(user.role.name) && (
            <button
              className="btn btn-link btn-sm"
              onClick={handleFileModalShow}
            >
              {"+ "} Upload Files
            </button>
          )}
        </div> */}
      </div>

      {/* <Modal show={showFileModal} onHide={handleFileModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="hidden"
              className="form-control"
              name="parent"
              onChange={formik.handleChange}
              value={"selectedFolder"}
              id=""
            />
            <label htmlFor="">File Name:</label>
            <input
              type="name"
              className="form-control"
              name="name"
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="">Type:</label>
            <select
              className="form-control"
              name="type"
              id=""
              onChange={formik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              Shared With: (Enter emails comma separated)
            </label>
            <input
              type="name"
              className="form-control"
              name="sharedWith"
              disabled={formik.values.type === "public" ? true : false}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="icon">Select File:</label>
            <div
              className="drop-zone border border-primary rounded p-5 text-center"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              {selectedFile ? (
                <p>
                  Selected file: <b>{selectedFile.name}</b>
                </p>
              ) : (
                <p>Drag & Drop your file here, or click to select a file</p>
              )}
              <input
                ref={fileInputRef}
                name="file"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="visually-hidden form-control"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="secondary"
            onClick={handleFileModalClose}
          >
            Close
          </Button>
          <Button
            disabled={showLoading}
            className="btn btn-sm"
            variant="primary"
            onClick={formik.handleSubmit}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal> */}
      <EditFileModal
        showUpdateFileModal={showUpdateFileModal}
        handleUpdateFileModalClose={handleUpdateFileModalClose}
        selectedUpdateFile={selectedUpdateFile}
        selectedFolder={selectedFolder}
        // getData={getData}
        getSingleFolder={getSingleFolder}
      />

      <Modal show={showFileDetail} onHide={handleViewModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("fileDetail")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showImageDetail && (
            <>
              <div className="row">
                {videoFileTypes.includes(showImageDetail?.fileType) ? (
                  <video className="w-full" width="100%" controls sty>
                    <source
                      src={`${imageUrl}${showImageDetail?.url}`}
                      type="video/mp4"
                    />
                  </video>
                ) : imageFileTypes.includes(showImageDetail.fileType) ? (
                  <img
                    src={`${imageUrl}${showImageDetail?.url}`}
                    alt="File"
                    style={{ justifyContent: "center" }}
                  />
                ) : showImageDetail.fileType == "pdf" ? (
                  <embed
                    src={`${imageUrl}${showImageDetail?.url}`}
                    type="application/pdf"
                    height="600px"
                    width="100%"
                    controls="none"
                  />
                ) : (
                  // <Document file={`${imageUrl}${showImageDetail?.url}`}>
                  //   <Page pageNumber={1} width={600} />
                  // </Document>
                  <>{t("noPreviewAvailable")}</>
                )}
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <h4>{t("fileName")}</h4>
                </div>
                <div className="col-6">
                  <h4>{showImageDetail?.name}</h4>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <h4>{t("fileType")}</h4>
                </div>
                <div className="col-6">
                  <h4>{showImageDetail?.type}</h4>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <h4>{t("dateUploaded")}</h4>
                </div>
                <div className="col-6">
                  <h4>
                    {showImageDetail?.createdAt
                      ? new Date(
                          showImageDetail?.createdAt
                        ).toLocaleDateString()
                      : ""}
                  </h4>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleViewModalClose}
            className="btn btn-sm"
            variant="secondary"
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <FileShareModal
        newShareModalShow={newShareModalShow}
        handleShareModal={() => setHandleShareModal(false)}
        selectedFolder={selectedFolder}
        selectedUpdateFile={selectedUpdateFile}
        fileId={id}
      />

      <Modal
        show={newShareModalShowSubFolder}
        onHide={() => setHandleShareModalSubFolder(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("shareFolder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="hidden"
              className="form-control"
              name="parent"
              value={selectedFolder}
              onChange={formikSubFolder.handleChange}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              value={formikSubFolder.values.type}
              id=""
              onChange={formikSubFolder.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              type="sharedWith"
              value={formikSubFolder.values.sharedWith}
              className="form-control"
              name="sharedWith"
              onChange={formikSubFolder.handleChange}
              id=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="secondary"
            onClick={() => setHandleShareModalSubFolder(false)}
          >
            {t("close")}
          </Button>
          <Button
            onClick={formikSubFolder.handleSubmit}
            disabled={showLoading}
            className="btn btn-sm"
            variant="primary"
          >
            {t("share")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={newHandleUpdateSubFolder}
        onHide={setNewHandleUpdateSubFolder}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("updateFolder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">{t("enterFolderName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={updateSubFolderFormik.values.name}
              placeholder={updateFolders?.name}
              onChange={updateSubFolderFormik.handleChange}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              value={updateSubFolderFormik.values.type}
              id=""
              onChange={updateSubFolderFormik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              type="name"
              className="form-control"
              name="sharedWith"
              value={updateSubFolderFormik.values.sharedWith}
              onChange={updateSubFolderFormik.handleChange}
              id=""
            />
            <label htmlFor="icon">{t("selectFolderIcon")}:</label>
            <div
              className="drop-zone border border-primary rounded p-5 text-center"
              onDragOver={updateHandleDragOver}
              onDrop={updateHandleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              {selectedFile ? (
                <p>
                  {t("selectedFile")}: <b>{selectedFile.name}</b>
                </p>
              ) : (
                <p>{t("drag&DropFile")}</p>
              )}
              <input
                ref={fileInputRef}
                name="icon"
                type="file"
                accept="image/*"
                onChange={updateHandleFileChange}
                className="visually-hidden form-control"
              />
            </div>
            {updateFolders?.url && (
              <>
                <label htmlFor="icon">{t("currentIcon")}:</label>
                <img
                  src={`${imageUrl}${updateFolders.url}`}
                  className="w-100 object-fit-contain"
                  alt=""
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="primary"
            onClick={updateSubFolderFormik.handleSubmit}
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <ShareFolderModal
        newShareModalShowSubFolder={newShareModalShow}
        handleShareModalSubFolder={handleShareModalSubFolder}
        selectedFolder={selectedFolder}
      /> */}
    </>
  );
};

export default FilesList;
