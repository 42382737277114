import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import DataService from "../../../services/AxiosInstance";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../../loader.gif";
import { useTranslation } from "react-i18next";

const BlogSubscription = () => {
  const { t } = useTranslation();
  const showLoading = useSelector((state) => state.auth.showLoading);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        dispatch(loadingToggleAction(true));
        const formData = new FormData();
        formData.append("email", values.email);
        await DataService.post("/blogs/create/subscription", values);
        toast.success("Email Added to subscriptions!");
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Something went wrong!");
        dispatch(loadingToggleAction(false));
      }
    },
  });

  return (
    <>
      <div className="card h-auto notifiy">
        <div className="card-body">
          <div className="icon-box icon-box-sm bg-primary">
            <i className="fa-solid fa-bell text-white"></i>
          </div>
          <div className="notify-data">
            <h6>{t("followByEmail")}</h6>
            <span>{t("getNotifiedInbox")}</span>
            <div className="mt-3">
              <input
                type="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="form-control form-control-sm"
                placeholder={`${t("emailEnter")}`}
              />
              <div className="loader">
                <Button
                  type="submit"
                  disabled={showLoading}
                  className="btn btn-primary btn-sm d-block mt-3"
                  onClick={formik.handleSubmit}
                >
                  {showLoading ? (
                    <>
                      <span className="loaderIconWrapper me-1">
                        <img alt="" src={loader} style={{ width: "15px" }} />
                      </span>
                      <span className="subscribeText">{t("subscribe")}</span>
                    </>
                  ) : (
                    `${t("subscribe")}`
                  )}
                </Button>
              </div>
            </div>
            <span className="text-start mt-3 d-block text-danger">
              * {t("wePromiseThat")} !
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSubscription;
