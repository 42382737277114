import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const PageTitle = ({ motherMenu, activeMenu, pageContent }) => {
  const router = useLocation();
  console.log(router, "Router");
  let path = window.location.pathname.split("/");
  const { t } = useTranslation();
  return (
    <>
      <div className="row page-titles mx-0 mt-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${path[path.length - 1]}`}>{t(motherMenu)}</Link>
          </li>
          {activeMenu === "Blog Category" && (
            <li className="breadcrumb-item active">
              <Link to={`/blog-category`}>{t(activeMenu)}</Link>
            </li>
          )}
          {activeMenu === "Add Blog" && (
            <li className="breadcrumb-item active">
              <Link to={`/add-blog`}>{t(activeMenu)}</Link>
            </li>
          )}
          {activeMenu === "Blog" && (
            <li className="breadcrumb-item active">
              <Link to={`/blog`}>{t(activeMenu)}</Link>
            </li>
          )}
          {activeMenu === "Update Blog" && (
            <li className="breadcrumb-item active">
              <Link to={`#`}>{t(activeMenu)}</Link>
            </li>
          )}
          {activeMenu === "Blog Comment List" && (
            <li className="breadcrumb-item active">
              <Link to={`/comment-list`}>{t(activeMenu)}</Link>
            </li>
          )}
        </ol>
      </div>
    </>
  );
};

export default PageTitle;
