import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import { IMAGES } from "../../constant/theme";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BlogSubscription from "./BlogSubscription";
import DataService from "../../../services/AxiosInstance";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import ucwords from "../../../services/Ucwords";
import loader from "../../../loader.gif";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const BlogDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const user = useSelector((state) => state.auth.auth);
  const showLoading = useSelector((state) => state.auth.showLoading);
  const [blogComments, setBlogComments] = useState([]);

  const fetchData = async () => {
    try {
      const response = await DataService.get(`/blogs/${id}`);
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAllBlog = async () => {
    try {
      const response = await DataService.get("/blogs");
      setAllBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  function formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const userId = user ? user.id : "";
  const blogId = id ? id : "";

  const formik = useFormik({
    initialValues: {
      hiddenUserId: userId,
      comment: "",
      blogId: blogId,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        dispatch(loadingToggleAction(true));
        const response = await DataService.post(
          "/blogs/create/comments",
          values
        );
        if (response.data.success) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        resetForm();
        fetchComments();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(
          error?.response?.data?.message ?? "Error Adding Blog Comment!"
        );
        dispatch(loadingToggleAction(false));
      }
    },
  });

  const fetchComments = async () => {
    try {
      dispatch(loadingToggleAction(true));
      const response = await DataService.get(`/blogs/single/comment/${id}`);
      setBlogComments(response.data.data);
      dispatch(loadingToggleAction(false));
    } catch (error) {
      console.error("Error fetching blog comments:", error);
      dispatch(loadingToggleAction(false));
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <MainPagetitle mainTitle="blog detail" pageTitle="blog detail" parentTitle="Dashboard"  />
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0">
                    <h4 className="heading mb-0 blog-title">{t("blogs")}</h4>
                  </div>
                  <div className="card-body">
                    <div className="blog-img">
                      <img
                        src={
                          blogs.bannerImage
                            ? imageUrl + blogs.bannerImage
                            : IMAGES.Tab1
                        }
                        alt=""
                        className="blk-img2"
                      />
                      <div className="blog-text">
                        <h2>{ucwords(blogs.title)}</h2>
                        <img
                          src={
                            user?.profileImage
                              ? imageUrl + user?.profileImage
                              : IMAGES.Tab1
                          }
                          className="avatar rounded-circle me-2 small-post"
                          alt=""
                        />
                        <span>
                          {ucwords(user?.displayName)} in{" "}
                          {formatDate(blogs.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-9">
              <div className="card">
                <div className="card-body">
                  <p>
                    <Link to="/dashboard">
                      <b>{t("home")} »</b>
                    </Link>
                    <Link to="/blog-section">
                      <b className="ms-2 me-2">{t("blogs")} »</b>
                    </Link>{" "}
                    {ucwords(blogs.title)}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{ __html: blogs.description }}
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                  ></p>
                  <div className="row">
                    {allBlogs.slice(0, 4).map((item, ind) => (
                      <div className="col-xl-6" key={ind}>
                        <div className="seconday-post">
                          <img
                            src={
                              item.image ? imageUrl + item.image : IMAGES.Tab1
                            }
                            alt=""
                          />
                          <div className="post-1">
                            <div className="post-data">
                              {item.categories.map((cate, key) => (
                                <React.Fragment key={key}>
                                  <span
                                    className={`badge border-0 badge-sm `}
                                    style={{ backgroundColor: cate.color }}
                                  >
                                    {ucwords(cate.name)}
                                  </span>
                                  {key !== item.categories.length - 1 && (
                                    <>&nbsp;</>
                                  )}{" "}
                                </React.Fragment>
                              ))}
                              <h4>{ucwords(item.title)}</h4>
                              <div>
                                <img
                                  src={
                                    user?.profileImage
                                      ? imageUrl + user?.profileImage
                                      : IMAGES.Tab1
                                  }
                                  className="avatar rounded-circle me-2 small-post"
                                  alt=""
                                />
                                <span>
                                  <b className="text-primary">
                                    {ucwords(user?.displayName)}
                                  </b>{" "}
                                  in {formatDate(item.createdAt)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="container mt-4">
                        <h4>{t("comments")}</h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <form onSubmit={formik.handleSubmit}>
                                <input
                                  type="hidden"
                                  className="hiddenValue"
                                  name="hiddenUserId"
                                  value={user?.id}
                                />
                                <input
                                  type="hidden"
                                  className="hiddenBlogId"
                                  name="blogId"
                                  value={blogId}
                                />
                                <div className="card-body">
                                  <div className="mb-3">
                                    <textarea
                                      className="form-control"
                                      rows="10"
                                      placeholder={t("writeComment")}
                                      name="comment"
                                      onChange={formik.handleChange}
                                      value={formik.values.comment}
                                    ></textarea>
                                  </div>
                                  <div className="loader">
                                    <button
                                      type="submit"
                                      disabled={showLoading}
                                      className="btn btn-primary mt-3 justify-content-end"
                                      onClick={formik.handleSubmit}
                                    >
                                      {showLoading ? (
                                        <>
                                          <span className="me-1">
                                            <img
                                              src={loader}
                                              style={{ width: "15px" }}
                                            />
                                          </span>
                                          <span className="">{t("postComment")}</span>
                                        </>
                                      ) : (
                                        `${t("postComment")}`
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="card-header border-0 pt-0 px-0">
                <h4 className="heading mb-0 blog-title">{t("popularPost")}</h4>
              </div>
              <div className="recent-post">
                {allBlogs
                  .slice(0, 3)
                  .sort((a, b) => b.views - a.views)
                  .map((data, ind) => (
                    <div className="third-post style-1" key={ind}>
                      <img
                        src={data.image ? imageUrl + data.image : IMAGES.Tab1}
                        alt=""
                      />
                      <div className="post-1">
                        <div className="post-data">
                          <h4>{ucwords(data.title)}</h4>
                          <div className="mb-2">
                            <img
                              src={
                                user?.profileImage
                                  ? imageUrl + user?.profileImage
                                  : IMAGES.Tab1
                              }
                              className="avatar rounded-circle me-2 small-post"
                              alt=""
                            />
                            <span>{formatDate(data.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="card-header border-0 pt-0 px-0">
                <h4 className="heading mb-0 blog-title">{t("socialPlugin")}</h4>
              </div>
              <div className="mb-4">
                <ul className="c-social">
                  <li>
                    <a
                      href="https://www.facebook.com/dexignzone"
                      rel="noreferrer"
                      target="_blank"
                      className="bg-facebook"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.whatsapp.com/"
                      rel="noreferrer"
                      target="_blank"
                      className="bg-whatsapp"
                    >
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/dexignzone"
                      rel="noreferrer"
                      target="_blank"
                      className="bg-linkedin"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="skype:rahulxarma?chat" className="bg-skype">
                      <i className="fa-brands fa-skype"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <BlogSubscription />

              <div className="recent-post">
                <div className="card-header border-0 pt-0 px-0">
                  <h4 className="heading mb-0 blog-title">{t("comments")}</h4>
                </div>
                {blogComments.slice(0, 3).map((item, ind) => (
                  <div className="third-post style-2" key={ind}>
                    <img
                      src={
                        user?.profileImage
                          ? imageUrl + user?.profileImage
                          : IMAGES.Tab1
                      }
                      className="avatar  rounded-circle me-2 av-post"
                      alt=""
                    />
                    <div className="post-1">
                      <div className="post-data">
                        <h4 className="mb-0">{ucwords(user?.displayName)}</h4>
                        <div>
                          <span>{`${item.comment
                            .split(" ")
                            .slice(0, 4)
                            .join(" ")}...`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(BlogDetail);
