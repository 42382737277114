import React, { useEffect } from "react";
import DataService from "../../../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FileShareModal = ({
  newShareModalShow,
  handleShareModal,
  selectedFolder,
  selectedUpdateFile,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.auth);
  const showLoading = useSelector((state) => state.auth.showLoading);
  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      dispatch(loadingToggleAction(false));
      values.folderId = selectedFolder;
      if (!values.sharedWith.includes(user.email))
        values.sharedWith = user.email + "," + values.sharedWith;
      console.log(values, "Sumbmitted Values");
      try {
        const res = await DataService.post(
          "/media/file/" + selectedUpdateFile._id,
          values
        );
        toast.success(res?.data?.message ?? "File Updated Successfully");
        resetForm();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error updating file");
        dispatch(loadingToggleAction(false));
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("type", selectedUpdateFile?.type);
    formik.setFieldValue("sharedWith", selectedUpdateFile?.sharedWith);
  }, [newShareModalShow]);

  return (
    <>
      <Modal show={newShareModalShow} onHide={handleShareModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("shareFile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="hidden"
              className="form-control"
              name="parent"
              onChange={formik.handleChange}
              value={"selectedFolder"}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              id=""
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              type="name"
              className="form-control"
              name="sharedWith"
              value={formik.values.sharedWith}
              onChange={formik.handleChange}
              id=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleShareModal}
            className="btn btn-sm"
            variant="secondary"
          >
            {t("close")}
          </Button>
          <Button
            disabled={showLoading}
            className="btn btn-sm"
            onClick={formik.handleSubmit}
            variant="primary"
          >
            {t("share")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileShareModal;
