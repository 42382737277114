import React from "react";
import { IMAGES } from "../../constant/theme";
import MainPagetitle from "../../layouts/MainPagetitle";

const BlogComment = () => {
  return (
    <>
      {/* <MainPagetitle mainTitle="blog comment list" pageTitle="blog comment list" parentTitle="Dashboard"  /> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0">
                    <h4 className="heading mb-0 blog-title">Active users</h4>
                  </div>
                  <div className="card-body">
                    <div className="blog-img">
                      <img
                        src={IMAGES.Professional2}
                        alt=""
                        className="blk-img2"
                      />
                      <div className="blog-text">
                        <img
                          src={IMAGES.contact2}
                          className="avatar rounded-circle me-2 small-post"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="container mt-4">
                          <h4>Comments</h4>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-body">
                                  <textarea
                                    className="form-control"
                                    rows="10"
                                    placeholder="Write a comment..."
                                  ></textarea>
                                  <button className="btn btn-primary mt-3 justify-content-end">
                                    Post Comment
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogComment;
