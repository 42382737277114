import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constant/theme";
import DataService from "../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const tableData = [
  {
    emplid: "1001",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
  {
    emplid: "1002",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1003",
    department: "Computer Science",
    image: IMAGES.contact3,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky M",
    email: "pqr@gmail.com",
    gender: "Male",
    location: "France",
  },
  {
    emplid: "1004",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Elijah James",
    email: "stuy@gmail.com",
    gender: "Female",
    location: "Dubai",
  },
  {
    emplid: "1005",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Honey Risher",
    email: "xyz@gmail.com",
    gender: "Male",
    location: "USA",
  },
  {
    emplid: "1006",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Honey Risher",
    email: "xyz@gmail.com",
    gender: "Male",
    location: "USA",
  },
  {
    emplid: "1007",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1008",
    department: "Computer Science",
    image: IMAGES.contact3,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky M",
    email: "pqr@gmail.com",
    gender: "Male",
    location: "France",
  },
  {
    emplid: "1009",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
  {
    emplid: "1010",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Elijah James",
    email: "stuy@gmail.com",
    gender: "Female",
    location: "Dubai",
  },
  {
    emplid: "1011",
    department: "Computer Science",
    image: IMAGES.contact2,
    contact: "+91 123 456 7890",
    status: "Inactive",
    title: "Ankites Risher",
    email: "abc@gmail.com",
    gender: "Male",
    location: "Brazil",
  },
  {
    emplid: "1012",
    department: "Computer Science",
    image: IMAGES.contact1,
    contact: "+91 123 456 7890",
    status: "Active",
    title: "Ricky Antony",
    email: "ra@gmail.com",
    gender: "Female",
    location: "India",
  },
];

const Blogs = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getBlogs();
  }, []);
  const getBlogs = async () => {
    try {
      const response = await DataService.get("/blogs");
      console.log(response.data);
      setData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="card">
      <div className="card-body p-0 overflow-scroll">
        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
          <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
            <h4 className="heading mb-0 text-capitalize">
              recent {t("blogs")}
            </h4>
            <div>
              <Link
                to={"/blog-section"}
                className="text-primary"
                data-bs-toggle="offcanvas"
                onClick={() => {}}
              >
                {t("viewAll")}
              </Link>
            </div>
          </div>
          <div
            id="employee-tbl_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0"
            >
              <thead>
                <tr>
                  <th>{t("id")}</th>
                  <th>{t("title")}</th>
                  <th>{t("slug")}</th>
                  <th>{t("createdAt")}</th>
                </tr>
              </thead>
              <tbody className="">
                {data
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .slice(0, 10)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span>#{index}</span>
                      </td>
                      <td>
                        <Link to={`/blog-detail/${item._id}`}>
                          <h6>{item.title}</h6>
                        </Link>
                      </td>
                      <td>
                        <span>{item.slug}</span>
                      </td>
                      <td>
                        <span className="">
                          {new Date(item.createdAt).toDateString()}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
