import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { SVGICON } from "../../../constant/theme";
import GridTab from "./GridTab";
import EmployeeOffcanvas from "../../../constant/EmployeeOffcanvas";
import ListTab from "./ListTab";
import { useTranslation } from "react-i18next";

const User = () => {
  const { t } = useTranslation();
  const userdata = useRef();
  const navigate = useNavigate();
  return (
    <>
      <MainPagetitle
        mainTitle="User"
        pageTitle="User"
        parentTitle="dashboard"
        parentPageUrl="/dashboard"
      />
      <div className="container-fluid" style={{ paddingTop: "0px" }}>
        <div className="row">
          <Tab.Container defaultActiveKey={"List"}>
            <div className="d-flex justify-content-end align-items-center mb-4 mt-3">
              <div className="d-flex align-items-center">
                <Nav
                  as="ul"
                  className="nav nav-pills mix-chart-tab user-m-tabe"
                  id="pills-tab"
                >
                  <Nav.Item as="li" className="nav-item" role="presentation">
                    <Nav.Link
                      as="button"
                      className="nav-link"
                      eventKey={"List"}
                    >
                      {SVGICON.List}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link
                      as="button"
                      className="nav-link"
                      eventKey={"Grid"}
                    >
                      {SVGICON.GridDots}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Link to={"/add-user"} className="btn btn-primary btn-sm ms-2">
                  + {t("addUser")}
                </Link>
              </div>
            </div>
            <div className="col-xl-12 active-p">
              <Tab.Content>
                <Tab.Pane eventKey={"Grid"}>
                  <GridTab />
                </Tab.Pane>
                <Tab.Pane eventKey={"List"}>
                  <ListTab />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default User;
