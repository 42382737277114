import React, { useEffect, useRef, useState } from "react";
import Nestable from "react-nestable";
import Slider from "react-slick";
import { SVGICON } from "../../../constant/theme";
import DataService from "../../../../services/AxiosInstance";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import EditFolderModal from "./EditFolderModal";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import ShareFolderModal from "./ShareFolderModal";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

function convertKeys(data) {
  if (typeof data !== "object" || data === null) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(convertKeys);
  }
  const convertedData = {};

  for (const key in data) {
    if (key === "name") {
      convertedData["text"] = data[key];
    } else if (key === "_id") {
      convertedData["id"] = data[key];
    } else if (key === "folders") {
      convertedData["children"] = convertKeys(data[key]);
    } else {
      convertedData[key] = convertKeys(data[key]);
    }
  }

  return convertedData;
}
var settings = {
  // className: "center",
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        // infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginRight: "40px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        marginLeft: "40px",
        color: "black",
        zIndex: 50,
      }}
      onClick={onClick}
    />
  );
}

const FoldersList = ({
  selectedFolder,
  changeFolder,
  setRootFolderID,
  getFolderOne,
  handleFileModalShow,
}) => {
  const [tree, settree] = useState({
    example: 1,
    defaultCollapsed: false,
  });
  const refNestable = useRef(null);
  const user = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const editRef = useRef([]);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFolder, setFilteredFolder] = useState([]);
  const [isHovered, setIsHovered] = useState(-1);
  const [shareIconHovered, setShareIconHovered] = useState(false);
  const [dropDownHovered, setDropDownHovered] = useState(false);
  const [newShareModalShow, setHandleShareModal] = useState(false);
  const [folders, setFolders] = useState();
  const [organizedFolders, setOrganizedFolders] = useState([]);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [isNestableVisible, setIsNestableVisible] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleEditModalClose = () => setEditFolderModal(false);
  const handleEditModalShow = (e) => {
    e.preventDefault(); // Prevent default right-click menu
    setEditFolderModal(true);
  };
  const handleShareModal = (state) => setHandleShareModal(state);

  const handleMouseEnter = (i) => {
    setIsHovered(i);
  };

  const handleMouseLeave = (i) => {
    setIsHovered(null);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "public",
      sharedWith: "",
    },
    onSubmit: async (values, { resetForm }) => {
      if (values.name == "") {
        toast.error("Folder name cannot be empty");
        return;
      }

      dispatch(loadingToggleAction(true));
      console.log(values);
      console.log("selected folder data", selectedFolder);
      console.log(selectedFile);

      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("parent", selectedFolder);
        formData.append("type", values.type);
        formData.append("sharedWith", user.email + "," + values.sharedWith);
        formData.append("name", values.name);
        await DataService.post("/media/folders/create", formData);
        toast.success("Folder Created Successfully");
        // window.location.reload();
        changeFolder(selectedFolder);
        getData();
        resetForm();
        setShow(false);
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error creating folder");
        dispatch(loadingToggleAction(false));
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);
  const deleteFolder = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}` // Add translated cancel button text
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete("/media/folders/" + id);
          if (response.data.success) {
            toast.success("Folder Deleted Successfully!");
            getData();
            window.location.reload();
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error creating folder");
    }
  };

  function organizeItems(items) {
    const itemsMap = new Map();
    items.forEach((item) => {
      itemsMap.set(item.id, { ...item, children: [] });
    });

    const organizedItems = [];
    itemsMap.forEach((item) => {
      if (item.parent) {
        const parent = itemsMap.get(item.parent);
        if (parent) {
          parent.children.push(item);
        }
      } else {
        organizedItems.push(item);
      }
    });

    return organizedItems;
  }

  const getData = async () => {
    try {
      dispatch(loadingToggleAction(true));
      const res = await DataService.get("/media/folders");
      let foldersArray = res?.data?.data?.filter((folder) => {
        if (folder.type === "private") {
          return folder?.sharedWith?.includes(user?.email ?? "") ?? false;
        } else {
          return true;
        }
      });

      setFolders(foldersArray);
      console.log("folder array gg", foldersArray);
      const convertedData = convertKeys(res.data.data);
      const organizedData = organizeItems(convertedData);
      console.log("organize data", organizedData);
      setOrganizedFolders(organizedData);
      dispatch(loadingToggleAction(false));
      return res.data;
    } catch (error) {
      dispatch(loadingToggleAction(false));
    }
  };

  const { defaultCollapsed } = tree;

  useEffect(() => {
    const filtered = searchQuery
      ? organizedFolders.filter((folder) => {
          return folder.name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      : organizedFolders;

    setFilteredFolder(filtered);
  }, [searchQuery, organizedFolders]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredFolder(organizedFolders); // Reset filteredFolder to show all folders
    }
  }, [searchQuery, organizedFolders]);

  return (
    <>
      <div style={{ height: "100%" }} className="d-flex flex-column">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h3>{t("folders")}</h3>
          <div>
            {["admin", "super-admin"].includes(user.role.name) && (
              <button
                className="btn btn-link btn-sm"
                onClick={handleFileModalShow}
              >
                {"+ "} {t("uploadFiles")}
              </button>
            )}
            {["admin", "super-admin"].includes(user.role.name) && (
              <button className="btn btn-link btn-sm" onClick={handleShow}>
                {"+ "} {t("createFolder")}
              </button>
            )}
          </div>
        </div>
        <div className="w-100">
          <input
            type="text"
            name="foldersearch"
            className="form-control shadow-sm"
            placeholder={`${t("searchFolder")}`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div
          className="h-50 overflow-scroll rounded-lg"
          style={{ flexGrow: 1 }}
        >
          {isNestableVisible && (
            <div className="nestable p-0">
              <div className="dd py-5" id="nestable">
                {/* <Nestable items={filteredFolder} renderItem={renderItem} /> */}
                {filteredFolder && filteredFolder.length > 0 ? (
                  // <Slider {...settings} className="mx-auto">
                  <div className="d-flex gap-5" style={{ overflow: "scroll" }}>
                    {filteredFolder?.map((folder, i) => (
                      <>
                        <div key={i} className={`position-relative`}>
                          <div
                            // className="position-absolute top-0 w-100 d-flex justify-content-end align-items-center"
                            className={`${
                              isHovered === i ? "show" : "d-none"
                            } position-absolute top-0 w-100 d-flex justify-content-end align-items-center`}
                          >
                            <div
                              onClick={() => handleShareModal(true)}
                              onMouseEnter={() => {
                                setIsHovered(i);
                                setShareIconHovered(true);
                              }}
                              onMouseLeave={() => {
                                setIsHovered(-1);
                                setShareIconHovered(false);
                              }}
                              className=" rounded"
                              style={{
                                position: "relative",
                                left: "220px",
                                backgroundColor:
                                  isHovered === i
                                    ? shareIconHovered
                                      ? "#007ed9"
                                      : "#F8F8F8"
                                    : "initial",
                                padding: "5px",
                                marginRight: "6px",
                              }}
                              key={i}
                            >
                              <svg
                                fill={shareIconHovered ? "#ffffff" : "#000000"}
                                height="20xpx"
                                width="20px"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 268.733 268.733"
                                key={i}
                              >
                                <g>
                                  <path
                                    d="M0,141.467v36.37c0,5.69,4.613,10.304,10.304,10.304h33.425v-39.175c0-13.63,3.309-26.493,9.135-37.864
                                    c-12.09-0.718-23.009-5.835-31.187-13.77C8.495,107.539,0,123.506,0,141.467z"
                                  />
                                  <path
                                    d="M78.616,81.218c-5.225-8.579-8.236-18.646-8.236-29.403c0-6.551,1.13-12.839,3.183-18.697
                                    c-5.172-3.171-11.254-5.001-17.765-5.001c-18.8,0-34.041,15.239-34.041,34.04c0,18.8,15.241,34.041,34.041,34.041
                                    c2.589,0,5.107-0.299,7.531-0.847C67.813,90.029,72.951,85.282,78.616,81.218z"
                                  />
                                  <path
                                    d="M171.078,150.335c5.518,0,10.918,1.226,15.834,3.515l8.482-6.204c-0.432-22.684-11.904-42.655-29.279-54.77
                                    c-10.175,9.679-23.919,15.639-39.037,15.639c-15.118,0-28.862-5.96-39.038-15.638c-17.712,12.35-29.312,32.86-29.312,56.091v44.552
                                    c0,6.971,5.651,12.622,12.622,12.622h66.796c-2.988-5.393-4.696-11.589-4.696-18.178
                                    C133.45,167.214,150.33,150.335,171.078,150.335z"
                                  />
                                  <circle cx="127.078" cy="51.815" r="41.698" />
                                  <path
                                    d="M247.104,215.36c-3.436,0-6.672,0.822-9.558,2.248l-40.529-29.645l40.234-29.431c2.957,1.518,6.301,2.391,9.852,2.391
                                    c11.279,0,20.53-8.636,21.529-19.652c1.163-12.944-9.064-23.603-21.529-23.603c-11.944,0-21.628,9.683-21.628,21.628
                                    c0,0.99,0.09,1.957,0.219,2.911l-40.359,29.521c-3.96-3.473-9.025-5.393-14.258-5.393c-11.944,0-21.628,9.683-21.628,21.628
                                    c0,11.944,9.684,21.628,21.628,21.628c5.273,0,10.329-1.941,14.258-5.394l40.408,29.557c-0.159,1.058-0.268,2.132-0.268,3.234
                                    c0,11.944,9.684,21.628,21.628,21.628c11.912,0,21.629-9.655,21.629-21.628C268.733,225.079,259.078,215.36,247.104,215.36z"
                                  />
                                </g>
                              </svg>
                            </div>
                            {["admin", "super-admin"].includes(
                              user.role.name
                            ) && (
                              <Dropdown
                                onMouseEnter={() => {
                                  setIsHovered(i);
                                  setDropDownHovered(true);
                                }}
                                onMouseLeave={() => {
                                  setIsHovered(i);
                                  setDropDownHovered(false);
                                }}
                                style={{
                                  position: "relative",
                                  left: "222px",
                                  backgroundColor:
                                    isHovered === i
                                      ? dropDownHovered
                                        ? "#007ed9"
                                        : "#F8F8F8"
                                      : "initial",
                                  padding: "3px",
                                }}
                                className="rounded"
                              >
                                <Dropdown.Toggle
                                  as="div"
                                  className="btn-link i-false d-flex align-items-center justify-content-end"
                                  ref={(el) =>
                                    (editRef.current[folder.id] = el)
                                  }
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill={
                                      dropDownHovered ? "#ffffff" : "#000000"
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke={
                                        dropDownHovered ? "#ffffff" : "#000000"
                                      }
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className="flex-d dropdown-menu-right border"
                                  align="end"
                                >
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      changeFolder(folder.id);
                                      handleEditModalShow(e);
                                    }}
                                  >
                                    {t("edit")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      deleteFolder(folder.id);
                                    }}
                                  >
                                    {t("delete")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center"
                          onClick={() => {
                            changeFolder(folder.id);
                            setRootFolderID(folder.id);
                            getFolderOne(folder.id);
                            setIsNestableVisible(false);
                          }}
                          // style={{
                          //   color: "black",
                          // }}
                        >
                          <div
                            key={i}
                            onMouseEnter={() => handleMouseEnter(i)}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            <div
                              key={i}
                              className={`rounded shadow-sm ${
                                selectedFolder === folder.id
                                  ? "border"
                                  : "bg-lightGray"
                              }  mb-2 overflow-hidden d-flex flex-column`}
                              style={{
                                height: "10rem",
                                width: "13rem",
                              }}
                            >
                              <div
                                className="px-3 d-flex justify-content-center align-items-center"
                                style={{ flexGrow: 1 }}
                              >
                                {folder?.url ? (
                                  <img
                                    src={`${imageUrl}${folder.url}`}
                                    alt=""
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      // height: "5rem",
                                    }}
                                    className=" object-fit-contain rounded"
                                  />
                                ) : (
                                  SVGICON.Plugins
                                )}
                              </div>
                            </div>
                            <span className="p-2">
                              {folder.text}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                    {/* </Slider> */}
                  </div>
                ) : (
                  <div className="row text-center ">
                    <h4 className="mt-2" style={{ color: "#949494" }}>
                      {t("noFolderFound")}
                    </h4>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <Nestable
        className="p-0"
        items={filteredFolder}
        collapsed={defaultCollapsed}
        renderItem={renderItem}
        ref={refNestable}
        maxDepth={30}
      /> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("createFolder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="hidden"
              className="form-control"
              name="parent"
              onChange={formik.handleChange}
              value={selectedFolder}
              id=""
            />
            <label htmlFor="">{t("enterFolderName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="">{t("type")}:</label>
            <select
              className="form-control"
              name="type"
              value={formik.values.type}
              id=""
              onChange={formik.handleChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
            <label htmlFor="">
              {t("shareWithCommaSeparated")}
            </label>
            <input
              type="name"
              className="form-control"
              name="sharedWith"
              value={formik.values.sharedWith}
              onChange={formik.handleChange}
              id=""
            />
            <label htmlFor="icon">{t("selectFolderIcon")}:</label>
            <div
              className="drop-zone border border-primary rounded p-5 text-center"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              {selectedFile ? (
                <p>
                  {t("selectedFile")}: <b>{selectedFile.name}</b>
                </p>
              ) : (
                <p>{t("drag&DropFile")}</p>
              )}
              <input
                ref={fileInputRef}
                name="icon"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="visually-hidden form-control"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="secondary"
            onClick={handleClose}
          >
            {t("close")}
          </Button>
          <Button
            className="btn btn-sm"
            variant="primary"
            onClick={formik.handleSubmit}
          >
            {t("create")}
          </Button>
        </Modal.Footer>
      </Modal>
      <EditFolderModal
        handleEditModalClose={handleEditModalClose}
        editFolderModal={editFolderModal}
        selectedFolder={selectedFolder}
        getData={getData}
      />
      <ShareFolderModal
        newShareModalShow={newShareModalShow}
        handleShareModal={handleShareModal}
        selectedFolder={selectedFolder}
        getData={getData}
      />
    </>
  );
};

export default FoldersList;
