import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../constant/theme";
import { Dropdown } from "react-bootstrap";
import DataService from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ListTab = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = tableData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tableData.length / 10);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const response = await DataService.get("/users/");
    setTableData(response?.data?.data ?? []);
  };

  const deleteUser = async (iid) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}` // Add translated cancel button text
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete(`/users/${iid}`);
          if (response.data.success) {
            toast.success("User Deleted Successfully!");
            getUserData();
          }
        } else {
          toast.error("Failed to delete the category.");
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Error Occur while Deleting User!"
      );
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % tableData.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
              <table
                id="projects-tbl"
                className="table ItemsCheckboxSec dataTable no-footer mb-0"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("user")}</th>
                    <th>{t("email")}</th>
                    <th>{t("role")}</th>
                    <th>{t("phone")}</th>
                    <th>{t("gender")}</th>
                    <th>{t("dateOfBirth")}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems &&
                    currentItems.length > 0 &&
                    currentItems.map((item, index) => (
                      <tr key={index}>
                        <td className="sorting_20">{index + 1}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                item?.profileImage
                                  ? imageUrl + item?.profileImage
                                  : IMAGES.Tab1
                              }
                              className="avatar rounded-circle"
                              alt=""
                            />
                            <p className="mb-0 ms-2 text-capitalize">
                              {item.name}
                            </p>
                          </div>
                        </td>
                        <td>{item.email}</td>
                        <td className="text-capitalize">{item.role}</td>
                        <td>{item.phone}</td>
                        <td className="text-capitalize">{item.gender}</td>
                        <td>{new Date(item.birth).toLocaleDateString()}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="div"
                              className="btn-link i-false"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                  stroke="#737B8B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                  stroke="#737B8B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                  stroke="#737B8B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="flex-d dropdown-menu-right"
                              align="end"
                            >
                              <Dropdown.Item>
                                <Link
                                  className="d-flex align-items-center h-100 w-100"
                                  to={`/edit-user/${item._id}`}
                                >
                                  {t("edit")}
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  deleteUser(item._id);
                                }}
                              >
                                {t("delete")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="d-flex w-100 my-3">
                <ReactPaginate
                  className="w-100 d-flex justify-content-end gap-3"
                  breakLabel="..."
                  nextLabel=" >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTab;
