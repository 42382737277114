import axios from "axios";
import { store } from "../store/store";
const baseURL = process.env.REACT_APP_API_ENDPOINT;

const DataService = axios.create({
  baseURL: baseURL + "/api",
});

DataService.interceptors.request.use((config) => {
  try {
    const state = store.getState();
    return config;
  } catch (error) {
    console.error(error);
    return config;
  }
});

export default DataService;
