import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DataService from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import MainPagetitle from "../../../layouts/MainPagetitle";

const EventCategories = () => {
  const { t } = useTranslation();
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
    formikAdd.resetForm();
  };
  const handleShowAdd = () => {
    setShowAdd(true);
  };

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
    formikEdit.resetForm();
  };
  const handleShowEdit = () => {
    setShowEdit(true);
  };

  const [eid, setEid] = useState(null);
  const [ename, setEname] = useState("");
  const [loading, setLoading] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const validationSchema2 = Yup.object().shape({
    name2: Yup.string().required("Name is required"),
  });

  const formikAdd = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await DataService.post(
          "/events-categories/create",
          values
        );
        if (response.data.success) {
          toast.success("Category Added Successfully");
          handleCloseAdd();
          fetchData();
          setLoading(false);
        }
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error?.response?.data?.message ?? "Error Adding Category");
        setLoading(false);
      }
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      name2: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      console.log(values);
      let obj = {
        name: values.name2,
      };
      try {
        const response = await DataService.put(
          `/events-categories/${eid}`,
          obj
        );
        if (response.data.success) {
          toast.success("Category Updated Successfully");
          handleCloseEdit();
          fetchData();
          setEid(null);
          setLoading(false);
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message ?? "Error While Updating Category"
        );
        setLoading(false);
      }
    },
  });

  // const [data, setData] = useState(
  //   document.querySelectorAll("#blog_wrapper tbody tr")
  // );
  // const sort = 5;
  // const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = blogs.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blogs.length / 10);
  const navigate = useNavigate();

  // const chageData = (frist, sec) => {
  //   for (var i = 0; i < data.length; ++i) {
  //     if (i >= frist && i < sec) {
  //       data[i].classList.remove("d-none");
  //     } else {
  //       data[i].classList.add("d-none");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setData(document.querySelectorAll("#blog_wrapper tbody tr"));
  // }, [test]);

  // activePag.current === 0 && chageData(0, sort);
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // const onClick = (i) => {
  //   activePag.current = i;
  //   chageData(activePag.current * sort, (activePag.current + 1) * sort);
  //   settest(i);
  // };

  const fetchData = async () => {
    try {
      const response = await DataService.get("/events-categories/");
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % blogs.length;
    setItemOffset(newOffset);
  };

  const deleteCategory = async (iid) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete(
            `/events-categories/${iid}`
          );
          if (response.data.success) {
            toast.success("Event Category Deleted Successfully!");
            fetchData();
          } else {
            toast.error(
              response?.data?.message ?? "Failed to delete the category."
            );
          }
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Failed to delete the category."
      );
      console.error("Error deleting blog:", error);
    }
  };
  return (
    <>
      <MainPagetitle mainTitle="event categories" pageTitle="event categories" parentTitle="Dashboard"  />
      <div className="card m-3">
        <div className="card-body p-3">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div className="tbl-caption">
              <h4 className="heading mb-0">{t("eventCategoryList")}</h4>
              <div>
                <Link
                  onClick={() => {
                    handleShowAdd();
                  }}
                  className="btn btn-primary light btn-sm me-2"
                >
                  <i className="fa-solid fa-plus" /> {t("addCategory")}
                </Link>
              </div>
            </div>

            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div id="user-tbl_wrapper" className="dataTables_wrapper no-footer">
                    <table
                      id="projects-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="">{t("title")}</th>
                          <th className="text-end">{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogs.map((blog, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className=" text-capitalize">{blog.name}</td>
                            <td className="text-end">
                              <Link
                                type="button"
                                onClick={() => {
                                  formikEdit.setFieldValue("name2", blog?.name);
                                  handleShowEdit();
                                  setEid(blog?._id);
                                }}
                                className="btn btn-warning btn-sm content-icon me-1"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                              <Link
                                type="button"
                                onClick={() => {
                                  deleteCategory(blog?._id);
                                }}
                                className="btn btn-danger btn-sm content-icon ms-1"
                              >
                                <i className="fa-solid fa-trash" />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex w-100 my-3">
                      <ReactPaginate
                        className="w-100 d-flex justify-content-end gap-3"
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div id="blog_wrapper" className="dataTables_wrapper no-footer">
              <table className="table table-responsive-lg table-striped table-condensed flip-content">
                <thead>
                  <tr>
                    <th className="text-black">S.No</th>
                    <th className="text-black">{t("title")}</th>
                    <th className="text-black text-end">{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {blogs.map((blog, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className=" text-capitalize">{blog.name}</td>
                      <td className="text-end">
                        <Link
                          type="button"
                          onClick={() => {
                            formikEdit.setFieldValue("name2", blog?.name);
                            handleShowEdit();
                            setEid(blog?._id);
                          }}
                          className="btn btn-warning btn-sm content-icon me-1"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                        <Link
                          type="button"
                          onClick={() => {
                            deleteCategory(blog?._id);
                          }}
                          className="btn btn-danger btn-sm content-icon ms-1"
                        >
                          <i className="fa-solid fa-trash" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {t("showing")} {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  {t("of")} {data.length} {t("entries")}
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example2_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="#"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" />
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="#"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" />
                  </Link>
                </div>
              </div>
            </div> */}
            
          </div>
        </div>
      </div>
      <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header closeButton>
          <Modal.Title>{t("addCategory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">{t("enterCategoryName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={formikAdd.handleChange}
              value={formikAdd.values.name}
            />
            {formikAdd.errors.name && (
              <div className="text-danger fs-12">{formikAdd.errors.name}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="secondary"
            onClick={handleCloseAdd}
          >
            {t("close")}
          </Button>
          <Button
            disabled={loading}
            className="btn btn-sm"
            variant="primary"
            onClick={formikAdd.handleSubmit}
          >
            {t("add")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("editCategory")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">{t("enterCategoryName")}:</label>
            <input
              type="text"
              className="form-control"
              name="name2"
              id="name2"
              onChange={formikEdit.handleChange}
              value={formikEdit.values.name2}
            />
            {formikEdit.errors.name2 && (
              <div className="text-danger fs-12">{formikEdit.errors.name2}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-sm"
            variant="secondary"
            onClick={handleCloseEdit}
          >
            {t("close")}
          </Button>
          <Button
            disabled={loading}
            className="btn btn-sm"
            variant="primary"
            onClick={formikEdit.handleSubmit}
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventCategories;
