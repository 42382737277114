import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from "../../../constant/theme";
import * as Yup from "yup";
import MainPagetitle from "../../../layouts/MainPagetitle";
import DataService from "../../../../services/AxiosInstance";
import CountriesJson from "../../../constant/countries.json";
import { toast } from "react-toastify";
import { validateImageUrl } from "../../../../services/Validation";
import { useDispatch, useSelector } from "react-redux";
import { loadingToggleAction } from "../../../../store/actions/AuthActions";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const options1 = [
  { value: "", label: "Select" },
  { value: "66026da3e28086935bcb5bff", label: "Super Admin" },
  { value: "66026dace28086935bcb5c04", label: "Admin" },
  { value: "66026db7e28086935bcb5c07", label: "Team" },
];

const options2 = [
  { value: "", label: "Select" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const EditUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showLoading = useSelector((state) => state.auth.showLoading);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    const response = await DataService.get("/roles/");
    console.log(response.data.data);
    setRoles(response?.data?.data ?? []);

    let response2 = await DataService.get(`/users/${id}`);
    const ee = await validateImageUrl(response2?.data?.data?.profileImage);
    response2.data.data.profileImage = ee;
    console.log(response2.data.data);
    setUser(response2?.data?.data ?? {});
    console.log(ee, "Image Url ");
    console.log(response2?.data?.data?.birth);
    let date = new Date(response2?.data?.data?.birth ?? new Date());
    formik.setValues(response2?.data?.data ?? {});
    formik.setFieldValue("birth", date);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const validationSchemaPassword = Yup.object().shape({
    newPassword: Yup.string().required("New Password is required"),
    oldPassword: Yup.string().required("Old Password is required"),
  });

  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      dispatch(loadingToggleAction(true));
      try {
        console.log("pass", values);
        const response = await DataService.put(
          `/auth/changePassword/${id}`,
          values
        );
        toast.success(response.data.message);
        formikPassword.resetForm();
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(
          error?.response?.data?.message ?? "Error Updating Password!"
        );
        dispatch(loadingToggleAction(false));
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      role: "",
      gender: "",
      birth: new Date(),
      phone: "",
      country: "",
      city: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(loadingToggleAction(true));
      try {
        values.birth = new Date(values.birth);
        console.log(values.birth);
        console.log("Edit User", values);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("role", values.role);
        formData.append("gender", values.gender);
        formData.append("birth", values.birth); // Assuming it's a Date object
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("country", values.country);
        formData.append("city", values.city);
        formData.append("image", selectedFile);
        const response = await DataService.put(`/users/${id}`, formData);
        toast.success(response.data.message);
        dispatch(loadingToggleAction(false));
      } catch (error) {
        toast.error(error?.response?.data?.message ?? "Error Updating User!");
        dispatch(loadingToggleAction(false));
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("==file==", file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById("image").src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <MainPagetitle
        mainTitle="edit user"
        pageTitle="edit user"
        parentTitle="Dashboard"
      />
      <div className="container-fluid" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div
              className="profile-card card-bx m-b30 mt-3"
              style={{ border: "none" }}
            >
              <div
                className="card-header"
                style={{ border: "none", padding: "0px" }}
              >
                <h6 className="title">{t("accountSetup")}</h6>
                <div className="author-profile">
                  <div style={{ position: "relative" }} className="relative">
                    <img
                      id="image"
                      width={100}
                      height={100}
                      style={{ borderRadius: 100 }}
                      src={
                        user && user.profileImage
                          ? imageUrl + user.profileImage
                          : selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : IMAGES.Tab1
                      }
                      alt=""
                    />
                    <div
                      className="upload-link"
                      title=""
                      data-toggle="tooltip"
                      data-placement="right"
                      data-original-title="update"
                    >
                      <input
                        onChange={handleFileChange}
                        type="file"
                        accept="image/*"
                        className="update-flie"
                      />
                      <i className="fa fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
              <form className="profile-form" onSubmit={formik.handleSubmit}>
                <div className="card-body mt-4" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("fullName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      {formik.errors.name && (
                        <div className="text-danger fs-12">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("emailAddress")}</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <div className="text-danger fs-12">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("role")}</label>
                      <Select
                        options={options1}
                        className="custom-react-select"
                        isSearchable={false}
                        name="role"
                        id="role"
                        onChange={(selectedOption) =>
                          formik.setFieldValue("role", selectedOption.value)
                        }
                        value={options1.find(
                          (option) => option.value === formik.values.role
                        )}
                      />
                      {formik.errors.role && (
                        <div className="text-danger fs-12">
                          {formik.errors.role}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("gender")}</label>
                      <Select
                        options={options2}
                        className="custom-react-select"
                        isSearchable={false}
                        name="gender"
                        onChange={(selectedOption) =>
                          formik.setFieldValue("gender", selectedOption.value)
                        }
                        value={options2.find(
                          (option) => option.value === formik.values.gender
                        )}
                      />
                      {formik.errors.gender && (
                        <div className="text-danger fs-12">
                          {formik.errors.gender}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("dateOfBirth")} (</label>
                      <input
                        type="date"
                        className="form-control"
                        id="birth"
                        name="birth"
                        value={
                          new Date(formik.values.birth)
                            .toISOString()
                            .split("T")[0]
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("phone")}</label>
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("country")}</label>
                      <select
                        name="country"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                      >
                        {CountriesJson.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("city")}</label>
                      <input
                        className="custom-react-select form-control"
                        isSearchable={false}
                        name="city"
                        onChange={formik.handleChange}
                        value={
                          formik?.values?.city !== "undefined"
                            ? formik.values.city
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer"
                  style={{ border: "none", padding: "0px" }}
                >
                  <button
                    disabled={showLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {t("update")}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-xl-12 col-lg-12">
            <div
              className=" profile-card card-bx m-b30"
              style={{ border: "none" }}
            >
              <form
                className="profile-form"
                onSubmit={formikPassword.handleSubmit}
              >
                <div className="card-body mt-5" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("oldPassword")}</label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        name="oldPassword"
                        onChange={formikPassword.handleChange}
                        value={formikPassword.values.oldPassword}
                      />
                      {formikPassword.errors.oldPassword && (
                        <div className="text-danger fs-12">
                          {formikPassword.errors.oldPassword}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">{t("newPassword")}</label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        onChange={formikPassword.handleChange}
                        value={formikPassword.values.newPassword}
                      />
                      {formikPassword.errors.newPassword && (
                        <div className="text-danger fs-12">
                          {formikPassword.errors.newPassword}
                        </div>
                      )}
                    </div>
                    <div className=" mb-3">
                      <button
                        type="submit"
                        disabled={showLoading}
                        className="btn btn-primary"
                      >
                        {t("updatePassword")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditUser;
