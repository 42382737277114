import React, { Component, useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import DataService from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const EventCalendar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.auth);
  const [calendarEvents, setCalenderEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");

  useEffect(() => {}, []);

  useEffect(() => {
    getdata();
  }, []);

  const getCategoryData = async () => {
    const response = await DataService.get("/events-categories/");
    console.log(response.data.data);
    setCategories(response?.data?.data ?? []);

    const response2 = await DataService.get("/events/");
    console.log(response2.data.data);
    setCalenderEvents(response2?.data?.data ?? []);
  };

  const getdata = async () => {
    const response = await DataService.get("/events-categories/");
    console.log(response.data.data);
    setCategories(response?.data?.data ?? []);

    const response2 = await DataService.get("/events/");
    console.log(response2.data.data);
    setCalenderEvents(response2?.data?.data ?? []);
  };

  const deleteEvent = async (id) => {
    try {
      Swal.fire({
        title: `${t("areYouSure")}`,
        text: `${t("youWontBeAble")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${t("yesDeleteIt")}`,
        cancelButtonText: `${t("cancel")}`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await DataService.delete(`/events/${id}`);
          if (response?.data?.success) {
            toast.success(
              response?.data?.message ?? "Event deleted successfully"
            );
            getdata();
          } else {
            toast.error(
              response?.data?.message ?? "Error Occur while deleting"
            );
          }
        }
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message ?? "Failed to delete the event."
      );
      console.error("Error deleting blog:", error);
    }
  };

  const cap = (txt) => {
    if (typeof txt !== "string") {
      return "Input is not a string";
    }
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  };

  const eventClick = (eventClick) => {
    let data = eventClick.event.extendedProps;
    console.log("=data=", data);
    Alert.fire({
      title: cap(eventClick.event.title),
      imageUrl: imageUrl + data.image,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      html:
        `<div class="table-responsive border-none" style="border:none;">
      <table class="table border-none" style="border:none;text-align:left;">
      <tbody> 
      <tr style="border-style: none">
      <td style="border-style:none;">Category</td>
      <td style="border-style:none;"><strong style="text-transform: capitalize;">` +
        data?.category?.name +
        `</strong></td>
      </tr>
      <tr style="border-style: none">
      <td style="border-style:none;">Start Time</td>
      <td style="border-style:none;"><strong>` +
        new Date(eventClick.event.start).toLocaleString() +
        `</strong></td>
      </tr>
      <tr style="border-style: none">
      <td style="border-style:none;">Location</td>
      <td style="border-style:none;"><strong>` +
        cap(data.location) +
        `</strong></td>
      </tr>
      <tr style="border-style: none">
      <td style="border-style:none;">Price</td>
      <td style="border-style:none;"><strong>` +
        data.price +
        `</strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,
      showConfirmButton: ["admin", "super-admin"].includes(user.role.name)
        ? true
        : false,
      showDenyButton: ["admin", "super-admin"].includes(user.role.name)
        ? true
        : false,
      showCancelButton: false,
      denyButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("edit"),
      cancelButtonText: t("delete"),
      denyButtonText: t("delete"),
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        console.log("edit");
        navigate(`/edit-event/${data._id}`);
      }
      if (result.isDismissed) {
        console.log("delete");
      }
      if (result.isDenied) {
        console.log("din");
        deleteEvent(data._id);
      }
    });
  };
  const dragFunction = () => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        return {
          title: title,
          id: id,
        };
      },
    });
  };

  const clickcategory = async (id) => {
    try {
      setSelectedCategory(id);
      const response = await DataService.get(`/events/eventByCategory/${id}`);
      console.log(response.data);
      if (response?.data?.success) {
        setCalenderEvents(response?.data?.data ?? []);
      } else {
        Alert.fire("Alert!", "No data found", "info");
      }
    } catch (error) {
      console.log(error);
      Alert.fire("Alert!", "No data found", "info");
    }
  };

  const frLocale = {
    code: 'fr', // Language code
    week: {
      dow: 1, // Monday is the first day of the week
      doy: 4, // The week that contains Jan 4th is the first week of the year
    },
    buttonText: {
      prev: 'Précédent', // Text for the previous button
      next: 'Suivant',   // Text for the next button
      today: "Aujourd'hui", // Text for the today button
      month: 'Mois', // Text for the month view
      week: 'Semaine', // Text for the week view
      day: 'Jour', // Text for the day view
      list: 'Liste' // Text for the list view
    },
    weekLabel: 'Sem.',
    allDayText: 'Toute la journée',
    moreLinkText: 'plus',
    noEventsText: 'Aucun événement à afficher',
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'].map(day => day.charAt(0).toUpperCase() + day.slice(1)), // Capitalize day names
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map(day => day.charAt(0).toUpperCase() + day.slice(1)), // Capitalize shortened day names
  };

  return (
    <div className="animated fadeIn demo-app p-0">
      <Row>
        <div className="card-header border-0 ">
          <h4 className="card-intro-title mb-0">{t("categories")}</h4>
        </div>
        <Card.Body
          className=" d-flex justify-content-center gap-3"
          style={{ padding: "0px" }}
        >
          <div
            style={{
              borderRadius: "0.125rem",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              margin: "0.3125rem 0.4375rem",
              padding: "10px",
              textAlign: "center",
            }}
            className={`light btn-primary ${
              selectedCategory == "all" && "bg-primary text-white"
            }`}
            data-class={`bg-primary`}
            title={"All"}
            data={1}
            key={1}
            onClick={() => {
              setSelectedCategory("all");
              getdata();
            }}
          >
            <i className="" />
            <span className=" text-capitalize">{t("all")}</span>
          </div>
          {categories.map((event) => (
            <div
              style={{
                borderRadius: "0.125rem",
                border: "none",
                cursor: "pointer",
                fontSize: "16px",
                margin: "0.3125rem 0.4375rem",
                textAlign: "center",
                padding: "10px",
              }}
              className={`light btn-primary ${
                selectedCategory == event._id && "bg-primary text-white"
              }`}
              data-class={`bg-primary`}
              title={event.name}
              data={event._id}
              key={event._id}
              onClick={() => {
                clickcategory(event._id);
              }}
            >
              <i className="" />
              <span className=" text-capitalize">{event?.name}</span>
            </div>
          ))}
        </Card.Body>

        <Col>
          <Card>
            <Card.Body>
              <div className="demo-app-calendar" id="mycalendartest">
                <FullCalendar
                  defaultView="dayGridMonth"
                  headerToolbar={{
                    start: `prev,next today`,
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  rerenderDelay={10}
                  eventDurationEditable={false}
                  editable={false}
                  droppable={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  weekends={true}
                  events={calendarEvents}
                  eventClick={(e) => {
                    eventClick(e);
                  }}
                  locale={frLocale}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EventCalendar;
