import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataService from "../../../../services/AxiosInstance";
import { useTranslation } from "react-i18next";

const ToDoList = () => {
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    callApi();
  }, []);
  const callApi = async () => {
    try {
      const response = await DataService.get("/events/");
      console.log(response.data.data);
      const filteredData = response.data.data
        .filter((item) => new Date(item.start) > new Date())
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      setEvents(filteredData ?? []);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };
  return (
    <>
      <div className="card shadow h-100">
        <div className="card-header ">
          <h4 className="heading mb-0">{t("upcomingEvents")}</h4>
          <div>
            <Link to="/events" className="text-primary me-2">
              {t("viewAll")}
            </Link>
          </div>
        </div>
        <div className="card-body overflow-scroll">
          {events.length > 0 ? (
            events.map((event) => (
              <div className="w-100 d-flex justify-content-between p-2">
                <div className="">
                  <Link to={``}>
                    <h5 className="text-capitalize">{event.title}</h5>
                  </Link>
                  <span>{new Date(event.start).toDateString()}</span>
                </div>
                <div className="w-25 text-left">
                  <span className="text-capitalize">{event.location}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              {t("noUpcommingEvents")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ToDoList;
